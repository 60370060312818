/**** pagination ****/
.pagination-wrapper {
	text-align: center;

	.pg-pagination {
		display: inline-block;
		overflow: hidden;
		list-style-type: none;

		li {
			float: left;
			margin-right: 10px;

			@include media-query(767px) {
				margin-right: 4px;
			}
		}

		li:first-child {
			i {
				line-height: 33px;

				@include media-query(767px) {
					line-height: 27px;
				}

			}
		}

		li:last-child {
			margin-right: 0;

			i {
				line-height: 33px;

				@include media-query(767px) {
					line-height: 27px;
				}
			}
		}

		li a {
			font-family: $heading-font;
			background-color: $heading-color;
			display: block;
			width: 35px;
			height: 35px;
			line-height: 35px;
			color: $white;
			font-weight: 500;

			@include media-query(767px) {
				width: 27px;
				height: 27px;
				line-height: 27px;
			}
		}

		.active a,
		li a:hover {
			background-color: $theme-secondary-color;
			color: $heading-color;
		}
	}
}
