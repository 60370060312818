/* Lists */
.ul {
	list-style: none;

	li {
		display: flex;
		align-items: baseline;
		padding: 7px 0;
		line-height: 1.5;

		&::before {
			content: "";
			display: block;
			flex: 0 0 10px;
			width: 10px;
			min-width: 10px;
			height: 10px;
			margin-right: 15px;
			border-radius: 50%;
			background-color: $theme-secondary-color;
		}
	}
}



/* Media Elements */

// Video
.video-pic {
	padding: 40px 0 0 45px;
	position: relative;

	cursor: pointer;

	&::before {
		content: "\f04b";
		font: normal normal normal 50px/1 FontAwesome;
		position: absolute;
		display: flex;
		width: 100px;
		height: 100px;
		padding-left: 10px;
		//top: calc(50% - 25px);
		//left: calc(50% - 50px);
		top: 0;
		left: 40px;
		right: 0;
		bottom: 0;
		margin: auto;
		align-items: center;
		justify-content: center;
		border: 10px solid $theme-secondary-color;
		border-radius: 50%;
		color: $theme-secondary-color;
		transition: all .3s ease-out;
		z-index: 3;
	}
	
	&:after {
		content: "";
		position: absolute;
		top: -25px;
		bottom: -25px;
		left: -15px;
		right: 100px;
		border: 2px solid $theme-secondary-color;
	}

	&:hover {
		&::before {
			color: $white;
			border-color: $white;
		}
	}

	img {
		position: relative;
		z-index: 2;
	}
}
