/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/

@font-face {
  font-family: 'GoodTimesRg-Regular';
  font-style: normal;
  font-weight: normal;
  src: local('GoodTimesRg-Regular'), url('../fonts/good-times-rg.woff') format('woff');
}

html {
	font-size: $base-font-size + px;
}

body {
    font-family: $base-font;
    font-size: 15px;
    font-size: calc-rem-value(15);

    @include media-query( 767px ) {
        font-size: 14px;
        font-size: calc-rem-value(14);
    }
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

*,
*::before,
*::after {
	box-sizing: border-box;
}

*::selection {
	background-color: $theme-primary-color;
	color: $theme-secondary-color;
}

p {
    color: $text-color;
    line-height: 1.8em;
    margin-bottom: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $heading-font;
    font-weight: bold;
    color: $heading-color;
    margin: 0 0 0.4em;
}

ul {
    padding-left: 0;
    margin: 0;
    list-style-position: inside;
}

a {
    text-decoration: none;
    @include transition-time(0.2s);
}

a:hover,
a:focus {
    text-decoration: none;
}

a:focus {
    outline: none !important;
}

img {
    width: 100%;
    height: auto;
}



/*--------------------------------------------------------------
#0.2    Global Elements1
--------------------------------------------------------------*/
.page-wrapper {
    position: relative;
    overflow: hidden;
}

.float-right {
    float: right;
}

.wow {
    visibility: hidden;
}

.fi:before {
    margin: 0;
}

.section-padding {
    padding: 100px 0;

    @include media-query( 1199px ) {
        padding: 80px 0;
    }

    @include media-query( 767px ) {
        padding: 70px 0;
    }
}

.section-padding_top {
	@extend .section-padding;

	padding-bottom: 0;
}


/*** contact form error handling ***/
.contact-validation-active {
    .error-handling-messages {
        width: 100%;
        margin-top: 15px;
        clear: both;
    }

    label.error {
        color: $error !important;
        font-size: 1em !important;
        font-weight: normal !important;
        margin: 5px 0 0 0;
        display: none;
    }

    #loader,
    #loader-2 {
        display: none;
        margin-top: 10px;
    }

    #success,
    #success-2,
    #error,
    #error-2 {
        width: 100%;
        color: $white;
        padding: 5px 10px;
        font-size: 16px;
        text-align: center;
        display: none;

        @include media-query(767px)  {
            font-size: 15px;
        }
    }

    #success,
    #success-2 {
        background-color: lighten(green, 5%);
        border-left: 5px solid green;
        margin-bottom: 5px;
    }

    #error,
    #error-2 {
        background-color: lighten(red, 5%);
        border-left: 5px solid red;
    }

    #loader {
        text-align: center;
        color: #283138;
        font-size: 8px;
        font-size: calc-rem-value(8);
    }
}

/*** back to top **/
.back-to-top {
    background-color: transparentize($theme-secondary-color, 0.1);
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    display: none;
    position: fixed;
    z-index: 999;
    left: 20px;
    bottom: 15px;
    @include transition-time(0.2s);
    @include rounded-border(3px);

    @include media-query(991px) {
        width: 35px;
        height: 35px;
        line-height: 35px;
    }

    &:hover {
        background-color: $theme-secondary-color;
    }
}

.back-to-top i {
    font-size: 18px;
    font-size: calc-rem-value(18);
    color: #fff;
    position: absolute;
    left: 50%;
    top: 50%;
    @include center-by-translating();
}



/** for popup image ***/
.mfp-wrap {
    background-color: transparentize($black, 0.2);
    z-index: 99998;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
    opacity: 0;
    -webkit-backface-visibility: hidden;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
    opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
    opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
    opacity: 0;
}

/*** for fancybox video ***/
.fancybox-overlay {
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999 !important;
}

.fancybox-wrap {
  z-index: 99999 !important;
}

.product-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  flex: 1 1 45%;
  max-height:155px;
}
.product-details{
  flex: 1 1 55%;
  padding-left: 15px;
  line-height:23px;
}
.product-details__title{
  font-size:18px;
  color:#000;

  font-weight: bold;

  &:hover {
    color: #5487c1;
  }

  @include media-query( 767px ) {
    font-size:1rem;
    line-height:20px;
  }
}


.b-products .product{
  display:flex;
  margin-bottom:15px;
}
.b-products .product p{
  margin:0px;
  @include media-query( 767px ) {
    line-height:20px;
    font-size:13px;
  }
}
.b-features__heading a{
  color:#283128;
}
.mt-50{
  margin-top:50px;
}
@media screen and (max-width:1199px) and (min-width:992px){
  .product-image{
    height:132px;
  }
}
@media screen and (max-width:767px){
  .product-image{
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width:580px){
  .b-products .product{
    width:100%;
  }
}

.section-padding_bottom {
  padding-bottom: 100px;

  @include media-query( 1199px ) {
    padding-bottom: 80px;
  }

  @include media-query( 767px ) {
    padding-bottom: 70px;
  }
}

.section-padding_bmedium {
  padding-bottom: 50px;

  @include media-query( 1199px ) {
    padding-bottom: 40px;
  }

  @include media-query( 767px ) {
    padding-bottom: 35px;
  }
}

#hello{
  max-width: 500px;
  margin: 50px auto;
  z-index: 999999;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#hello_placeholder{
  position:fixed;
  top:0px;
  left:0px;
  bottom:0px;
  right:0px;
  z-index:99999;
  background: rgba(0,0,0,0.2);
}

#hello .exit{
  position: absolute;
  top: 5px;
  right: 10px;
  cursor:pointer;
}

#hello div[role="form"] {
  width: 100%;
  background: #fff;
  padding: 15px;
  z-index:999999;
  overflow-y:auto;
  position: fixed;
  border-radius:3px;
  top: 0;

  height: 100%;
  overflow-y: scroll;
  margin-top: 0;

  left: 0;
  right: 0;
  margin: 0 auto;

  @media (min-width: 740px) {
    width: 700px;
  }

  @media (min-width: 425px) {
    max-height: 800px;
    height: 80%;
    top:75px;
    margin-top: 20px !important;

  }



  @include media-query( 767px ) {
    top: 20px;
    bottom:0px;
  }

  @include media-query(400px){
    top:0px;
  }

  input {
    //min-height: 45px;
  }

  .b-form__label {
    font-size: 1.2rem;
    color: #283138;
    font-weight: 600;
  }

  .wpcf7-form select {
    //background: none;
    //hello 2
  }

  input, textarea, select {
    min-height: 45px;
    //height: auto;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #d1d7e0;
    font-size: 17px;

    @media (min-width: 425px) {
      font-size: 16px;

    }

  }

  .wpcf7-form-control.wpcf7-checkbox {

    .wpcf7-list-item {

      margin: 0;

      &.first {

      }

      &.last {

      }

    }

    input[type="checkbox"] {
      width: 45px;
    }

  }



  .theme-btn {
    width: 100%;
  }

  input:focus {
    border: 1px solid #d1d7e0;
  }

  h3.col-12.text-center {
    margin-bottom: 30px;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 33px;
      height: 5px;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      background-color: #5487c1;
      border-radius: 3px;
      margin: 0 auto;
    }
  }

}



#hello .upload-file-1-inner{
  height:70px;
}

#hello .wpcf7-response-output {
  display:none;
  text-align: center;
}


#hello .wpcf7-mail-sent-ok{
  display: block!important;
  //position: absolute;
  width: 65%;
  bottom: 0;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  @include media-query( 767px ) {
    bottom:0px;
    left:0px;
    position:relative;
    border-radius:0px;
    width:100%;
  }
}

.section-padding_tmedium {
  padding-bottom: 50px;

  @include media-query( 1199px ) {
    padding-bottom: 40px;
  }

  @include media-query( 767px ) {
    padding-bottom: 35px;
  }
}
