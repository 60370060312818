/*---------------------------
	Fonts
----------------------------*/
@import url('https://fonts.googleapis.com/css?family=Hind:400,600|Montserrat:600,700');

// fonts
$base-font-size: 15;

$base-font: 'Hind', sans-serif;
$heading-font: 'Montserrat', sans-serif;
$fa: "FontAwesome";


// color
$dark-blue: #283138; // #051829;
$yellow: #5487c1; // #fdc900;
$dark-gray: #2a2a2a;
$light-gray: #6a6a6a;
$red: #283138;
$white: #fff;
$black: #000;

$theme-primary-color: $dark-blue;
$theme-secondary-color: $yellow;
$text-color: $light-gray;
$heading-color: $dark-blue;
$error: $red;


// media-query breakpoints
$phones:   480px;
$l-phones: 575px;
$tablets:  767px;
$medium:   991px;
$large:    1199px;
$x-large:  1439px;
