/******************************
	#blog grids
******************************/
.page-title {
	@include background-style("../images/page-title.jpg", center center, cover, no-repeat, local);
	min-height: 480px;
	position: relative;
	width: 100%;


	display:-webkit-box;
	display:-ms-flexbox;
	display:flex;
	-webkit-box-align:center;
	-ms-flex-align:center;
	align-items:center;


	padding: 15px 0;

	//height: 260px;

	//height: 100px;

	&:before {
		//content: '';
		//position: relative;
		//width: 100%;
		//height: 260px;
	}


	.row {
		//display: flex;
		////height: 260px;
		//width: 100%;
		//align-items: center;
		//flex-wrap: wrap;

	}

	@include media-query(767px) {
		//height: 220px;
	}

	&:before {
		content: "";
		@include overlay(transparentize($black, 0.7));
	}

	.container {
		//height: 100%;
		//display: table;

		> .row {
			//vertical-align: middle;
			//display: table-cell;
		}
	}

	h2,
	ol {
		color: $white;
	}

	ol {
		margin-top: 15px;
	}

	h2 {
		//font-size: 48px;
		font-size: calc-rem-value(48);
		font-weight: 800;
		//margin: 1em 0 0.23em;
		margin: 0;

		//@include media-query(1199px) {
		//	font-size: 42px;
		//	font-size: calc-rem-value(42);
		//}
		//
		//@include media-query(991px) {
		//	font-size: 35px;
		//	font-size: calc-rem-value(35);
		//}
		//
		//@include media-query(767px) {
		//	font-size: 28px;
		//	font-size: calc-rem-value(28);
		//	font-weight: bold;
		//}
	}

	.breadcrumb {
		background-color: transparent;
		padding: 0;
		margin-bottom: 0;

		@include media-query(767px) {
			//text-align: center;
		}
	}

	.breadcrumb li {
		//font-size: 18px;
		font-size: calc-rem-value(16);
		color: $theme-secondary-color;
		margin-right: 5px;

		@include media-query(991px) {
			//font-size: 15px;
			//font-size: calc-rem-value(15);
		}
	}

	.breadcrumb li a {
		color: $white;
	}

	.breadcrumb li a:hover {
		color: $theme-secondary-color;
	}

	.breadcrumb li:before {
		content: none;
	}

	.breadcrumb li:after {
		font-family: "FontAwesome";
		content: "\f105";
		color: $white;
		margin-left: 12px;
	}


	.breadcrumb li:last-child:after {
		content: none;
	}

	//.breadcrumb > li + li:before {
	//	font-family: "FontAwesome";
	//	content: "\f105";
	//	color: $white;
	//	margin-right: 5px;
	//}


	.container .row .col {
		//background-image: linear-gradient(90deg,rgba(40,49,56,.9),rgba(40,49,56,.4));
		//padding: 30px;
		text-shadow: 1px 1px 4px rgba(31, 31, 31, 0.60);


		display: inline-block;
		width: auto;

		h1 {
			//margin: 0;
			margin-bottom: 0;
		}

		p {
			margin-top: 15px;
		}
	}


}

.breadcrumb-nw {
	padding: 15px 0;
	background-color: #f2f2f2;

	h2,
	ol {
		color: #2d3840;
	}

	ol {
		margin-top: 15px;
	}

	h2 {
		//font-size: 48px;
		font-size: calc-rem-value(48);
		font-weight: 800;
		//margin: 1em 0 0.23em;
		margin: 0;

		//@include media-query(1199px) {
		//	font-size: 42px;
		//	font-size: calc-rem-value(42);
		//}
		//
		//@include media-query(991px) {
		//	font-size: 35px;
		//	font-size: calc-rem-value(35);
		//}
		//
		//@include media-query(767px) {
		//	font-size: 28px;
		//	font-size: calc-rem-value(28);
		//	font-weight: bold;c
		//}
	}

	.breadcrumb {
		background-color: transparent;
		margin: 0;
		padding: 0;
		display: flex;
		align-items: baseline;

		@include media-query(767px) {
			//text-align: center;
			flex-wrap: wrap;
		}
	}

	.breadcrumb li {
		//font-size: 18px;
		font-size: calc-rem-value(16);

		color: $theme-secondary-color;
		margin-right: 10px;

		@include media-query(991px) {
			//font-size: 15px;
			//font-size: calc-rem-value(15);
		}

		&:last-child {
			margin-right: 0;
		}
	}

	.breadcrumb li a {
		color: #2d3840;
	}

	.breadcrumb li a:hover {
		color: $theme-secondary-color;
	}

	.breadcrumb li:before {
		content: none;
	}

	.breadcrumb li:after {
		font-family: "FontAwesome";
		content: "\f105";
		color: #2d3840;
		margin-left: 12px;
	}


	.breadcrumb li:last-child:after {
		content: none;
	}


}
