.site-header_rel {
	position: relative;
}

.site-logo_main {
	position: absolute!important;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-width: 330px;
	max-height: 170px;
	top: -90px;
	bottom: 0;
	//margin: 0!important;
	transition: all .3s ease-out;
	padding-right: 20px;
	margin: auto !important;
	height: auto;
	background-position: 15px;

	background-repeat: no-repeat;
	background-size: contain;
	width: 100%;

	//height: 100%;

	@media (min-width: 768px) {
		background-position: center;
	}


	@include media-query($medium) {
		max-width: 320px!important;
		bottom: -60px;
	}

	@include media-query($tablets) {
		position: static!important;
		height: auto;
		max-width: 260px!important;
		max-height: 100px;
		padding-left: 10px;
	}

	img {
		//display: none;
		display: block;
		height: 90%;
		width: auto;
		//width: 100%;
		max-width: 100%;
		margin: auto;
		margin-left: 0;
		object-fit: contain;
	}
}

.sticky-on .site-logo_main {
	top: 0;

	@include media-query($tablets) {
		max-height: 60px;
	}
}
