/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name: 	
Version:        1.0.0
Author:         themexriver
URL:            http://themeforest.net/user/themexriver
-------------------------------------------------------------------*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
#0.1	Theme Reset Style
#0.2	Global Elements
#0.3	header
#0.4	hero-slider
#0.5	footer
#0.6	services
#0.7	testimonials
#0.8    offer
#0.9    recent-projects
#1.0    about-us-faq
#1.1    partners
#1.2    partners
#1.3    news-section


---------------------------------------------------------------
	home style 2
----------------------------------------------------------------
#2.0    cta
#2.1    services s2
#2.2    our-team
#2.3    fun-fact
#2.4    cta-newsletter
#2.5    testimoials-s2-slider


---------------------------------------------------------------
	home style 3
----------------------------------------------------------------
#3.0    features
#3.1    services-s3
#3.2    pricing


---------------------------------------------------------------
	faq page
----------------------------------------------------------------
#4.0	faq-pg-section


---------------------------------------------------------------
	service single page
----------------------------------------------------------------
#5.0	service-singel-section


---------------------------------------------------------------
	contact page
----------------------------------------------------------------
#6.0	contact-pg-section


---------------------------------------------------------------
	blog grid page
----------------------------------------------------------------
#7.0	blog-grid section


---------------------------------------------------------------
	blog with sidebar page
----------------------------------------------------------------
#8.0	blog-with-sidebar


---------------------------------------------------------------
	blog details page
----------------------------------------------------------------
#9.0	blog-single-content


---------------------------------------------------------------
	shop grid page
----------------------------------------------------------------
#10.0	products-section


---------------------------------------------------------------
	shop with sidebar page
----------------------------------------------------------------
#11.0	shop-main-content


---------------------------------------------------------------
	shop details page
----------------------------------------------------------------
#12.0	products-section



----------------------------------------------------------------*/
/*---------------------------
	Fonts
----------------------------*/
@import url("https://fonts.googleapis.com/css?family=Hind:400,600|Montserrat:600,700");
/*--------------------------------------------------------------
#0.1    Theme Reset Style
--------------------------------------------------------------*/
@font-face {
  font-family: 'GoodTimesRg-Regular';
  font-style: normal;
  font-weight: normal;
  src: local("GoodTimesRg-Regular"), url("../fonts/good-times-rg.woff") format("woff"); }

html {
  font-size: 15px; }

body {
  font-family: "Hind", sans-serif;
  font-size: 15px;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden; }
  @media (max-width: 767px) {
    body {
      font-size: 14px;
      font-size: 0.93333rem; } }

*,
*::before,
*::after {
  box-sizing: border-box; }

*::selection {
  background-color: #283138;
  color: #5487c1; }

p {
  color: #6a6a6a;
  line-height: 1.8em;
  margin-bottom: 15px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  color: #283138;
  margin: 0 0 0.4em; }

ul {
  padding-left: 0;
  margin: 0;
  list-style-position: inside; }

a {
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s; }

a:hover,
a:focus {
  text-decoration: none; }

a:focus {
  outline: none !important; }

img {
  width: 100%;
  height: auto; }

/*--------------------------------------------------------------
#0.2    Global Elements1
--------------------------------------------------------------*/
.page-wrapper {
  position: relative;
  overflow: hidden; }

.float-right {
  float: right; }

.wow {
  visibility: hidden; }

.fi:before {
  margin: 0; }

.section-padding, .section-padding_top {
  padding: 100px 0; }
  @media (max-width: 1199px) {
    .section-padding, .section-padding_top {
      padding: 80px 0; } }
  @media (max-width: 767px) {
    .section-padding, .section-padding_top {
      padding: 70px 0; } }

.section-padding_top {
  padding-bottom: 0; }

/*** contact form error handling ***/
.contact-validation-active .error-handling-messages {
  width: 100%;
  margin-top: 15px;
  clear: both; }

.contact-validation-active label.error {
  color: #283138 !important;
  font-size: 1em !important;
  font-weight: normal !important;
  margin: 5px 0 0 0;
  display: none; }

.contact-validation-active #loader,
.contact-validation-active #loader-2 {
  display: none;
  margin-top: 10px; }

.contact-validation-active #success,
.contact-validation-active #success-2,
.contact-validation-active #error,
.contact-validation-active #error-2 {
  width: 100%;
  color: #fff;
  padding: 5px 10px;
  font-size: 16px;
  text-align: center;
  display: none; }
  @media (max-width: 767px) {
    .contact-validation-active #success,
    .contact-validation-active #success-2,
    .contact-validation-active #error,
    .contact-validation-active #error-2 {
      font-size: 15px; } }

.contact-validation-active #success,
.contact-validation-active #success-2 {
  background-color: #009a00;
  border-left: 5px solid green;
  margin-bottom: 5px; }

.contact-validation-active #error,
.contact-validation-active #error-2 {
  background-color: #ff1a1a;
  border-left: 5px solid red; }

.contact-validation-active #loader {
  text-align: center;
  color: #283138;
  font-size: 8px;
  font-size: 0.53333rem; }

/*** back to top **/
.back-to-top {
  background-color: rgba(84, 135, 193, 0.9);
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  display: none;
  position: fixed;
  z-index: 999;
  left: 20px;
  bottom: 15px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px; }
  @media (max-width: 991px) {
    .back-to-top {
      width: 35px;
      height: 35px;
      line-height: 35px; } }
  .back-to-top:hover {
    background-color: #5487c1; }

.back-to-top i {
  font-size: 18px;
  font-size: 1.2rem;
  color: #fff;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

/** for popup image ***/
.mfp-wrap {
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99998; }

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1; }

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8; }

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0; }

/*** for fancybox video ***/
.fancybox-overlay {
  background: rgba(0, 0, 0, 0.9);
  z-index: 9999 !important; }

.fancybox-wrap {
  z-index: 99999 !important; }

.product-image {
  width: 100%;
  height: 100%;
  background-size: cover;
  flex: 1 1 45%;
  max-height: 155px; }

.product-details {
  flex: 1 1 55%;
  padding-left: 15px;
  line-height: 23px; }

.product-details__title {
  font-size: 18px;
  color: #000;
  font-weight: bold; }
  .product-details__title:hover {
    color: #5487c1; }
  @media (max-width: 767px) {
    .product-details__title {
      font-size: 1rem;
      line-height: 20px; } }

.b-products .product {
  display: flex;
  margin-bottom: 15px; }

.b-products .product p {
  margin: 0px; }
  @media (max-width: 767px) {
    .b-products .product p {
      line-height: 20px;
      font-size: 13px; } }

.b-features__heading a {
  color: #283128; }

.mt-50 {
  margin-top: 50px; }

@media screen and (max-width: 1199px) and (min-width: 992px) {
  .product-image {
    height: 132px; } }

@media screen and (max-width: 767px) {
  .product-image {
    width: 120px;
    height: 120px; } }

@media screen and (max-width: 580px) {
  .b-products .product {
    width: 100%; } }

.section-padding_bottom {
  padding-bottom: 100px; }
  @media (max-width: 1199px) {
    .section-padding_bottom {
      padding-bottom: 80px; } }
  @media (max-width: 767px) {
    .section-padding_bottom {
      padding-bottom: 70px; } }

.section-padding_bmedium {
  padding-bottom: 50px; }
  @media (max-width: 1199px) {
    .section-padding_bmedium {
      padding-bottom: 40px; } }
  @media (max-width: 767px) {
    .section-padding_bmedium {
      padding-bottom: 35px; } }

#hello {
  max-width: 500px;
  margin: 50px auto;
  z-index: 999999;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center; }

#hello_placeholder {
  position: fixed;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  z-index: 99999;
  background: rgba(0, 0, 0, 0.2); }

#hello .exit {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer; }

#hello div[role="form"] {
  width: 100%;
  background: #fff;
  padding: 15px;
  z-index: 999999;
  overflow-y: auto;
  position: fixed;
  border-radius: 3px;
  top: 0;
  height: 100%;
  overflow-y: scroll;
  margin-top: 0;
  left: 0;
  right: 0;
  margin: 0 auto; }
  @media (min-width: 740px) {
    #hello div[role="form"] {
      width: 700px; } }
  @media (min-width: 425px) {
    #hello div[role="form"] {
      max-height: 800px;
      height: 80%;
      top: 75px;
      margin-top: 20px !important; } }
  @media (max-width: 767px) {
    #hello div[role="form"] {
      top: 20px;
      bottom: 0px; } }
  @media (max-width: 400px) {
    #hello div[role="form"] {
      top: 0px; } }
  #hello div[role="form"] .b-form__label {
    font-size: 1.2rem;
    color: #283138;
    font-weight: 600; }
  #hello div[role="form"] input, #hello div[role="form"] textarea, #hello div[role="form"] select {
    min-height: 45px;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #d1d7e0;
    font-size: 17px; }
    @media (min-width: 425px) {
      #hello div[role="form"] input, #hello div[role="form"] textarea, #hello div[role="form"] select {
        font-size: 16px; } }
  #hello div[role="form"] .wpcf7-form-control.wpcf7-checkbox .wpcf7-list-item {
    margin: 0; }
  #hello div[role="form"] .wpcf7-form-control.wpcf7-checkbox input[type="checkbox"] {
    width: 45px; }
  #hello div[role="form"] .theme-btn, #hello div[role="form"] .theme-btn_light, #hello div[role="form"] .theme-btn-s2, #hello div[role="form"] .theme-btn-s3, #hello div[role="form"] .theme-btn-s4, #hello div[role="form"] .theme-btn-s5 {
    width: 100%; }
  #hello div[role="form"] input:focus {
    border: 1px solid #d1d7e0; }
  #hello div[role="form"] h3.col-12.text-center {
    margin-bottom: 30px;
    position: relative; }
    #hello div[role="form"] h3.col-12.text-center:after {
      content: "";
      display: block;
      width: 33px;
      height: 5px;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      background-color: #5487c1;
      border-radius: 3px;
      margin: 0 auto; }

#hello .upload-file-1-inner {
  height: 70px; }

#hello .wpcf7-response-output {
  display: none;
  text-align: center; }

#hello .wpcf7-mail-sent-ok {
  display: block !important;
  width: 65%;
  bottom: 0;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0; }
  @media (max-width: 767px) {
    #hello .wpcf7-mail-sent-ok {
      bottom: 0px;
      left: 0px;
      position: relative;
      border-radius: 0px;
      width: 100%; } }

.section-padding_tmedium {
  padding-bottom: 50px; }
  @media (max-width: 1199px) {
    .section-padding_tmedium {
      padding-bottom: 40px; } }
  @media (max-width: 767px) {
    .section-padding_tmedium {
      padding-bottom: 35px; } }

/* @import "layout/header";
@import "layout/hero-slider";
@import "layout/footer"; */
/*--------------------------------------------------------------
#0.5	footer
--------------------------------------------------------------*/
.site-footer {
  /*** about widget ***/
  /*** service-links-widget ***/
  /*** quick-links-widget ***/
  /*** twitter-feed-widget ***/
  /*** copyright-info ***/ }
  .site-footer .upper-footer {
    background-color: #283138;
    padding: 100px 0; }
    @media (max-width: 991px) {
      .site-footer .upper-footer {
        padding: 80px 0 35px; } }
    @media (max-width: 767px) {
      .site-footer .upper-footer {
        padding: 70px 0 30px; } }
    .site-footer .upper-footer ul {
      list-style: none; }
    .site-footer .upper-footer ul,
    .site-footer .upper-footer li,
    .site-footer .upper-footer p,
    .site-footer .upper-footer a {
      color: #fff; }
    .site-footer .upper-footer a:hover {
      color: #5487c1; }
  @media (max-width: 991px) {
    .site-footer .upper-footer .row > .col {
      margin-bottom: 55px; } }
  @media (max-width: 767px) {
    .site-footer .upper-footer .row > .col {
      margin-bottom: 40px; } }
  .site-footer .widget > h3,
  .site-footer .widget .footer-logo {
    font-size: 22px;
    font-size: cacl-rem-value(24);
    color: #fff;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 2em;
    padding-bottom: 0.5em;
    position: relative; }
    @media (max-width: 991px) {
      .site-footer .widget > h3,
      .site-footer .widget .footer-logo {
        font-size: 20px;
        font-size: cacl-rem-value(20); } }
    @media (max-width: 767px) {
      .site-footer .widget > h3,
      .site-footer .widget .footer-logo {
        font-size: 16px;
        font-size: cacl-rem-value(16); } }
    .site-footer .widget > h3:before,
    .site-footer .widget .footer-logo:before {
      content: "";
      background-color: #5487c1;
      width: 28px;
      height: 3px;
      position: absolute;
      left: 0;
      bottom: 0;
      border-radius: 5px; }
  .site-footer .about-widget .contact-info {
    margin-top: -20px; }
    @media (max-width: 767px) {
      .site-footer .about-widget .contact-info {
        margin-top: -10px; } }
  .site-footer .about-widget .footer-logo:before {
    display: none; }
  .site-footer .about-widget ul li {
    position: relative;
    padding-left: 38px;
    line-height: 1.78em; }
  .site-footer .about-widget ul li i {
    font-size: 24px;
    color: #5487c1;
    position: absolute;
    left: 0;
    top: 0; }
  .site-footer .about-widget ul > li + li {
    margin-top: 28px; }
    @media (max-width: 767px) {
      .site-footer .about-widget ul > li + li {
        margin-top: 10px; } }
  @media (max-width: 767px) {
    .site-footer .about-widget img {
      max-width: 160px; } }
  .site-footer .service-links-widget {
    overflow: hidden; }
  .site-footer .service-links-widget ul li {
    position: relative;
    padding-left: 20px; }
    .site-footer .service-links-widget ul li:before {
      font-family: "FontAwesome";
      content: "\f105";
      color: #5487c1;
      position: absolute;
      left: 0;
      top: -3px; }
  .site-footer .service-links-widget ul li + li {
    margin-top: 25px; }
    @media (max-width: 767px) {
      .site-footer .service-links-widget ul li + li {
        margin-top: 10px; } }
  .site-footer .quick-links-widget {
    overflow: hidden;
    margin-top: -16px; }
    @media screen and (min-width: 992px) {
      .site-footer .quick-links-widget {
        margin-top: 48px; } }
    @media screen and (min-width: 1200px) {
      .site-footer .quick-links-widget {
        margin-top: -16px; } }
  .site-footer .quick-links-widget ul li + li {
    margin-top: 15px; }
    @media (max-width: 767px) {
      .site-footer .quick-links-widget ul li + li {
        margin-top: 10px; } }
  .site-footer .quick-links-widget ul {
    width: 50%;
    float: left; }
  .site-footer .twitter-feed-widget ul li + li {
    padding-top: 14px;
    margin-top: 14px;
    border-top: 1px solid #494949; }
  .site-footer .twitter-feed-widget p {
    color: #ccc; }
  .site-footer .twitter-feed-widget i {
    color: #198cfa;
    display: inline-block;
    padding-right: 5px; }
  .site-footer .copyright-info {
    background-color: #000204;
    padding: 15px 0;
    text-align: center; }
    .site-footer .copyright-info p {
      color: #808790;
      margin: 0; }
      @media (max-width: 767px) {
        .site-footer .copyright-info p {
          font-size: 13px;
          font-size: cacl-rem-value(13); } }
    .site-footer .copyright-info a {
      color: #5487c1; }

/*--------------------------------------------------------------
#0.6	footer flex
--------------------------------------------------------------*/
.site-footer__wrap {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between; }
  @media screen and (max-width: 991px) {
    .site-footer__wrap {
      flex-wrap: wrap; } }
  @media screen and (max-width: 550px) {
    .site-footer__wrap .widget > h3 {
      text-align: left; } }

.site-footer__block {
  flex: 1 1; }

.site-footer__logo {
  flex: 0 0 15%; }
  @media screen and (max-width: 991px) {
    .site-footer__logo {
      flex: 0 0 40%; } }
  @media screen and (max-width: 550px) {
    .site-footer__logo {
      flex: 1 1 100%;
      text-align: center; } }
  .site-footer__logo a {
    display: inline-block;
    text-decoration: none; }
    @media screen and (max-width: 550px) {
      .site-footer__logo a {
        max-width: 300px;
        margin: auto; } }
    .site-footer__logo a img {
      max-height: 136px; }

.site-footer__nav {
  padding-left: 40px;
  padding-right: 40px; }
  .site-footer__nav ul {
    flex: 0 0 33%; }
  @media screen and (max-width: 991px) {
    .site-footer__nav {
      flex: 0 0 50%;
      padding-left: 0;
      padding-right: 0; } }
  @media screen and (max-width: 550px) {
    .site-footer__nav {
      flex: 1 1 100%;
      margin-top: 50px; }
      .site-footer__nav ul {
        text-align: left; } }
  .site-footer__nav > * {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0; }

.site-footer__contact {
  flex: 0 0 50%; }
  @media screen and (max-width: 991px) {
    .site-footer__contact {
      flex: 1 1 100%;
      margin-top: 50px; } }

.site-footer__cols {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .site-footer__cols > * {
    flex: 0 0 50%; }
    .site-footer__cols > *:first-child:not(:last-child) li {
      padding-right: 15px; }

@media screen and (max-width: 550px) {
  .site-footer__cols_sm > *:first-child:not(:last-child) {
    text-align: left; }
  .site-footer__cols_sm:last-child:not(:first-child) li {
    padding-left: .9375rem; } }

@media screen and (max-width: 550px) {
  .site-footer__cols_st {
    display: block;
    margin: auto; } }

@media screen and (max-width: 550px) {
  .site-footer .about-widget.site-footer__cols_st .contact-info:not(:first-of-type) {
    margin-top: .625rem; } }

@media screen and (max-width: 550px) {
  .site-footer__wrap .social-list_footer {
    justify-content: center;
    text-align: center; } }

.site-footer__socials {
  margin: 0 !important;
  padding: 0 !important; }
  @media screen and (max-width: 991px) {
    .site-footer__socials {
      position: static !important;
      margin-bottom: 100px !important; } }
  @media screen and (max-width: 550px) {
    .site-footer__socials {
      margin-bottom: 50px !important; } }
  .site-footer__socials .fa {
    position: relative !important; }
  @media screen and (max-width: 991px) {
    .site-footer__socials .social-list.social-list_footer {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      justify-content: center;
      text-align: center; } }

/*--------------------------------------------------------------
#0.3	header
--------------------------------------------------------------*/
.site-header {
  /* navigation open and close btn hide for width screen */
  /* style for navigation less than 992px */
  /*navbar collaps less then 992px*/ }
  .site-header .navigation {
    background-color: #fff;
    margin-bottom: 0;
    border: 0;
    width: 100% !important;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -o-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0; }
    .site-header .navigation > .container {
      position: relative; }
    .site-header .navigation ul {
      list-style-type: none;
      margin-right: -15px; }
    @media (max-width: 991px) {
      .site-header .navigation {
        min-height: 65px; } }
    .site-header .navigation .social-list {
      padding-right: 0;
      margin-top: 25px; }
      @media (min-width: 768px) {
        .site-header .navigation .social-list {
          display: none; } }
  .site-header #navbar {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    /*** mega-menu style ***/ }
    .site-header #navbar > ul li a:hover,
    .site-header #navbar > ul li a:focus {
      text-decoration: none;
      color: #283138; }
    @media screen and (min-width: 992px) {
      .site-header #navbar {
        /*** hover effect ***/ }
        .site-header #navbar li {
          position: relative; }
        .site-header #navbar > ul > li > a {
          font-size: 15px;
          font-size: 1rem;
          color: #222; }
        .site-header #navbar > ul .sub-menu {
          background-color: #283138;
          width: 220px;
          position: absolute;
          left: 0;
          top: 130%;
          z-index: 10;
          visibility: hidden;
          opacity: 0;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          -ms-transition: all 0.3s;
          transition: all 0.3s; }
        .site-header #navbar > ul > li .sub-menu li {
          border-bottom: 1px solid #2e3941; }
        .site-header #navbar > ul > li .sub-menu > li:last-child {
          border-bottom: 0; }
        .site-header #navbar > ul > li .sub-menu a {
          font-size: 13px;
          font-size: 0.86667rem;
          color: #c3bfbf;
          display: block;
          padding: 11px 15px; }
        .site-header #navbar > ul > li .sub-menu a:hover {
          background-color: #5487c1;
          color: #fff; }
        .site-header #navbar > ul > li > .sub-menu .sub-menu {
          left: 110%;
          top: 0; }
        .site-header #navbar > ul > li > .sub-menu > .menu-item-has-children > a {
          position: relative; }
          .site-header #navbar > ul > li > .sub-menu > .menu-item-has-children > a:before {
            font-family: "FontAwesome";
            content: "\f105";
            font-size: 15px;
            font-size: 1rem;
            position: absolute;
            right: 15px;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -o-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
        .site-header #navbar > ul > li:hover > .sub-menu {
          top: 100%;
          visibility: visible;
          opacity: 1; }
        .site-header #navbar .sub-menu > li:hover > .sub-menu {
          left: 100%;
          visibility: visible;
          opacity: 1; } }
    @media (max-width: 991px) {
      .site-header #navbar > ul > li a {
        display: block;
        font-size: 14px;
        font-size: 0.93333rem; }
      .site-header #navbar > ul > li .sub-menu li {
        border-bottom: 1px solid #e6e6e6; }
      .site-header #navbar > ul .sub-menu > li:last-child {
        border-bottom: 0; }
      .site-header #navbar > ul > li > .sub-menu a {
        padding: 8px 15px 8px 45px; }
      .site-header #navbar > ul > li > .sub-menu .sub-menu a {
        padding: 8px 15px 8px 65px; }
      .site-header #navbar > ul .menu-item-has-children > a {
        position: relative; }
        .site-header #navbar > ul .menu-item-has-children > a:before {
          font-family: "FontAwesome";
          content: "\f107";
          font-size: 15px;
          font-size: 1rem;
          position: absolute;
          right: 15px;
          top: 50%;
          -webkit-transform: translateY(-50%);
          -moz-transform: translateY(-50%);
          -o-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%); } }
    @media screen and (min-width: 992px) {
      .site-header #navbar {
        /*** hover effect ***/ }
        .site-header #navbar .has-mega-menu {
          position: static; }
        .site-header #navbar .mega-menu,
        .site-header #navbar .half-mega-menu {
          background-color: #fff;
          padding: 20px;
          border-top: 2px solid #283138;
          position: absolute;
          right: 0;
          top: 120%;
          z-index: 10;
          visibility: hidden;
          opacity: 0;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -o-transition: all 0.3s;
          -ms-transition: all 0.3s;
          transition: all 0.3s; }
        .site-header #navbar .mega-menu {
          width: 1140px;
          right: 15px; }
        .site-header #navbar .half-mega-menu {
          width: 585px; }
        .site-header #navbar .mega-menu-box-title {
          font-size: 14px;
          font-size: 0.93333rem;
          text-transform: uppercase;
          font-weight: bold;
          display: block;
          padding-bottom: 7px;
          margin-bottom: 7px;
          border-bottom: 1px solid #e6e6e6; }
        .site-header #navbar .mega-menu-list-holder li a {
          font-size: 14px;
          font-size: 0.93333rem;
          display: block;
          padding: 7px 8px;
          margin-left: -8px; }
        .site-header #navbar .has-mega-menu:hover > ul {
          top: 100%;
          visibility: visible;
          opacity: 1; } }
    @media (max-width: 1199px) {
      .site-header #navbar > ul .mega-menu {
        width: 950px;
        right: 15px; }
      .site-header #navbar > ul .half-mega-menu {
        width: 485px; } }
    @media (max-width: 991px) {
      .site-header #navbar > ul .mega-menu,
      .site-header #navbar > ul .half-mega-menu {
        width: auto; }
      .site-header #navbar > ul .mega-menu .row,
      .site-header #navbar > ul .half-mega-menu .row {
        margin: 0; }
      .site-header #navbar .mega-menu-content > .row > .col {
        margin-bottom: 25px; } }
    @media (max-width: 991px) {
      .site-header #navbar .mega-menu .mega-menu-list-holder a {
        padding: 5px 15px 5px 40px; }
      .site-header #navbar .mega-menu .mega-menu-box-title {
        font-size: 14px;
        font-size: 0.93333rem;
        text-transform: uppercase;
        display: block;
        border-bottom: 1px dotted #b3b3b3;
        padding: 0 0 4px 5px;
        margin: 0 25px 8px 25px; } }
  @media screen and (min-width: 992px) {
    .site-header .navbar-header .open-btn {
      display: none; }
    .site-header #navbar .close-navbar {
      display: none; } }
  @media (max-width: 991px) {
    .site-header {
      /* class for show hide navigation */ }
      .site-header .container {
        width: 100%; }
      .site-header .navbar-header button {
        background-color: #283138;
        width: 40px;
        height: 35px;
        border: 0;
        padding: 5px 10px;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        -o-border-radius: 3px;
        -ms-border-radius: 3px;
        border-radius: 3px;
        outline: 0;
        position: absolute;
        right: 15px;
        top: 15px;
        z-index: 20; }
        .site-header .navbar-header button span {
          background-color: #fff;
          display: block;
          height: 2px;
          margin-bottom: 5px; }
          .site-header .navbar-header button span:last-child {
            margin: 0; }
      .site-header #navbar {
        background: #fff;
        display: block !important;
        width: 280px;
        height: 100% !important;
        margin: 0;
        padding: 0;
        border-left: 1px solid #cccccc;
        border-right: 1px solid #cccccc;
        position: fixed;
        right: -300px;
        top: 0;
        z-index: 100; }
        .site-header #navbar ul a {
          color: #000; }
        .site-header #navbar ul a:hover,
        .site-header #navbar ul li.current a {
          color: #283138; }
        .site-header #navbar .navbar-nav {
          overflow: auto; }
        .site-header #navbar .close-navbar {
          background-color: #283138;
          width: 35px;
          height: 35px;
          color: #fff;
          border: 0;
          -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          -o-border-radius: 50%;
          -ms-border-radius: 50%;
          border-radius: 50%;
          outline: none;
          position: absolute;
          left: -18px;
          top: 10px;
          z-index: 20; }
        .site-header #navbar > ul > li {
          border-bottom: 1px solid #cccccc; }
        .site-header #navbar > ul > li > a {
          padding: 10px 15px 10px 35px; }
      .site-header .slideInn {
        right: 0 !important; } }
  @media (max-width: 767px) {
    .site-header .navbar-header .navbar-brand {
      font-size: 24px; }
    .site-header #navbar .navbar-nav {
      margin: 0; } }
  @media (max-width: 991px) {
    .site-header .navbar-collapse.collapse {
      display: none; }
    .site-header .navbar-collapse.collapse.in {
      display: block; }
    .site-header .navbar-header .collapse,
    .site-header .navbar-toggle {
      display: block; }
    .site-header .navbar-header {
      float: none; }
    .site-header .navbar-right {
      float: none; }
    .site-header .navbar-nav {
      float: none; }
    .site-header .navbar-nav > li {
      float: none; } }

/**************************************************
	#header style 1
******************************************************/
.topbar-style-1, .topbar-style-2 {
  background-color: #283138;
  padding: 15px 0; }
  @media (max-width: 767px) {
    .topbar-style-1 .site-logo, .topbar-style-2 .site-logo {
      text-align: center;
      border-bottom: 1px solid #333e47;
      padding-bottom: 15px;
      margin-bottom: 15px; }
    .topbar-style-1 .topbar-contact-info-wrapper, .topbar-style-2 .topbar-contact-info-wrapper {
      text-align: center; } }
  .topbar-style-1 .topbar-contact-info, .topbar-style-2 .topbar-contact-info {
    display: inline-block;
    float: right;
    margin-top: 5px; }
    @media (max-width: 767px) {
      .topbar-style-1 .topbar-contact-info, .topbar-style-2 .topbar-contact-info {
        float: none; } }
  .topbar-style-1 .topbar-contact-info > div, .topbar-style-2 .topbar-contact-info > div {
    display: inline-block;
    float: left;
    padding-left: 30px;
    position: relative; }
    @media (max-width: 767px) {
      .topbar-style-1 .topbar-contact-info > div, .topbar-style-2 .topbar-contact-info > div {
        float: none;
        display: block;
        text-align: left; } }
  @media screen and (min-width: 992px) {
    .topbar-style-1 .topbar-contact-info > div + div, .topbar-style-2 .topbar-contact-info > div + div {
      margin-left: 35px; } }
  @media (max-width: 767px) {
    .topbar-style-1 .topbar-contact-info > div + div, .topbar-style-2 .topbar-contact-info > div + div {
      margin-top: 15px; } }
  .topbar-style-1 .topbar-contact-info > div > i, .topbar-style-2 .topbar-contact-info > div > i {
    font-size: 18px;
    font-size: 1.2rem;
    color: #5487c1;
    position: absolute;
    left: 0; }
  .topbar-style-1 .topbar-contact-info p, .topbar-style-2 .topbar-contact-info p {
    font-size: 13px;
    color: #fff;
    line-height: 100%;
    margin: 0 0 0.2em; }
  .topbar-style-1 .details > span, .topbar-style-2 .details > span {
    font-size: 12px;
    font-size: 0.8rem;
    color: #9a9a9a; }

@media screen and (min-width: 992px) {
  .header-style-1 .navigation-holder, .header-style-2 .navigation-holder, .header-style-3 .navigation-holder, .header-style-4 .navigation-holder, .header-style-5 .navigation-holder, .header-style-6 .navigation-holder {
    padding: 0; } }

@media screen and (min-width: 992px) {
  .header-style-1 #navbar > ul > li > a, .header-style-2 #navbar > ul > li > a, .header-style-3 #navbar > ul > li > a, .header-style-4 #navbar > ul > li > a, .header-style-5 #navbar > ul > li > a, .header-style-6 #navbar > ul > li > a {
    font-size: 15px;
    font-size: 1rem;
    font-weight: 600;
    padding: 30px 15px;
    position: relative; }
    .header-style-1 #navbar > ul > li > a:before, .header-style-2 #navbar > ul > li > a:before, .header-style-3 #navbar > ul > li > a:before, .header-style-4 #navbar > ul > li > a:before, .header-style-5 #navbar > ul > li > a:before, .header-style-6 #navbar > ul > li > a:before {
      content: "";
      background-color: #5487c1;
      width: 0;
      height: 3px;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 20px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      transition: all 0.3s; } }

.header-style-1 #navbar > ul > li > a:hover:before, .header-style-2 #navbar > ul > li > a:hover:before, .header-style-3 #navbar > ul > li > a:hover:before, .header-style-4 #navbar > ul > li > a:hover:before, .header-style-5 #navbar > ul > li > a:hover:before, .header-style-6 #navbar > ul > li > a:hover:before {
  width: 12px; }

/*** cart and quote ***/
.cart-contact {
  position: absolute;
  right: 0;
  top: 30px; }
  @media (max-width: 991px) {
    .cart-contact {
      top: 18px;
      left: 15px; } }
  .cart-contact .mini-cart {
    float: left;
    position: relative; }
    @media screen and (min-width: 992px) {
      .cart-contact .mini-cart {
        margin-top: -4px; } }
  .cart-contact .mini-cart > button {
    font-size: 15px;
    font-size: 1rem;
    color: #0d1d36;
    display: inline-block;
    border: 0;
    outline: 0;
    background: transparent;
    font-weight: 600;
    margin-right: 30px; }
    @media (max-width: 767px) {
      .cart-contact .mini-cart > button {
        font-size: 13px;
        font-size: 0.86667rem;
        margin-right: 15px; } }
  .cart-contact .mini-cart > button i:before {
    font-size: 18px;
    font-size: 1.2rem;
    color: #0d1d36;
    display: inline-block;
    padding-right: 5px; }
    @media (max-width: 767px) {
      .cart-contact .mini-cart > button i:before {
        font-size: 15px;
        font-size: 1rem; } }
  .cart-contact .top-cart-content {
    background: #fff;
    width: 292px;
    border-top: 2px solid #5487c1;
    position: absolute;
    top: 45px;
    opacity: 0;
    visibility: hidden;
    z-index: 10;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s; }
    @media (max-width: 991px) {
      .cart-contact .top-cart-content {
        top: 40px;
        left: -14px; } }
    .cart-contact .top-cart-content .top-cart-title {
      padding: 12px 15px;
      border-bottom: 1px solid #e4e2e2; }
    .cart-contact .top-cart-content p {
      font-size: 14px;
      font-size: 0.93333rem;
      font-weight: 600;
      color: #283138;
      margin: 0;
      text-transform: uppercase; }
    .cart-contact .top-cart-content .top-cart-items {
      padding: 15px; }
    .cart-contact .top-cart-content .top-cart-item {
      padding-top: 15px;
      margin-top: 15px;
      border-top: 1px solid #e4e2e2; }
    .cart-contact .top-cart-content .top-cart-item:first-child {
      padding-top: 0;
      margin-top: 0;
      border-top: 0; }
    .cart-contact .top-cart-content .top-cart-item-image {
      width: 48px;
      height: 48px;
      border: 2px solid #eee;
      float: left;
      margin-right: 15px;
      -webkit-transition: all .2s linear;
      -o-transition: all .2s linear;
      transition: all .2s linear; }
    .cart-contact .top-cart-content .top-cart-item-image a,
    .cart-contact .top-cart-content .top-cart-item-image img {
      display: block;
      width: 44px;
      height: 44px; }
    .cart-contact .top-cart-content .top-cart-item-image:hover {
      border-color: #5487c1; }
    .cart-contact .top-cart-content .top-cart-item-des {
      position: relative;
      overflow: hidden; }
    .cart-contact .top-cart-content .top-cart-item-des a {
      width: auto;
      height: auto;
      font-size: 13px;
      font-weight: 600;
      text-align: left;
      color: #333; }
    .cart-contact .top-cart-content .top-cart-item-des a:hover {
      color: #5487c1; }
    .cart-contact .top-cart-content .top-cart-item-des .top-cart-item-price {
      font-size: 12px;
      color: #999;
      line-height: 20px;
      display: block; }
    .cart-contact .top-cart-content .top-cart-item-des .top-cart-item-quantity {
      font-size: 12px;
      color: #555;
      display: block;
      position: absolute;
      right: 0;
      top: 2px; }
    .cart-contact .top-cart-content .top-cart-action {
      padding: 25px 15px 25px;
      border-top: 1px solid #e4e2e2; }
    .cart-contact .top-cart-content .top-cart-action .top-checkout-price {
      font-size: 15px;
      font-size: 1rem;
      font-weight: 600;
      color: #283138; }
    .cart-contact .top-cart-content .top-cart-action .theme-btn, .cart-contact .top-cart-content .top-cart-action .theme-btn_light, .cart-contact .top-cart-content .top-cart-action .theme-btn-s2, .cart-contact .top-cart-content .top-cart-action .theme-btn-s3, .cart-contact .top-cart-content .top-cart-action .theme-btn-s4, .cart-contact .top-cart-content .top-cart-action .theme-btn-s5 {
      font-size: 14px;
      font-size: 0.93333rem;
      padding: 3px 15px;
      float: right; }
  .cart-contact .top-cart-content-toggle {
    top: 54px;
    opacity: 1;
    visibility: visible; }
    @media (max-width: 991px) {
      .cart-contact .top-cart-content-toggle {
        top: 47px; } }
  .cart-contact .get-quote {
    float: right; }
    @media (max-width: 991px) {
      .cart-contact .get-quote {
        float: left;
        margin: 4px 0 0; } }
    @media (max-width: 991px) {
      .cart-contact .get-quote .theme-btn, .cart-contact .get-quote .theme-btn_light, .cart-contact .get-quote .theme-btn-s2, .cart-contact .get-quote .theme-btn-s3, .cart-contact .get-quote .theme-btn-s4, .cart-contact .get-quote .theme-btn-s5 {
        padding: 3px 15px; } }

/**************************************
	#header style 2
****************************************/
.header-style-2 .site-logo, .header-style-3 .site-logo, .header-style-4 .site-logo, .header-style-5 .site-logo, .header-style-6 .site-logo {
  margin-top: 15px; }
  @media (max-width: 991px) {
    .header-style-2 .site-logo, .header-style-3 .site-logo, .header-style-4 .site-logo, .header-style-5 .site-logo, .header-style-6 .site-logo {
      margin-top: 8px;
      position: relative;
      z-index: 10; } }
  @media (max-width: 767px) {
    .header-style-2 .site-logo, .header-style-3 .site-logo, .header-style-4 .site-logo, .header-style-5 .site-logo, .header-style-6 .site-logo {
      margin: 12px 0 0 10px;
      max-width: 160px; } }

.header-style-2 .social, .header-style-3 .social, .header-style-4 .social, .header-style-5 .social, .header-style-6 .social {
  display: block;
  float: right; }
  @media (max-width: 767px) {
    .header-style-2 .social, .header-style-3 .social, .header-style-4 .social, .header-style-5 .social, .header-style-6 .social {
      float: none;
      text-align: center;
      margin-top: 15px; } }
  .header-style-2 .social > span, .header-style-3 .social > span, .header-style-4 .social > span, .header-style-5 .social > span, .header-style-6 .social > span {
    font-size: 14px;
    font-size: 0.93333rem;
    color: #5487c1;
    position: relative;
    top: 0; }

.header-style-2 .social-links, .header-style-3 .social-links, .header-style-4 .social-links, .header-style-5 .social-links, .header-style-6 .social-links {
  display: inline-block;
  position: relative;
  top: 24px; }
  @media (max-width: 767px) {
    .header-style-2 .social-links, .header-style-3 .social-links, .header-style-4 .social-links, .header-style-5 .social-links, .header-style-6 .social-links {
      top: 0; } }

.header-style-2 .social-links > li + li, .header-style-3 .social-links > li + li, .header-style-4 .social-links > li + li, .header-style-5 .social-links > li + li, .header-style-6 .social-links > li + li {
  margin-left: 15px; }

.header-style-2 .social-links li a, .header-style-3 .social-links li a, .header-style-4 .social-links li a, .header-style-5 .social-links li a, .header-style-6 .social-links li a {
  font-size: 14px;
  font-size: 0.93333rem;
  color: #fff; }

.header-style-2 #navbar .close-navbar, .header-style-3 #navbar .close-navbar, .header-style-4 #navbar .close-navbar, .header-style-5 #navbar .close-navbar, .header-style-6 #navbar .close-navbar {
  background-color: #fff;
  color: #283138;
  box-shadow: 0px 0px 3px 0px; }

@media (max-width: 991px) {
  .header-style-2 .cart-contact .mini-cart, .header-style-3 .cart-contact .mini-cart, .header-style-4 .cart-contact .mini-cart, .header-style-5 .cart-contact .mini-cart, .header-style-6 .cart-contact .mini-cart {
    float: right;
    margin-right: 65px; } }

.header-style-2 .cart-contact .mini-cart > button, .header-style-3 .cart-contact .mini-cart > button, .header-style-4 .cart-contact .mini-cart > button, .header-style-5 .cart-contact .mini-cart > button, .header-style-6 .cart-contact .mini-cart > button {
  margin-right: 0; }

.header-style-2 .cart-contact .top-cart-content, .header-style-3 .cart-contact .top-cart-content, .header-style-4 .cart-contact .top-cart-content, .header-style-5 .cart-contact .top-cart-content, .header-style-6 .cart-contact .top-cart-content {
  right: 0; }
  @media (max-width: 991px) {
    .header-style-2 .cart-contact .top-cart-content, .header-style-3 .cart-contact .top-cart-content, .header-style-4 .cart-contact .top-cart-content, .header-style-5 .cart-contact .top-cart-content, .header-style-6 .cart-contact .top-cart-content {
      right: auto;
      left: -140px; } }

.topbar-style-2 {
  background-color: #fff !important;
  border: none !important; }
  @media (max-width: 767px) {
    .topbar-style-2 {
      display: none; } }
  .topbar-style-2 .topbar-contact-info {
    float: none; }
  .topbar-style-2 .get-quote {
    float: right;
    margin-top: 12px; }
    @media screen and (min-width: 992px) {
      .topbar-style-2 .get-quote {
        position: relative;
        right: -10px; } }
    @media (max-width: 767px) {
      .topbar-style-2 .get-quote {
        float: none;
        text-align: center;
        margin-top: 15px;
        margin: 25px 0 15px; } }
  @media (max-width: 991px) {
    .topbar-style-2 .topbar-contact-info > div + div {
      margin-left: 35px; } }
  @media (max-width: 767px) {
    .topbar-style-2 .topbar-contact-info > div + div {
      margin-left: 0; } }

/**************************************
	#header style 3
****************************************/
@media screen and (min-width: 992px) {
  .header-style-3 {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10; }
    .header-style-3 .navigation,
    .header-style-3 .topbar-style-2 {
      background: transparent; }
    .header-style-3 .topbar-contact-info p {
      color: #5487c1; }
    .header-style-3 .topbar-style-2 .details > span {
      color: #9a9a9a; }
    .header-style-3 .topbar-style-2 .get-quote {
      position: relative;
      right: -10px; }
    .header-style-3 #navbar > ul > li > a {
      color: #fff; }
    .header-style-3 #navbar > ul > li > a:hover {
      color: #5487c1; }
    .header-style-3 .cart-contact .mini-cart > button i:before,
    .header-style-3 .cart-contact .mini-cart > button {
      color: #5487c1; } }

@media (max-width: 991px) {
  .header-style-3 .navigation {
    background: #1d2429; }
  .header-style-3 .cart-contact .mini-cart > button i:before,
  .header-style-3 .cart-contact .mini-cart > button {
    color: #fff; } }

/**************************************
	#header style 4
****************************************/
@media screen and (min-width: 992px) {
  .header-style-4, .header-style-5 {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10; } }

@media screen and (min-width: 992px) {
  .header-style-4 .topbar-style-2, .header-style-5 .topbar-style-2 {
    background: transparent; }
  .header-style-4 .topbar-style-2 .topbar-contact-info p, .header-style-5 .topbar-style-2 .topbar-contact-info p {
    color: #5487c1; }
  .header-style-4 .topbar-style-2 .details > span, .header-style-5 .topbar-style-2 .details > span {
    color: #9a9a9a; }
  .header-style-4 .navigation, .header-style-5 .navigation {
    background-color: transparent; }
    .header-style-4 .navigation .container, .header-style-5 .navigation .container {
      background-color: #fff;
      border-radius: 5px; }
  .header-style-4 .cart-contact, .header-style-5 .cart-contact {
    right: 15px; } }

.header-style-4 .social, .header-style-5 .social {
  display: inline-block; }
  @media (max-width: 991px) {
    .header-style-4 .social, .header-style-5 .social {
      display: none; } }
  .header-style-4 .social > span, .header-style-5 .social > span {
    font-size: 14px;
    font-size: 0.93333rem;
    color: #5487c1;
    position: relative;
    top: 9px; }

.header-style-4 .social-links, .header-style-5 .social-links {
  display: inline-block;
  position: relative;
  top: 15px;
  left: 8px; }

.header-style-4 .social-links > li + li, .header-style-5 .social-links > li + li {
  margin-left: 15px; }

.header-style-4 .social-links li a, .header-style-5 .social-links li a {
  font-size: 14px;
  font-size: 0.93333rem;
  color: #fff; }

/**************************************
	#header style 5
****************************************/
.header-style-5 {
  position: static;
  /*** lower topbar ***/ }
  .header-style-5 .social {
    float: right; }
    @media (max-width: 991px) {
      .header-style-5 .social {
        display: block; } }
    @media (max-width: 767px) {
      .header-style-5 .social {
        float: none;
        text-align: center;
        margin-top: 15px; } }
  .header-style-5 .topbar {
    background-color: #283138; }
  .header-style-5 .social-links {
    top: 5px; }
  .header-style-5 .social > span {
    top: 0; }
  .header-style-5 .lower-topbar {
    padding: 12px 0; }
    @media (max-width: 991px) {
      .header-style-5 .lower-topbar {
        border-bottom: 1px solid #d4d2d2; } }
    .header-style-5 .lower-topbar .site-logo {
      margin: 0; }
      @media (max-width: 767px) {
        .header-style-5 .lower-topbar .site-logo {
          margin: 0 auto; } }
    .header-style-5 .lower-topbar .awards {
      float: right;
      overflow: hidden;
      position: relative;
      top: 5px; }
      @media (max-width: 767px) {
        .header-style-5 .lower-topbar .awards {
          float: none;
          border-top: 1px solid #d4d2d2;
          padding-top: 17px;
          margin: 10px -15px 0; } }
    .header-style-5 .lower-topbar .awards > div {
      min-height: 43px;
      padding-left: 58px;
      position: relative;
      float: left; }
      @media (max-width: 767px) {
        .header-style-5 .lower-topbar .awards > div {
          width: 210px;
          float: none;
          margin: 0 auto 10px; } }
      @media (max-width: 991px) {
        .header-style-5 .lower-topbar .awards > div {
          padding-left: 40px; } }
    .header-style-5 .lower-topbar .awards > div:first-child {
      margin-right: 40px; }
      @media (max-width: 767px) {
        .header-style-5 .lower-topbar .awards > div:first-child {
          margin-right: auto; } }
    .header-style-5 .lower-topbar .awards .icon {
      position: absolute;
      left: 0;
      top: -8px; }
    .header-style-5 .lower-topbar .awards .icon .fi:before {
      font-size: 44px;
      font-size: 2.93333rem;
      color: #5487c1; }
      @media (max-width: 991px) {
        .header-style-5 .lower-topbar .awards .icon .fi:before {
          font-size: 35px;
          font-size: 2.33333rem; } }
    .header-style-5 .lower-topbar .awards h4 {
      font-size: 16px;
      font-size: 1.06667rem;
      margin: 0; }
      @media (max-width: 991px) {
        .header-style-5 .lower-topbar .awards h4 {
          font-size: 14px;
          font-size: 0.93333rem; } }
    .header-style-5 .lower-topbar .awards p {
      font-size: 12px;
      font-size: 0.8rem;
      margin: 0; }
  @media screen and (min-width: 992px) {
    .header-style-5 .navigation,
    .header-style-5 .navigation .container {
      background-color: #5487c1; }
    .header-style-5 #navbar > ul > li > a {
      padding: 15px; }
    .header-style-5 #navbar > ul > li > a:before {
      display: none; }
    .header-style-5 #navbar > ul > li > a:hover {
      color: #fff; } }
  @media screen and (min-width: 992px) {
    .header-style-5 .cart-contact {
      right: 195px;
      top: 15px; }
    .header-style-5 .cart-contact .top-cart-content-toggle {
      top: 39px; } }
  .header-style-5 .request-quote {
    width: 140px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    border: 0;
    border-radius: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0; }
    @media (max-width: 991px) {
      .header-style-5 .request-quote {
        right: auto;
        width: 130px;
        height: 40px;
        line-height: 40px;
        top: 12px; } }
  .header-style-5 .request-quote:hover {
    background-color: #080a0b;
    color: #fff; }

/**************************************
	#header style 6
****************************************/
@media (max-width: 767px) {
  .header-style-6 .site-logo {
    height: 61px; } }

@media screen and (min-width: 992px) {
  .header-style-6 .topbar-style-2 {
    background-color: #fff;
    border-bottom: 1px solid #e5e5e5; }
  .header-style-6 .topbar-style-2 .topbar-contact-info p {
    color: #111;
    font-weight: 600; }
  .header-style-6 .site-logo {
    max-width: 257px; } }

@media screen and (min-width: 1200px) {
  .header-style-6 .site-logo {
    max-width: 330px; } }

.header-style-6 .topbar-style-2 .theme-btn, .header-style-6 .topbar-style-2 .theme-btn_light, .header-style-6 .topbar-style-2 .theme-btn-s2, .header-style-6 .topbar-style-2 .theme-btn-s3, .header-style-6 .topbar-style-2 .theme-btn-s4, .header-style-6 .topbar-style-2 .theme-btn-s5 {
  color: #fff; }

.navbar-nav_bt {
  transition: all .5s ease-in-out;
  border-top: 1px solid rgba(40, 49, 56, 0.05); }

.sticky-on .navbar-nav_bt {
  border-top: 1px solid rgba(40, 49, 56, 0); }

/*--------------------------------------------------------------
#0.4	hero slider
--------------------------------------------------------------*/
.hero {
  position: relative;
  height: 680px;
  overflow: hidden;
  /** slider controls **/
  /*** hero slider animation ***/ }
  @media (max-width: 991px) {
    .hero {
      height: 450px; } }
  @media (max-width: 767px) {
    .hero {
      min-height: 420px; } }
  .hero .slide {
    height: 680px;
    position: relative;
    background-repeat: no-repeat;
    position: relative; }
    @media (max-width: 991px) {
      .hero .slide {
        height: 450px; } }
    @media (max-width: 767px) {
      .hero .slide {
        min-height: 420px; } }
    .hero .slide .slider-bg {
      display: none; }
    .hero .slide:before {
      content: "";
      background-color: rgba(0, 0, 0, 0.4);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
  .hero .slide:focus {
    outline: none; }
  .hero .slide .container {
    height: 100%;
    display: table;
    padding: 0; }
  .hero .slide .row {
    display: table-cell;
    vertical-align: middle; }
  .hero .slick-prev,
  .hero .slick-next {
    background-color: #5487c1;
    width: 45px;
    height: 45px;
    z-index: 9;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -o-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
    .hero .slick-prev:hover,
    .hero .slick-next:hover {
      background-color: #437ab9; }
    @media (max-width: 991px) {
      .hero .slick-prev,
      .hero .slick-next {
        display: none !important; } }
  .hero .slick-prev {
    left: 5px; }
    @media screen and (min-width: 1600px) {
      .hero .slick-prev {
        left: 50px; } }
    .hero .slick-prev:before {
      font-family: "FontAwesome";
      content: "\f104";
      opacity: 1; }
  .hero .slick-next {
    right: 5px; }
    @media screen and (min-width: 1600px) {
      .hero .slick-next {
        right: 50px; } }
    .hero .slick-next:before {
      font-family: "FontAwesome";
      content: "\f105";
      opacity: 1; }
  .hero .slick-dots {
    bottom: 30px;
    display: none !important; }
    @media (max-width: 991px) {
      .hero .slick-dots {
        display: block !important; } }
    .hero .slick-dots li,
    .hero .slick-dots li button {
      width: 15px;
      height: 15px; }
      @media (max-width: 767px) {
        .hero .slick-dots li,
        .hero .slick-dots li button {
          width: 14px;
          height: 14px; } }
    .hero .slick-dots li button {
      background-color: #5487c1;
      border: 2px solid #fff;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -o-border-radius: 50%;
      -ms-border-radius: 50%;
      border-radius: 50%; }
    .hero .slick-dots li button:before {
      display: none; }
    .hero .slick-dots li {
      opacity: 0.5; }
    .hero .slick-dots .slick-active {
      opacity: 1; }
  .hero .hero-slider .slide-caption > h2,
  .hero .hero-slider .slide-caption > p,
  .hero .hero-slider .slide-caption > .btns {
    color: #fff;
    opacity: 0; }
  .hero .hero-slider .slick-list .slick-current .slide-caption > h2 {
    -webkit-animation: fadeInLeftSlow 1.5s 0.8s forwards;
    -moz-animation: fadeInLeftSlow 1.5s 0.8s forwards;
    -o-animation: fadeInLeftSlow 1.5s 0.8s forwards;
    -ms-animation: fadeInLeftSlow 1.5s 0.8s forwards;
    animation: fadeInLeftSlow 1.5s 0.8s forwards; }
  .hero .hero-slider .slick-list .slick-current .slide-caption > p {
    -webkit-animation: fadeInLeftSlow 1.5s 1.4s forwards;
    -moz-animation: fadeInLeftSlow 1.5s 1.4s forwards;
    -o-animation: fadeInLeftSlow 1.5s 1.4s forwards;
    -ms-animation: fadeInLeftSlow 1.5s 1.4s forwards;
    animation: fadeInLeftSlow 1.5s 1.4s forwards; }
  .hero .hero-slider .slick-list .slick-current .slide-caption > .btns {
    -webkit-animation: fadeInLeftSlow 1.5s 1.8s forwards;
    -moz-animation: fadeInLeftSlow 1.5s 1.8s forwards;
    -o-animation: fadeInLeftSlow 1.5s 1.8s forwards;
    -ms-animation: fadeInLeftSlow 1.5s 1.8s forwards;
    animation: fadeInLeftSlow 1.5s 1.8s forwards; }
  .hero .hero-slider .slide-caption > h2 {
    font-size: 45px;
    font-size: 3rem;
    line-height: 1.3em;
    color: #fff;
    margin: 0 0 0.27em; }
    @media (max-width: 1199px) {
      .hero .hero-slider .slide-caption > h2 {
        font-size: 35px;
        font-size: 2.33333rem; } }
    @media (max-width: 767px) {
      .hero .hero-slider .slide-caption > h2 {
        font-size: 28px;
        font-size: 1.86667rem; } }
    .hero .hero-slider .slide-caption > h2 span {
      color: #5487c1; }
  .hero .hero-slider .slick-list .slick-current .slide-caption > p {
    font-size: 24px;
    font-size: 1.6rem;
    line-height: 1.5em;
    color: #fff;
    margin: 0 0 1.58em; }
    @media (max-width: 1199px) {
      .hero .hero-slider .slick-list .slick-current .slide-caption > p {
        font-size: 20px;
        font-size: 1.33333rem; } }
    @media (max-width: 767px) {
      .hero .hero-slider .slick-list .slick-current .slide-caption > p {
        font-size: 17px;
        font-size: 1.13333rem; } }
    @media screen and (min-width: 992px) {
      .hero .hero-slider .slick-list .slick-current .slide-caption > p {
        padding-right: 110px; } }
  .hero .hero-slider .slide-caption > .btns > a:first-child {
    margin-right: 5px; }

/**********************************
	= slider style 2
**********************************/
.hero-slider-style-2 {
  text-align: center;
  height: 100vh;
  min-height: 600px; }
  .hero-slider-style-2 .hero-slider .slick-list .slick-current .slide-caption > p {
    padding-right: 0; }
  @media (max-width: 991px) {
    .hero-slider-style-2 {
      height: 450px; } }
  @media (max-width: 767px) {
    .hero-slider-style-2 {
      min-height: 420px; } }
  .hero-slider-style-2 .slide {
    height: 100vh;
    min-height: 600px; }
    @media screen and (min-width: 992px) {
      .hero-slider-style-2 .slide {
        padding-top: 100px; } }
    @media (max-width: 991px) {
      .hero-slider-style-2 .slide {
        height: 450px; } }
    @media (max-width: 767px) {
      .hero-slider-style-2 .slide {
        min-height: 420px; } }
    .hero-slider-style-2 .slide:before {
      content: "";
      background-color: rgba(0, 0, 0, 0.65);
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }

body .hero-slider-fix {
  height: 500px; }
  body .hero-slider-fix .slide {
    height: 500px; }
  body .hero-slider-fix .container .row .slide-caption .b-qualitySlider__text {
    margin-bottom: 0; }

body.products .slide-caption,
body.careers .slide-caption,
body.our-team .slide-caption,
body.quality .slide-caption,
body.downloads .slide-caption,
body.news .slide-caption,
body.blog .slide-caption,
body.contact .slide-caption {
  /*background-image: linear-gradient(90deg,rgba(40,49,56,.9),rgba(40,49,56,.4));*/
  /*padding: 30px;*/
  text-shadow: 1px 1px 4px rgba(31, 31, 31, 0.6); }
  body.products .slide-caption h1, body.products .slide-caption h2, body.products .slide-caption h3,
  body.careers .slide-caption h1,
  body.careers .slide-caption h2,
  body.careers .slide-caption h3,
  body.our-team .slide-caption h1,
  body.our-team .slide-caption h2,
  body.our-team .slide-caption h3,
  body.quality .slide-caption h1,
  body.quality .slide-caption h2,
  body.quality .slide-caption h3,
  body.downloads .slide-caption h1,
  body.downloads .slide-caption h2,
  body.downloads .slide-caption h3,
  body.news .slide-caption h1,
  body.news .slide-caption h2,
  body.news .slide-caption h3,
  body.blog .slide-caption h1,
  body.blog .slide-caption h2,
  body.blog .slide-caption h3,
  body.contact .slide-caption h1,
  body.contact .slide-caption h2,
  body.contact .slide-caption h3 {
    margin-bottom: 0; }
  body.products .slide-caption p,
  body.careers .slide-caption p,
  body.our-team .slide-caption p,
  body.quality .slide-caption p,
  body.downloads .slide-caption p,
  body.news .slide-caption p,
  body.blog .slide-caption p,
  body.contact .slide-caption p {
    margin: 15px 0 0 !important;
    padding: 0; }

/* @import "components/sticky-header";
@import "components/typography";
@import "components/section-title";
@import "components/buttons";
@import "components/form";
@import "components/social-links";
@import "components/page-title";
@import "components/preloader";
@import "components/service-grids";
@import "components/dots-arrow-style";
@import "components/accordion";
@import "components/team-grids";
@import "components/service-single-sidebar";
@import "components/pagination";
@import "components/blog-sidebar.scss";
@import "components/products-grids.scss";
@import "components/shop-sidebar.scss"; */
.theme-accordion-s1 {
  margin-bottom: 0; }
  .theme-accordion-s1 .panel-default {
    background: transparent;
    border: 1px solid #dee0e1;
    border-radius: 0; }
  .theme-accordion-s1 .panel-group .panel + .panel {
    margin-top: 8px; }
  .theme-accordion-s1 .panel-heading {
    background-color: transparent;
    padding: 0;
    border-radius: 0; }
  .theme-accordion-s1 .panel-heading a {
    background-color: rgba(253, 212, 51, 0.95);
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #222;
    display: block;
    padding: 15px 25px;
    position: relative; }
    @media (max-width: 1800px) {
      .theme-accordion-s1 .panel-heading a {
        background-color: #5487c1; } }
    @media (max-width: 991px) {
      .theme-accordion-s1 .panel-heading a {
        font-size: 16px;
        font-size: 1.06667rem;
        padding: 12px 20px; } }
    @media (max-width: 767px) {
      .theme-accordion-s1 .panel-heading a {
        padding: 12px 15px; } }
    .theme-accordion-s1 .panel-heading a:before {
      font-family: "FontAwesome";
      content: "\f107";
      font-size: 20px;
      font-size: 1.33333rem;
      position: absolute;
      right: 25px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      transition: all 0.3s; }
      @media (max-width: 991px) {
        .theme-accordion-s1 .panel-heading a:before {
          font-size: 18px;
          font-size: 1.2rem;
          right: 20px; } }
  .theme-accordion-s1 .panel-heading .collapsed {
    background-color: #fff; }
  .theme-accordion-s1 .panel-heading .collapsed:before {
    -webkit-transform: rotate(0);
    transform: rotate(0); }
  .theme-accordion-s1 .panel-heading + .panel-collapse > .panel-body {
    background: rgba(255, 255, 255, 0.9);
    border: 0;
    padding: 24px 33px 12px; }
    @media (max-width: 1800px) {
      .theme-accordion-s1 .panel-heading + .panel-collapse > .panel-body {
        background-color: #fff; } }
    @media (max-width: 991px) {
      .theme-accordion-s1 .panel-heading + .panel-collapse > .panel-body {
        padding: 20px 20px 10px; } }
    @media (max-width: 767px) {
      .theme-accordion-s1 .panel-heading + .panel-collapse > .panel-body {
        padding: 15px 15px 8px; } }

/*** blog-sidebar ***/
@media (max-width: 991px) {
  .blog-sidebar {
    margin-top: 80px; } }

@media (max-width: 767px) {
  .blog-sidebar {
    margin-top: 70px; } }

.blog-sidebar .widget {
  margin-bottom: 75px; }
  @media (max-width: 991px) {
    .blog-sidebar .widget {
      margin-bottom: 60px; } }
  .blog-sidebar .widget ul {
    list-style: none; }

.blog-sidebar .widget:last-child {
  margin-bottom: 0; }

.blog-sidebar h3 {
  font-size: 20px;
  font-size: 1.33333rem;
  color: #0d0d0d;
  margin: 0 0 1.25em;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .blog-sidebar h3 {
      font-size: 17px;
      font-size: 1.13333rem; } }

.blog-sidebar .category-widget ul li,
.blog-sidebar .archive-widget ul li {
  border-bottom: 1px solid #fff; }
  .blog-sidebar .category-widget ul li > a,
  .blog-sidebar .archive-widget ul li > a {
    padding: 10px 0; }

.blog-sidebar ul li {
  font-size: 15px;
  font-size: 1rem;
  font-weight: bold; }
  .blog-sidebar ul li > a {
    display: block;
    color: #404040;
    position: relative; }
  .blog-sidebar ul li:first-child a {
    padding-top: 0; }
  .blog-sidebar ul li:last-child a {
    padding-bottom: 0; }
  .blog-sidebar ul li:last-child {
    border: 0; }
  .blog-sidebar ul li > a:hover {
    color: #283138; }
  .blog-sidebar ul li > a .badge {
    background-color: transparent;
    font-size: 15px;
    font-size: 1rem;
    color: #404040;
    position: absolute;
    right: 5px; }
  .blog-sidebar ul li > a .badge:before,
  .blog-sidebar ul li > a .badge:after {
    font-size: 14px;
    font-size: 0.93333rem;
    position: absolute;
    top: 3px; }
  .blog-sidebar ul li > a .badge:before {
    content: "(";
    left: 0; }
  .blog-sidebar ul li > a .badge:after {
    content: ")";
    right: 0; }

.blog-sidebar .search-widget input {
  background-color: transparent;
  padding: 8px 12px;
  height: auto; }
  .blog-sidebar .search-widget input:focus {
    box-shadow: none;
    border-color: #283138; }

@media (max-width: 767px) {
  .blog-sidebar .recent-post-widget ul {
    margin-left: -15px; } }

.blog-sidebar .recent-post-widget ul li {
  border: 0;
  overflow: hidden;
  margin-bottom: 30px;
  position: relative; }
  @media (max-width: 767px) {
    .blog-sidebar .recent-post-widget ul li {
      padding: 0 0 40px 100px;
      overflow: visible; } }

.blog-sidebar .recent-post-widget ul li:last-child {
  margin-bottom: 0; }

.blog-sidebar .recent-post-widget .post-pic {
  width: 30%;
  float: left; }
  @media (max-width: 767px) {
    .blog-sidebar .recent-post-widget .post-pic {
      width: 70px;
      float: none;
      position: absolute;
      left: 15px; } }

.blog-sidebar .recent-post-widget .details {
  width: 68%;
  float: right; }
  @media (max-width: 767px) {
    .blog-sidebar .recent-post-widget .details {
      width: 100%;
      float: none; } }
  .blog-sidebar .recent-post-widget .details > span {
    font-size: 12px;
    font-size: 0.8rem;
    color: #666666;
    text-transform: uppercase; }

.blog-sidebar .recent-post-widget .details h4 {
  font-size: 14px;
  font-size: 0.93333rem;
  margin: 0.5em 0;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .blog-sidebar .recent-post-widget .details h4 {
      font-size: 13px;
      font-size: 0.86667rem;
      padding-top: 10px; } }

.blog-sidebar .recent-post-widget .details h4 a {
  color: #000; }

.blog-sidebar .recent-post-widget .details h4 a:hover {
  color: #283138; }

.blog-sidebar .tag-widget .all-tags {
  margin-left: -12px; }
  .blog-sidebar .tag-widget .all-tags a {
    font-size: 15px;
    font-size: 1rem;
    font-weight: bold;
    color: gray;
    border: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s; }
  .blog-sidebar .tag-widget .all-tags a:hover {
    color: #283138; }

.blog-sidebar .instagram-widget ul {
  overflow: hidden;
  margin-right: -8px; }

.blog-sidebar .instagram-widget ul li {
  width: 33.33%;
  float: left;
  padding: 0 8px 8px 0; }

.blog-sidebar .instagram-widget ul li img {
  max-width: 100%; }
  @media (max-width: 767px) {
    .blog-sidebar .instagram-widget ul li img {
      width: 100%; } }

.theme-btn, .theme-btn_light, .theme-btn-s2, .theme-btn-s3, .theme-btn-s4, .theme-btn-s5 {
  background-color: #5487c1;
  font-size: 16px;
  font-size: 1.06667rem;
  font-weight: 600;
  color: #fff;
  padding: 5px 28px;
  border: 2px solid #5487c1;
  transition: all .3s ease-out;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -o-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  min-height: 35.5px; }
  .theme-btn:hover, .theme-btn_light:hover, .theme-btn-s2:hover, .theme-btn-s3:hover, .theme-btn-s4:hover, .theme-btn-s5:hover {
    background-color: #283138;
    border-color: #283138;
    color: #fff; }
  @media (max-width: 1199px) {
    .theme-btn, .theme-btn_light, .theme-btn-s2, .theme-btn-s3, .theme-btn-s4, .theme-btn-s5 {
      font-size: 15px;
      font-size: 1rem;
      padding: 4px 22px; } }
  @media (max-width: 991px) {
    .theme-btn, .theme-btn_light, .theme-btn-s2, .theme-btn-s3, .theme-btn-s4, .theme-btn-s5 {
      font-size: 14px;
      font-size: 0.93333rem;
      padding: 4px 20px; } }
  @media (max-width: 767px) {
    .theme-btn, .theme-btn_light, .theme-btn-s2, .theme-btn-s3, .theme-btn-s4, .theme-btn-s5 {
      font-size: 13px; } }

.theme-btn_light:hover {
  background-color: transparent;
  border-color: #5487c1;
  color: #5487c1; }

.theme-btn-s2 {
  background-color: #1d2429;
  color: #fff; }
  .theme-btn-s2:hover {
    background-color: #5487c1;
    color: #283138; }

.theme-btn-s3:hover {
  border-color: #283138;
  color: #5487c1; }

.theme-btn-s4 {
  background-color: #283138;
  border-color: #283138;
  color: #5487c1; }
  .theme-btn-s4:hover {
    background-color: #5487c1;
    border-color: #5487c1;
    color: #283138; }

.theme-btn-s5 {
  background-color: transparent;
  border: 2px solid #5487c1;
  color: #fff; }
  .theme-btn-s5:hover {
    background-color: #5487c1; }

.certification {
  display: flex;
  align-items: flex-end; }
  .certification::before {
    background-color: rgba(255, 255, 255, 0.3) !important; }
  .certification .contact-women {
    left: auto;
    right: 0; }

.certification__content {
  display: flex;
  padding: 15px;
  align-items: center;
  background-image: linear-gradient(to right, rgba(40, 49, 56, 0.9), rgba(40, 49, 56, 0.4));
  z-index: 5; }
  @media (max-width: 425px) {
    .certification__content {
      padding-bottom: 65px;
      padding-top: 65px; } }
  @media (max-width: 575px) {
    .certification__content {
      display: block; } }
  .certification__content, .certification__content *, .certification__content p {
    font-size: 1.3rem;
    color: #fff !important; }
  @media (max-width: 480px) {
    .certification__content {
      padding-right: 15px; } }
  @media (min-width: 769px) {
    .certification__content {
      padding-left: 30px;
      padding-right: 30px; } }

.certification__txt {
  flex: 1 1 100%;
  padding-right: 30px; }
  .certification__txt > *:last-child {
    margin-bottom: 0; }
  @media (max-width: 575px) {
    .certification__txt {
      padding-right: 0; } }

.certification__img {
  flex: 0 0 200px;
  display: block !important;
  margin: 30px auto; }

.dots-s1 .owl-controls {
  margin-top: 0; }

.dots-s1 .owl-dots {
  height: 15px; }

.dots-s1 .owl-dots .owl-dot span {
  background: #bfbfbf;
  width: 8px;
  height: 8px;
  margin: 0 7px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s; }

.dots-s1 .owl-dots .owl-dot.active span,
.dots-s1 .owl-dots .owl-dot:hover span {
  background-color: #5487c1; }

.dots-s1 .owl-dots .owl-dot.active span {
  width: 9px;
  height: 9px; }

.slider-arrow-s1 .owl-controls {
  margin-top: 0; }

.slider-arrow-s1 .owl-controls .owl-nav .owl-prev,
.slider-arrow-s1 .owl-controls .owl-nav .owl-next {
  background-color: #5487c1;
  width: 34px;
  height: 34px;
  line-height: 37px;
  font-size: 18px;
  font-size: 1.2rem;
  color: #283138;
  padding: 0;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s; }
  .slider-arrow-s1 .owl-controls .owl-nav .owl-prev:hover,
  .slider-arrow-s1 .owl-controls .owl-nav .owl-next:hover {
    background-color: #437ab9;
    color: #283138; }
  @media (max-width: 991px) {
    .slider-arrow-s1 .owl-controls .owl-nav .owl-prev,
    .slider-arrow-s1 .owl-controls .owl-nav .owl-next {
      width: 30px;
      height: 30px;
      line-height: 33px;
      font-size: 16px;
      font-size: 1.06667rem; } }

.form input,
.form textarea,
.form select {
  border-color: #bfbfbf;
  border-radius: 0;
  outline: 0;
  box-shadow: none;
  color: #595959; }
  .form input:focus,
  .form textarea:focus,
  .form select:focus {
    border-color: #283138;
    -webkit-box-shadow: 0 0 5px 0 #3d4b56;
    -moz-box-shadow: 0 0 5px 0 #3d4b56;
    -o-box-shadow: 0 0 5px 0 #3d4b56;
    -ms-box-shadow: 0 0 5px 0 #3d4b56;
    box-shadow: 0 0 5px 0 #3d4b56; }

.form ::-webkit-input-placeholder {
  font-style: 14px;
  font-style: italic;
  color: #595959; }

.form :-moz-placeholder {
  font-style: 14px;
  font-style: italic;
  color: #595959; }

.form ::-moz-placeholder {
  font-style: 14px;
  font-style: italic;
  color: #595959; }

.form :-ms-input-placeholder {
  font-style: 14px;
  font-style: italic;
  color: #595959; }

.wpcf7-form input, .wpcf7-form select {
  height: 34px; }

.wpcf7-form input,
.wpcf7-form textarea,
.wpcf7-form select {
  display: block;
  width: 100%;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.076);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out .15s, -webkit-box-shadow ease-in-out .15s;
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.wpcf7-form textarea {
  padding: 12px;
  line-height: 1.3; }

.wpcf7-form select {
  font-style: normal;
  background-image: url(../images/select-icon.png);
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) center;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: pointer; }

.wpcf7-form select::-ms-expand {
  /* for IE 11 */
  display: none; }

/* FORM ELEMENTS */
.required {
  display: inline-flex; }
  .required::after {
    content: "\f069";
    margin-left: 7px;
    font-family: "FontAwesome";
    font-size: 0.6rem;
    color: #283138; }

.get-quote_head {
  position: static !important;
  float: none !important; }

.img-miniature {
  position: relative;
  display: inline-block;
  max-width: 200px;
  border: 2px solid #5487c1;
  cursor: pointer; }
  .img-miniature, .img-miniature::before, .img-miniature::after, .img-miniature * {
    transition: all .3s ease-in; }
  .img-miniature::before {
    content: "\f00e";
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    font-family: "FontAwesome";
    font-size: 30px;
    color: #5487c1;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0;
    z-index: 2; }
  .img-miniature:hover img {
    filter: grayscale(1); }
  .img-miniature:hover::before {
    opacity: 1; }
  .img-miniature img {
    display: block;
    width: 100%;
    max-width: 100%;
    filter: grayscale(0); }

.modal-win {
  display: none;
  position: fixed;
  width: 100vw;
  height: 100vh;
  padding-top: 70px;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: rgba(40, 49, 56, 0.8); }
  @media (max-width: 575px) {
    .modal-win {
      padding-top: 85px; } }
  .modal-win.open {
    display: flex;
    z-index: 1000; }

.modal-win__wrapper {
  position: relative;
  display: block;
  width: 80%;
  height: 80%; }
  @media (max-width: 767px) {
    .modal-win__wrapper {
      width: 95%; } }

.modal-win__close {
  position: absolute;
  display: flex;
  width: 50px;
  height: 50px;
  top: -50px;
  right: 0;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer; }
  .modal-win__close::before {
    content: "\f00d";
    font-family: "FontAwesome";
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    transition: all .3s ease-in; }
  .modal-win__close:hover::before {
    color: #fff; }

.modal-win__frame {
  text-align: center; }
  .modal-win__frame,
  .modal-win__frame img,
  .modal-win__frame iframe,
  .modal-win__frame video {
    width: 100%;
    height: 100%; }
  .modal-win__frame img {
    object-fit: contain;
    width: auto; }

/******************************
	#blog grids
******************************/
.page-title {
  background: url("../images/page-title.jpg") center center/cover no-repeat local;
  min-height: 480px;
  position: relative;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 0; }
  .page-title:before {
    content: "";
    background-color: rgba(0, 0, 0, 0.3);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .page-title h2,
  .page-title ol {
    color: #fff; }
  .page-title ol {
    margin-top: 15px; }
  .page-title h2 {
    font-size: 3.2rem;
    font-weight: 800;
    margin: 0; }
  .page-title .breadcrumb {
    background-color: transparent;
    padding: 0;
    margin-bottom: 0; }
  .page-title .breadcrumb li {
    font-size: 1.06667rem;
    color: #5487c1;
    margin-right: 5px; }
  .page-title .breadcrumb li a {
    color: #fff; }
  .page-title .breadcrumb li a:hover {
    color: #5487c1; }
  .page-title .breadcrumb li:before {
    content: none; }
  .page-title .breadcrumb li:after {
    font-family: "FontAwesome";
    content: "\f105";
    color: #fff;
    margin-left: 12px; }
  .page-title .breadcrumb li:last-child:after {
    content: none; }
  .page-title .container .row .col {
    text-shadow: 1px 1px 4px rgba(31, 31, 31, 0.6);
    display: inline-block;
    width: auto; }
    .page-title .container .row .col h1 {
      margin-bottom: 0; }
    .page-title .container .row .col p {
      margin-top: 15px; }

.breadcrumb-nw {
  padding: 15px 0;
  background-color: #f2f2f2; }
  .breadcrumb-nw h2,
  .breadcrumb-nw ol {
    color: #2d3840; }
  .breadcrumb-nw ol {
    margin-top: 15px; }
  .breadcrumb-nw h2 {
    font-size: 3.2rem;
    font-weight: 800;
    margin: 0; }
  .breadcrumb-nw .breadcrumb {
    background-color: transparent;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: baseline; }
    @media (max-width: 767px) {
      .breadcrumb-nw .breadcrumb {
        flex-wrap: wrap; } }
  .breadcrumb-nw .breadcrumb li {
    font-size: 1.06667rem;
    color: #5487c1;
    margin-right: 10px; }
    .breadcrumb-nw .breadcrumb li:last-child {
      margin-right: 0; }
  .breadcrumb-nw .breadcrumb li a {
    color: #2d3840; }
  .breadcrumb-nw .breadcrumb li a:hover {
    color: #5487c1; }
  .breadcrumb-nw .breadcrumb li:before {
    content: none; }
  .breadcrumb-nw .breadcrumb li:after {
    font-family: "FontAwesome";
    content: "\f105";
    color: #2d3840;
    margin-left: 12px; }
  .breadcrumb-nw .breadcrumb li:last-child:after {
    content: none; }

/**** pagination ****/
.pagination-wrapper {
  text-align: center; }
  .pagination-wrapper .pg-pagination {
    display: inline-block;
    overflow: hidden;
    list-style-type: none; }
    .pagination-wrapper .pg-pagination li {
      float: left;
      margin-right: 10px; }
      @media (max-width: 767px) {
        .pagination-wrapper .pg-pagination li {
          margin-right: 4px; } }
    .pagination-wrapper .pg-pagination li:first-child i {
      line-height: 33px; }
      @media (max-width: 767px) {
        .pagination-wrapper .pg-pagination li:first-child i {
          line-height: 27px; } }
    .pagination-wrapper .pg-pagination li:last-child {
      margin-right: 0; }
      .pagination-wrapper .pg-pagination li:last-child i {
        line-height: 33px; }
        @media (max-width: 767px) {
          .pagination-wrapper .pg-pagination li:last-child i {
            line-height: 27px; } }
    .pagination-wrapper .pg-pagination li a {
      font-family: "Montserrat", sans-serif;
      background-color: #283138;
      display: block;
      width: 35px;
      height: 35px;
      line-height: 35px;
      color: #fff;
      font-weight: 500; }
      @media (max-width: 767px) {
        .pagination-wrapper .pg-pagination li a {
          width: 27px;
          height: 27px;
          line-height: 27px; } }
    .pagination-wrapper .pg-pagination .active a,
    .pagination-wrapper .pg-pagination li a:hover {
      background-color: #5487c1;
      color: #283138; }

.preloader {
  width: 100%;
  background-color: #fff;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 10001; }
  .preloader div {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }

/**** products grids ***/
.products-grids .grid {
  background-color: #fff;
  -webkit-box-shadow: 0 0 15px 0 #d9d9d9;
  -moz-box-shadow: 0 0 15px 0 #d9d9d9;
  -o-box-shadow: 0 0 15px 0 #d9d9d9;
  -ms-box-shadow: 0 0 15px 0 #d9d9d9;
  box-shadow: 0 0 15px 0 #d9d9d9;
  text-align: center; }

.products-grids .img-holder img {
  display: inline-block; }

.products-grids .img-holder-info-list {
  position: relative; }

.products-grids .info-list {
  width: 152px;
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  @media (max-width: 767px) {
    .products-grids .info-list {
      width: 122px; } }
  .products-grids .info-list > div {
    width: 50px;
    float: left;
    margin-right: 1px; }
    @media (max-width: 767px) {
      .products-grids .info-list > div {
        width: 40px; } }
  .products-grids .info-list > div:last-child {
    margin-right: 0; }
  .products-grids .info-list > div a {
    background-color: #283138;
    height: 45px;
    display: block;
    text-align: center;
    padding: 10px 0; }
    @media (max-width: 767px) {
      .products-grids .info-list > div a {
        height: 40px;
        line-height: 40px;
        padding: 0; } }
  .products-grids .info-list > div a:hover {
    background-color: #1d2429; }
  .products-grids .info-list > div img {
    display: inline-block; }
    @media (max-width: 767px) {
      .products-grids .info-list > div img {
        max-width: 17px; } }

.products-grids .product-info {
  padding: 30px 15px;
  text-align: center;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s; }
  @media (max-width: 767px) {
    .products-grids .product-info {
      padding: 20px 15px; } }
  .products-grids .product-info h3 {
    font-size: 20px;
    font-size: 1.33333rem;
    margin: 0 0 0.4em; }
    @media (max-width: 767px) {
      .products-grids .product-info h3 {
        font-size: 18px;
        font-size: 1.2rem;
        margin: 0 0 0.4em; } }
    .products-grids .product-info h3 a {
      color: #000; }
  .products-grids .product-info .rating i {
    font-size: 18px;
    font-size: 1.2rem;
    color: #5487c1; }
    @media (max-width: 1199px) {
      .products-grids .product-info .rating i {
        font-size: 16px;
        font-size: 1.06667rem; } }
    @media (max-width: 767px) {
      .products-grids .product-info .rating i {
        font-size: 14px;
        font-size: 0.93333rem; } }
  .products-grids .product-info .price {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: 600;
    color: #999999;
    display: block;
    margin-top: 15px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
    @media (max-width: 767px) {
      .products-grids .product-info .price {
        margin-top: 10px;
        font-size: 16px;
        font-size: 1.06667rem; } }

.products-grids .grid:hover {
  -webkit-box-shadow: 0px 11px 35px -6px rgba(40, 49, 56, 0.5);
  -moz-box-shadow: 0px 11px 35px -6px rgba(40, 49, 56, 0.5);
  box-shadow: 0px 11px 35px -6px rgba(40, 49, 56, 0.5); }

.products-grids .grid:hover .product-info {
  background-color: #283138; }

.products-grids .grid:hover .product-info h3 a,
.products-grids .grid:hover .price {
  color: #fff; }

.products-grids .grid:hover .info-list {
  opacity: 1;
  visibility: visible; }

.shop .pagination-wrapper {
  padding-top: 50px;
  clear: both; }
  @media (max-width: 991px) {
    .shop .pagination-wrapper {
      padding-top: 30px; } }

.section-title, .section-title-white, .section-title-s2, .section-title-s5, .section-title-s6, .section-title-s7 {
  margin-bottom: 35px; }
  @media (max-width: 991px) {
    .section-title, .section-title-white, .section-title-s2, .section-title-s5, .section-title-s6, .section-title-s7 {
      margin-bottom: 35px; } }
  .section-title h2, .section-title-white h2, .section-title-s2 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2 {
    font-size: 30px;
    font-size: 2rem;
    text-transform: uppercase;
    margin: 0;
    padding-bottom: 0.5em;
    position: relative;
    letter-spacing: -1px; }
    @media (max-width: 1199px) {
      .section-title h2, .section-title-white h2, .section-title-s2 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2 {
        font-size: 27px;
        font-size: 1.8rem; } }
    @media (max-width: 991px) {
      .section-title h2, .section-title-white h2, .section-title-s2 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2 {
        font-size: 24px;
        font-size: 1.6rem; } }
    @media (max-width: 767px) {
      .section-title h2, .section-title-white h2, .section-title-s2 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2 {
        font-size: 20px;
        font-size: 1.33333rem; } }
    .section-title h2:before, .section-title-white h2:before, .section-title-s2 h2:before, .section-title-s5 h2:before, .section-title-s6 h2:before, .section-title-s7 h2:before {
      content: "";
      background-color: #5487c1;
      width: 33px;
      height: 5px;
      position: absolute;
      left: 0;
      bottom: 0;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -o-border-radius: 3px;
      -ms-border-radius: 3px;
      border-radius: 3px; }
      @media (max-width: 767px) {
        .section-title h2:before, .section-title-white h2:before, .section-title-s2 h2:before, .section-title-s5 h2:before, .section-title-s6 h2:before, .section-title-s7 h2:before {
          height: 3px; } }

.section-title-white h2 {
  color: #fff; }

.section-title-s2, .section-title-s5, .section-title-s6, .section-title-s7 {
  text-align: center; }
  .section-title-s2 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2,
  .section-title-s2 p,
  .section-title-s5 p,
  .section-title-s6 p,
  .section-title-s7 p {
    color: #fff; }
  .section-title-s2 h2:before, .section-title-s5 h2:before, .section-title-s6 h2:before, .section-title-s7 h2:before {
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%); }
  .section-title-s2 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2 {
    margin: 0 0 0.83em; }
  
  .section-title-s2 p,
  .section-title-s5 p,
  .section-title-s6 p,
  .section-title-s7 p {
    margin: 0; }
    @media screen and (min-width: 1200px) {
      
      .section-title-s2 p,
      .section-title-s5 p,
      .section-title-s6 p,
      .section-title-s7 p {
        padding: 0 45px; } }

.section-title-s3, .section-title-s4 {
  margin-bottom: 30px; }
  .section-title-s3 h2, .section-title-s4 h2 {
    font-size: 30px;
    font-size: 2rem;
    line-height: 1.33em;
    margin: 0.13em 0 0;
    padding-bottom: 0.3em;
    position: relative; }
    @media (max-width: 1199px) {
      .section-title-s3 h2, .section-title-s4 h2 {
        font-size: 27px;
        font-size: 1.8rem; } }
    @media (max-width: 991px) {
      .section-title-s3 h2, .section-title-s4 h2 {
        font-size: 24px;
        font-size: 1.6rem; } }
    @media (max-width: 767px) {
      .section-title-s3 h2, .section-title-s4 h2 {
        font-size: 20px;
        font-size: 1.33333rem; } }
    .section-title-s3 h2:before, .section-title-s4 h2:before {
      content: "";
      background-color: #5487c1;
      width: 80px;
      height: 2px;
      position: absolute;
      left: 0;
      bottom: 0; }
  .section-title-s3 > span, .section-title-s4 > span {
    color: #9a9a9a;
    text-transform: uppercase;
    letter-spacing: 2px; }

.section-title-s4 h2 {
  margin: 0 0 0.83em; }

.section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2 {
  color: #283138; }

.section-title-s5 p, .section-title-s6 p, .section-title-s7 p {
  color: #6a6a6a; }

.section-title-s6 h2, .section-title-s7 h2 {
  text-transform: none; }

.section-title-s6 h2:before, .section-title-s7 h2:before {
  display: none; }

.section-title-s7 h2 {
  padding-bottom: 0; }

/*************************************
    = service grids
**************************************/
.services-grids {
  overflow: hidden; }
  .services-grids .inner {
    min-height: 254px;
    position: relative;
    overflow: hidden; }
    @media (max-width: 1199px) {
      .services-grids .inner {
        min-height: 218px; } }
  .services-grids .details {
    width: 100%;
    height: 100%;
    padding: 60px 50px;
    position: absolute;
    left: 0;
    top: 57%;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s; }
    @media (max-width: 1199px) {
      .services-grids .details {
        padding: 50px 30px; } }
  .services-grids .info {
    position: relative; }
  .services-grids .details h3 {
    font-size: 18px;
    font-size: 1.2rem;
    color: #fff;
    font-weight: 600;
    margin: 0 0 1.2em; }
    @media (max-width: 1199px) {
      .services-grids .details h3 {
        font-size: 17px;
        font-size: 1.13333rem;
        margin: 0 0 1.2em; } }
    @media (max-width: 767px) {
      .services-grids .details h3 {
        font-size: 15px;
        font-size: 1rem;
        margin: 0 0 1.2em; } }
    .services-grids .details h3 i {
      background: #5487c1;
      color: #283138;
      padding: 7px 10px;
      -webkit-border-radius: 3px;
      -moz-border-radius: 3px;
      -o-border-radius: 3px;
      -ms-border-radius: 3px;
      border-radius: 3px;
      position: relative;
      left: -5px; }
      @media (max-width: 1199px) {
        .services-grids .details h3 i {
          padding: 5px 8px; } }
  .services-grids .details p {
    font-size: 14px;
    font-size: 0.93333rem;
    color: #fff;
    margin: 0 0 1.79em; }
  .services-grids .details .more {
    font-weight: 600;
    color: #283138;
    text-transform: capitalize; }
  .services-grids .grid:hover .details {
    background-color: rgba(84, 135, 193, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    top: 0; }
  .services-grids .grid:hover .details h3 {
    color: #283138;
    margin: 0 0 0.59em; }
    .services-grids .grid:hover .details h3 i {
      background: transparent; }

/*************************************
    = service sinle sidebar
**************************************/
@media screen and (min-width: 992px) {
  .service-single-sidebar {
    padding-right: 40px; } }

@media (max-width: 991px) {
  .service-single-sidebar {
    margin-top: 80px; } }

.service-single-sidebar ul {
  list-style-type: none; }

.service-single-sidebar > .widget + .widget {
  margin-top: 50px; }

.service-single-sidebar .services-link-widget ul > li + li {
  margin-top: 10px; }

.service-single-sidebar .services-link-widget ul li a {
  background-color: #f5f2f2;
  font-weight: 600;
  color: #242424;
  padding: 14px 20px;
  display: block;
  text-transform: uppercase; }
  @media (max-width: 1199px) {
    .service-single-sidebar .services-link-widget ul li a {
      font-size: 14px;
      font-size: 0.93333rem; } }
  @media (max-width: 767px) {
    .service-single-sidebar .services-link-widget ul li a {
      font-size: 12px;
      font-size: 0.8rem;
      padding: 10px 15px; } }

.service-single-sidebar .services-link-widget ul li a:hover,
.service-single-sidebar .services-link-widget ul li.current a {
  background-color: #5487c1; }

.service-single-sidebar .download-brocher-widget a {
  background-color: #5487c1;
  font-size: 18px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #fff;
  padding: 17px 20px 17px 50px;
  position: relative;
  display: block;
  text-transform: uppercase; }
  @media (max-width: 1199px) {
    .service-single-sidebar .download-brocher-widget a {
      font-size: 14px;
      font-size: 0.93333rem; } }
  @media (max-width: 767px) {
    .service-single-sidebar .download-brocher-widget a {
      font-size: 13px;
      font-size: 0.86667rem;
      padding: 10px 15px 10px 40px; } }

.service-single-sidebar .download-brocher-widget a i {
  background-color: #283138;
  padding: 5px 10px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }

.service-single-sidebar .contact-widget {
  border: 2px solid #e9e9e9;
  padding: 30px 25px; }
  .service-single-sidebar .contact-widget h3 {
    font-size: 20px;
    font-size: 1.33333rem;
    margin: 0 0 0.8em; }
    @media (max-width: 991px) {
      .service-single-sidebar .contact-widget h3 {
        font-size: 17px;
        font-size: 1.13333rem; } }
  .service-single-sidebar .contact-widget p {
    margin: 0; }

/*** shop sidebar ***/
.shop-sidebar {
  /*** range filtering ***/ }
  @media (max-width: 1199px) {
    .shop-sidebar {
      margin-top: 80px; } }
  @media (max-width: 767px) {
    .shop-sidebar {
      margin-top: 70px; } }
  .shop-sidebar ul {
    list-style: none; }
  .shop-sidebar .widget {
    margin-bottom: 65px; }
    .shop-sidebar .widget:last-child {
      margin-bottom: 0; }
    .shop-sidebar .widget h3 {
      font-size: 20px;
      font-size: 1.33333rem;
      margin: 0 0 1.7em;
      padding-bottom: 0.6em;
      text-transform: uppercase;
      position: relative; }
      .shop-sidebar .widget h3:before {
        content: "";
        background-color: #283138;
        width: 30px;
        height: 2px;
        position: absolute;
        left: 0;
        bottom: 0; }
      @media (max-width: 991px) {
        .shop-sidebar .widget h3 {
          font-size: 18px; } }
    .shop-sidebar .widget > ul li {
      font-size: 14px;
      font-size: 0.93333rem; }
    .shop-sidebar .widget > ul li:first-child a {
      padding-top: 0; }
    .shop-sidebar .widget > ul li:last-child a {
      padding-bottom: 0;
      border-bottom: 0; }
    .shop-sidebar .widget > ul li a {
      display: block;
      font-weight: bold;
      color: #333333;
      padding: 9px 0;
      border-bottom: 1px solid #e6e6e6;
      position: relative; }
    .shop-sidebar .widget > ul li a:hover,
    .shop-sidebar .widget > ul li a:hover .badge {
      color: #283138; }
    .shop-sidebar .widget > ul li a i {
      position: absolute;
      right: 18px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%); }
    .shop-sidebar .widget > ul li a .badge {
      color: #333333;
      background: transparent;
      position: absolute;
      right: 7px; }
    .shop-sidebar .widget > ul li a .badge:before {
      content: "(";
      position: absolute;
      left: 0; }
    .shop-sidebar .widget > ul li a .badge:after {
      content: ")";
      position: absolute;
      right: 0; }
  .shop-sidebar .filter-price-widget .slider.slider-horizontal {
    width: 100%; }
    .shop-sidebar .filter-price-widget .slider.slider-horizontal .slider-track {
      background: #d9d9d9 !important;
      height: 2px;
      margin: 0; }
      .shop-sidebar .filter-price-widget .slider.slider-horizontal .slider-track .slider-selection {
        background: #404040 !important; }
    .shop-sidebar .filter-price-widget .slider.slider-horizontal .slider-handle {
      background: #283138;
      width: 14px;
      height: 14px;
      top: 3px; }
  .shop-sidebar .filter-price-widget .value {
    overflow: hidden;
    margin-top: 17px; }
    .shop-sidebar .filter-price-widget .value > div {
      display: inline-block;
      float: left; }
  .shop-sidebar .filter-price-widget .price {
    font-size: 14px;
    font-size: 0.93333rem;
    font-weight: bold;
    color: #999999;
    line-height: 30px;
    float: right !important; }
    .shop-sidebar .filter-price-widget .price #min-value,
    .shop-sidebar .filter-price-widget .price #max-value {
      color: #000; }
    .shop-sidebar .filter-price-widget .price #max-value {
      display: inline-block;
      position: relative;
      padding-left: 15px; }
      .shop-sidebar .filter-price-widget .price #max-value:before {
        content: "";
        border-bottom: 1px solid #000;
        width: 10px;
        height: 1px;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%); }
  .shop-sidebar .filter-price-widget .theme-btn, .shop-sidebar .filter-price-widget .theme-btn_light, .shop-sidebar .filter-price-widget .theme-btn-s2, .shop-sidebar .filter-price-widget .theme-btn-s3, .shop-sidebar .filter-price-widget .theme-btn-s4, .shop-sidebar .filter-price-widget .theme-btn-s5 {
    height: 30px;
    line-height: 30px;
    font-size: 13px;
    font-size: 0.86667rem;
    font-weight: bold;
    padding: 0 17px;
    text-transform: uppercase;
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    -o-border-radius: 15px;
    -ms-border-radius: 15px;
    border-radius: 15px; }
    .shop-sidebar .filter-price-widget .theme-btn:before, .shop-sidebar .filter-price-widget .theme-btn_light:before, .shop-sidebar .filter-price-widget .theme-btn-s2:before, .shop-sidebar .filter-price-widget .theme-btn-s3:before, .shop-sidebar .filter-price-widget .theme-btn-s4:before, .shop-sidebar .filter-price-widget .theme-btn-s5:before {
      display: none; }

.simpletab {
  display: none;
  opacity: 0;
  transition: all 1.5s ease-out; }
  .simpletab.show {
    display: block;
    opacity: 1; }

.site-header_rel {
  position: relative; }

.site-logo_main {
  position: absolute !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 330px;
  max-height: 170px;
  top: -90px;
  bottom: 0;
  transition: all .3s ease-out;
  padding-right: 20px;
  margin: auto !important;
  height: auto;
  background-position: 15px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%; }
  @media (min-width: 768px) {
    .site-logo_main {
      background-position: center; } }
  @media (max-width: 991px) {
    .site-logo_main {
      max-width: 320px !important;
      bottom: -60px; } }
  @media (max-width: 767px) {
    .site-logo_main {
      position: static !important;
      height: auto;
      max-width: 260px !important;
      max-height: 100px;
      padding-left: 10px; } }
  .site-logo_main img {
    display: block;
    height: 90%;
    width: auto;
    max-width: 100%;
    margin: auto;
    margin-left: 0;
    object-fit: contain; }

.sticky-on .site-logo_main {
  top: 0; }
  @media (max-width: 767px) {
    .sticky-on .site-logo_main {
      max-height: 60px; } }

.social-links {
  overflow: hidden;
  list-style-type: none; }
  .social-links li {
    float: left; }
    .social-links li a {
      display: block;
      text-align: center; }

.social-list {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-right: 0 !important; }
  .social-list > * {
    flex: 0 0 50px;
    font-size: 1.5rem; }

.social-list_footer {
  width: 100%;
  margin-top: 30px; }
  .social-list_footer a .fa {
    transition: all .3s ease-in;
    color: #5487c1; }
    .social-list_footer a .fa:hover {
      color: #fff; }

.social-list_header {
  margin-top: 20px;
  justify-content: flex-end;
  text-align: right; }
  @media (max-width: 767px) {
    .social-list_header {
      justify-content: center;
      text-align: center; } }
  .social-list_header a .fa {
    transition: all .3s ease-in;
    color: #283138;
    font-size: 1.5rem; }
    .social-list_header a .fa:hover {
      color: #5487c1; }

.sticky-header {
  width: 100%;
  position: fixed;
  left: 0;
  top: -100%;
  z-index: 10000;
  opacity: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.7s;
  -moz-transition: all 0.7s;
  -o-transition: all 0.7s;
  -ms-transition: all 0.7s;
  transition: all 0.7s; }

.sticky-on {
  opacity: 1;
  top: 0; }

.header-style-3 .sticky-header {
  background-color: #3d4b56; }
  @media screen and (min-width: 992px) {
    .header-style-3 .sticky-header #navbar > ul > li > a:hover {
      color: #5487c1; } }

.header-style-4 .sticky-header, .header-style-5 .sticky-header {
  background-color: #fff; }

.header-style-5 .sticky-header {
  background-color: #5487c1; }

.header-style-5 .sticky-header .container {
  border-radius: 0; }

/*************************************
    = team grids
**************************************/
.team-grids .team-grid {
  overflow: hidden;
  position: relative; }

.team-grids .member-pic-social {
  position: relative;
  overflow: hidden; }

.team-grids .social {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  -webkit-transition: all 0.6s;
  -moz-transition: all 0.6s;
  -o-transition: all 0.6s;
  -ms-transition: all 0.6s;
  transition: all 0.6s; }

.team-grids .social-links {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 11;
  opacity: 0; }

.team-grids .member-pic-social:hover .social-links {
  opacity: 1; }

.team-grids .social-links > li + li {
  margin-left: 20px; }

.team-grids .social-links li a {
  font-size: 24px;
  font-size: 1.6rem;
  color: #fff; }
  @media (max-width: 1199px) {
    .team-grids .social-links li a {
      font-size: 18px;
      font-size: 1.2rem; } }

.team-grids .social-links li a:hover {
  color: #283138; }

.team-grids .member-info {
  text-align: center;
  padding-top: 25px; }
  .team-grids .member-info h3 {
    font-size: 22px;
    font-size: 1.46667rem;
    margin: 0 0 3px; }
    @media (max-width: 1199px) {
      .team-grids .member-info h3 {
        font-size: 18px;
        font-size: 1.2rem; } }
    @media (max-width: 991px) {
      .team-grids .member-info h3 {
        font-size: 16px;
        font-size: 1.06667rem; } }
  @media (max-width: 991px) {
    .team-grids .member-info p {
      font-size: 14px;
      font-size: 0.93333rem; } }

.team-grids .owl-controls .owl-nav {
  width: 100%;
  position: absolute;
  left: 0;
  top: 59%;
  z-index: 10; }
  @media (max-width: 1199px) {
    .team-grids .owl-controls .owl-nav {
      display: none; } }

.team-grids .owl-controls .owl-dots {
  position: relative;
  top: 80px;
  z-index: 10; }
  @media (max-width: 1199px) {
    .team-grids .owl-controls .owl-dots {
      top: 0; } }

.team-grids .owl-controls .owl-nav .owl-prev,
.team-grids .owl-controls .owl-nav .owl-next {
  background: transparent;
  font-size: 24px;
  font-size: 1.6rem;
  color: #283138;
  padding: 0;
  position: absolute; }

.team-grids .owl-controls .owl-nav .owl-prev:hover,
.team-grids .owl-controls .owl-nav .owl-next:hover {
  background: transparent;
  color: #5487c1; }

.team-grids .owl-controls .owl-nav .owl-prev {
  left: -65px; }

.team-grids .owl-controls .owl-nav .owl-next {
  right: -65px; }

.team-grids .owl-controls .owl-dots .active span,
.team-grids .owl-controls .owl-dots .owl-dot:hover span {
  background: #5487c1; }

.square-hover-effect {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10; }
  .square-hover-effect .hover-1,
  .square-hover-effect .hover-2,
  .square-hover-effect .hover-3,
  .square-hover-effect .hover-4 {
    background: rgba(84, 135, 193, 0.9);
    width: 50%;
    height: 50%;
    position: absolute;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    transition: all 0.5s;
    opacity: 0; }
  .square-hover-effect .hover-1 {
    left: 0;
    top: 0; }
  .square-hover-effect .hover-2 {
    left: 50%;
    top: 0; }
  .square-hover-effect .hover-3 {
    left: 50%;
    top: 50%; }
  .square-hover-effect .hover-4 {
    left: 0;
    top: 50%; }

.square-hover-effect:hover .hover-1,
.square-hover-effect-parent:hover .hover-1 {
  left: 50%;
  top: 0;
  opacity: 1; }

.square-hover-effect:hover .hover-2,
.square-hover-effect-parent:hover .hover-2 {
  left: 50%;
  top: 50%;
  opacity: 1; }

.square-hover-effect:hover .hover-3,
.square-hover-effect-parent:hover .hover-3 {
  left: 0%;
  top: 50%;
  opacity: 1; }

.square-hover-effect:hover .hover-4,
.square-hover-effect-parent:hover .hover-4 {
  left: 0%;
  top: 0%;
  opacity: 1; }

.team-slider_factory.team-grids .owl-controls .owl-nav {
  top: 46%; }

.team-slider_factory.team-grids .owl-controls .owl-nav .owl-next,
.team-slider_factory.team-grids .owl-controls .owl-nav .owl-prev {
  color: #fff; }

.team-slider_factory.team-grids .owl-controls .owl-dots {
  display: none !important; }
  @media (max-width: 1199px) {
    .team-slider_factory.team-grids .owl-controls .owl-dots {
      display: block !important; } }

.team-slider_factory-name {
  position: absolute;
  display: flex;
  top: 10px;
  bottom: 10px;
  left: 10px;
  right: 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  transition: all .3s ease-out;
  z-index: 10;
  cursor: pointer; }
  .team-slider_factory-name:hover {
    opacity: 1; }

/* Lists */
.ul {
  list-style: none; }
  .ul li {
    display: flex;
    align-items: baseline;
    padding: 7px 0;
    line-height: 1.5; }
    .ul li::before {
      content: "";
      display: block;
      flex: 0 0 10px;
      width: 10px;
      min-width: 10px;
      height: 10px;
      margin-right: 15px;
      border-radius: 50%;
      background-color: #5487c1; }

/* Media Elements */
.video-pic, .video-player {
  padding: 40px 0 0 45px;
  position: relative;
  cursor: pointer; }
  .video-pic::before, .video-player::before {
    content: "\f04b";
    font: normal normal normal 50px/1 FontAwesome;
    position: absolute;
    display: flex;
    width: 100px;
    height: 100px;
    padding-left: 10px;
    top: 0;
    left: 40px;
    right: 0;
    bottom: 0;
    margin: auto;
    align-items: center;
    justify-content: center;
    border: 10px solid #5487c1;
    border-radius: 50%;
    color: #5487c1;
    transition: all .3s ease-out;
    z-index: 3; }
  .video-pic:after, .video-player:after {
    content: "";
    position: absolute;
    top: -25px;
    bottom: -25px;
    left: -15px;
    right: 100px;
    border: 2px solid #5487c1; }
  .video-pic:hover::before, .video-player:hover::before {
    color: #fff;
    border-color: #fff; }
  .video-pic img, .video-player img {
    position: relative;
    z-index: 2; }

.video-player {
  display: block; }
  .video-player.index__vidLink {
    padding-top: 0; }
  @media (max-width: 991px) {
    .video-player {
      padding: 0;
      margin: 30px 0; }
      .video-player::after {
        content: none; }
      .video-player::before {
        left: 0;
        right: 0;
        top: 0;
        bottom: 0; }
      .video-player img {
        width: 100%; } }
  @media (max-width: 480px) {
    .video-player::before {
      width: 50px;
      height: 50px;
      padding-left: 5px;
      border-width: 3px;
      font-size: 30px; } }

/* @import "page/home-default";
@import "page/home-style2";
@import "page/home-style3";
@import "page/faq";
@import "page/service-single";
@import "page/project-single";
@import "page/contact";
@import "page/blog-grid";
@import "page/blog-with-sidebar";
@import "page/blog-details";
@import "page/shop";
@import "page/shop-with-sidebar";
@import "page/shop-details"; */
.pagenotfound__inner {
  text-align: center; }

.pagenotfound__header {
  text-align: center; }
  .pagenotfound__header:after {
    left: 50% !important;
    transform: translateX(-50%) !important; }

.pagenotfound__text {
  color: #283128;
  font-size: 1.3rem;
  color: #6a6a6a;
  line-height: 1.8em;
  margin-bottom: .9375rem; }

.pagenotfound .button {
  display: inline-block; }

/*--------------------------------------------------------------
	blog details page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#9.0	blog-single-content
--------------------------------------------------------------*/
.blog-single {
  background-color: #f2f2f2; }

.blog-single-content {
  /*** post ***/
  /*** tags ***/
  /*** comments ***/ }
  .blog-single-content ul {
    list-style: none; }
  @media screen and (min-width: 992px) {
    .blog-single-content {
      margin-bottom: 80px; } }
  @media (max-width: 991px) {
    .blog-single-content {
      margin-bottom: 100px; } }
  .blog-single-content .post-title-meta .btn {
    background-color: #283138;
    font-size: 12px;
    font-size: 0.8rem;
    color: #fff;
    text-transform: uppercase;
    border: 0;
    border-radius: 0;
    cursor: auto; }
  .blog-single-content .post-title-meta h2 {
    font-size: 30px;
    font-size: 2rem;
    font-weight: bold;
    margin: 0.57em 0 0.53em;
    line-height: 1.3em;
    color: #1a1a1a; }
    @media (max-width: 767px) {
      .blog-single-content .post-title-meta h2 {
        font-size: 22px;
        font-size: 1.46667rem; } }
  .blog-single-content .post-title-meta ul {
    overflow: hidden;
    margin-bottom: 24px; }
    .blog-single-content .post-title-meta ul li {
      font-size: 14px;
      font-size: 0.93333rem;
      float: left;
      text-transform: uppercase;
      margin-right: 8px;
      padding-right: 8px;
      position: relative; }
      .blog-single-content .post-title-meta ul li:after {
        content: "/";
        color: #999999;
        position: absolute;
        right: 0; }
      .blog-single-content .post-title-meta ul li:last-child:after {
        display: none; }
      .blog-single-content .post-title-meta ul li a {
        color: #999999; }
      .blog-single-content .post-title-meta ul li a:hover {
        color: #283138; }
  .blog-single-content h3 {
    font-size: 21px;
    font-size: 1.4rem;
    color: #1a1a1a;
    margin: 0; }
    @media (max-width: 767px) {
      .blog-single-content h3 {
        font-size: 18px; } }
  .blog-single-content p, .blog-single-content ul, .blog-single-content li {
    font-size: 16px;
    font-size: 1.06667rem;
    color: #6a6a6a;
    margin-bottom: 15px; }
    @media (max-width: 767px) {
      .blog-single-content p, .blog-single-content ul, .blog-single-content li {
        font-size: 14px; } }
  .blog-single-content .post,
  .blog-single-content .comments {
    background-color: #fff; }
  .blog-single-content .post {
    padding: 70px 45px 70px 45px; }
    @media (max-width: 991px) {
      .blog-single-content .post {
        padding: 45px 45px 45px; } }
    @media (max-width: 767px) {
      .blog-single-content .post {
        padding: 45px 20px 45px 20px; } }
    .blog-single-content .post h3 {
      margin: 1.9em 0 0.9em; }
  .blog-single-content .post .media {
    margin: 0 -45px 48px; }
    @media (max-width: 767px) {
      .blog-single-content .post .media {
        margin: 0 -20px 40px; } }
  .blog-single-content .gallery-post {
    margin: 35px 0 0; }
    .blog-single-content .gallery-post .gallery {
      overflow: hidden; }
    .blog-single-content .gallery-post .gallery > div:first-child {
      width: 60%;
      float: left; }
    .blog-single-content .gallery-post .gallery > div:last-child {
      width: 38%;
      float: right; }
  .blog-single-content .tag-share {
    overflow: hidden;
    margin: 25px 0 15px;
    /*** share ***/ }
    @media (max-width: 767px) {
      .blog-single-content .tag-share {
        margin: 25px 0; } }
    .blog-single-content .tag-share > div {
      display: inline-block;
      float: left; }
      @media (max-width: 767px) {
        .blog-single-content .tag-share > div {
          display: block;
          float: none !important; } }
    .blog-single-content .tag-share > div:last-child {
      float: right; }
    .blog-single-content .tag-share > div:first-child {
      padding-left: 20px; }
      .blog-single-content .tag-share > div:first-child span {
        font-size: 15px;
        font-size: 1rem;
        font-weight: bold;
        color: #000;
        margin-right: 25px;
        position: relative;
        top: -11px; }
      @media (max-width: 991px) {
        .blog-single-content .tag-share > div:first-child {
          padding-left: 0; }
          .blog-single-content .tag-share > div:first-child span {
            font-size: 14px;
            font-size: 0.93333rem;
            margin-right: 15px;
            top: -20px; } }
      @media (max-width: 767px) {
        .blog-single-content .tag-share > div:first-child {
          padding-left: 0; }
          .blog-single-content .tag-share > div:first-child span {
            font-size: 14px;
            font-size: 0.93333rem;
            margin-right: 15px;
            top: 0;
            display: block;
            margin-bottom: 5px; } }
    .blog-single-content .tag-share ul {
      display: inline-block;
      overflow: hidden; }
      .blog-single-content .tag-share ul li {
        float: left;
        margin-right: 12px; }
      .blog-single-content .tag-share ul li:last-child {
        margin-right: 0; }
    .blog-single-content .tag-share .tag a {
      background-color: #fff;
      font-size: 14px;
      font-size: 0.93333rem;
      color: #333333;
      display: inline-block;
      text-align: center;
      padding: 7px 15px;
      text-transform: lowercase; }
      @media (max-width: 991px) {
        .blog-single-content .tag-share .tag a {
          padding: 5px 10px;
          margin-bottom: 10px; } }
    .blog-single-content .tag-share .tag a:hover {
      background-color: #5487c1;
      color: #fff; }
    .blog-single-content .tag-share .share {
      padding-bottom: 0; }
    .blog-single-content .tag-share .share li {
      opacity: 0.9; }
    .blog-single-content .tag-share .share li:hover {
      opacity: 1; }
    .blog-single-content .tag-share .share li:first-child {
      background-color: #106ed2; }
    .blog-single-content .tag-share .share li:nth-child(2) {
      background-color: #3ad0fb; }
    .blog-single-content .tag-share .share li:last-child {
      background-color: #1379bb; }
    .blog-single-content .tag-share .share a {
      font-size: 16px;
      font-size: 1.06667rem;
      color: #fff;
      display: inline-block;
      padding: 6px 15px; }
      @media (max-width: 991px) {
        .blog-single-content .tag-share .share a {
          font-size: 14px;
          font-size: 0.93333rem;
          padding: 5px 10px; } }
    .blog-single-content .tag-share .share a i {
      display: inline-block;
      padding-right: 4px; }
  .blog-single-content .comments {
    padding: 55px 45px 0;
    /*** .article ***/ }
    @media (max-width: 1199px) {
      .blog-single-content .comments {
        padding: 45px 25px 0; } }
    @media (max-width: 767px) {
      .blog-single-content .comments {
        padding: 40px 25px 0; } }
    .blog-single-content .comments .title {
      margin-bottom: 65px; }
    .blog-single-content .comments h3 {
      font-size: 22px;
      font-size: 1.46667rem;
      text-align: center;
      font-weight: bold; }
    .blog-single-content .comments > ol {
      padding-left: 0; }
    .blog-single-content .comments ol {
      list-style: none; }
      .blog-single-content .comments ol > li {
        margin-bottom: 35px; }
      .blog-single-content .comments ol > li:last-child {
        margin-bottom: 0; }
      .blog-single-content .comments ol > li > ol {
        margin-left: 28px;
        margin-top: 35px;
        padding: 0; }
        @media (max-width: 767px) {
          .blog-single-content .comments ol > li > ol {
            margin: 30px 0 0 0; } }
      .blog-single-content .comments ol > li > ol > li {
        background-color: #fafafa;
        padding: 30px;
        margin-bottom: 35px !important; }
        @media (max-width: 767px) {
          .blog-single-content .comments ol > li > ol > li {
            padding: 20px; } }
      .blog-single-content .comments ol > li > ol > li:last-child {
        margin-bottom: 0 !important; }
    .blog-single-content .comments .article {
      overflow: hidden; }
      .blog-single-content .comments .article h4 {
        font-size: 16px;
        font-size: 1.06667rem;
        margin: 0;
        text-transform: capitalize; }
        @media (max-width: 767px) {
          .blog-single-content .comments .article h4 {
            font-size: 14px; } }
      .blog-single-content .comments .article p {
        font-size: 15px;
        font-size: 1rem;
        margin-bottom: 5px; }
        @media (max-width: 767px) {
          .blog-single-content .comments .article p {
            font-size: 14px; } }
      .blog-single-content .comments .article .replay button {
        background-color: transparent;
        font-size: 16px;
        font-size: 1.06667rem;
        font-weight: bold;
        color: #283138;
        padding: 0;
        border: 0;
        outline: 0; }
        @media (max-width: 767px) {
          .blog-single-content .comments .article .replay button {
            font-size: 14px; } }
    .blog-single-content .comments .article .author-meta {
      overflow: hidden;
      margin-bottom: 5px; }
      .blog-single-content .comments .article .author-meta > div {
        display: inline-block; }
      .blog-single-content .comments .article .author-meta > div:last-child {
        font-size: 13px;
        font-size: 0.86667rem;
        color: gray;
        padding-left: 5px; }
    .blog-single-content .comments .article .author-pic {
      width: 10%;
      float: left; }
      @media (max-width: 767px) {
        .blog-single-content .comments .article .author-pic {
          width: 100%;
          float: none;
          margin-bottom: 10px; } }
    .blog-single-content .comments .article .details {
      width: 90%;
      float: left; }
      @media (max-width: 767px) {
        .blog-single-content .comments .article .details {
          width: 100%;
          float: none; } }
  .blog-single-content .comment-respond {
    margin-top: 75px; }
    .blog-single-content .comment-respond h3 {
      margin: 0 0 2em; }
    .blog-single-content .comment-respond form {
      margin: 0 -15px;
      position: relative; }
    .blog-single-content .comment-respond form input,
    .blog-single-content .comment-respond form textarea {
      border-radius: 0;
      border: 0;
      box-shadow: none;
      border-bottom: 1px solid #cccccc; }
    .blog-single-content .comment-respond form textarea {
      height: 110px; }
    .blog-single-content .comment-respond form > div {
      margin-bottom: 50px; }
    .blog-single-content .comment-respond form .theme-btn-s2 {
      text-transform: none;
      box-shadow: none;
      position: absolute;
      left: 50%;
      bottom: -100px;
      -webkit-transform: translateX(-50%);
      -moz-transform: translateX(-50%);
      -o-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%); }
      @media (max-width: 991px) {
        .blog-single-content .comment-respond form .theme-btn-s2 {
          position: relative;
          bottom: 0; } }

/*--------------------------------------------------------------
#7.0	blog-grid section
--------------------------------------------------------------*/
.blog-grid-section .news-grids {
  overflow: hidden;
  padding-top: 0;
  column-count: 3;
  column-gap: 0; }
  @media (max-width: 991px) {
    .blog-grid-section .news-grids {
      column-count: 2; } }
  @media (max-width: 580px) {
    .blog-grid-section .news-grids {
      column-count: 1; } }

.blog-grid-section .news-grids .grid {
  margin-bottom: 30px;
  width: 100%;
  display: inline-block;
  float: none; }
  .blog-grid-section .news-grids .grid .b-blog__img {
    width: 100%;
    object-fit: cover;
    padding: 1.875rem 1.875rem 0; }

.blog-grid-section .news-grids > .grid:last-child {
  margin-top: 0; }

.blog-grid-section .pagination-wrapper {
  margin-top: 50px; }
  @media (max-width: 991px) {
    .blog-grid-section .pagination-wrapper {
      margin-top: 30px; } }
  @media (max-width: 767px) {
    .blog-grid-section .pagination-wrapper {
      margin-top: 20px; } }

/*--------------------------------------------------------------
# blog with sidebar page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#8.0	blog-with-sidebar
--------------------------------------------------------------*/
.blog-with-sidebar .news-grids {
  padding-top: 0; }

.blog-with-sidebar .news-grids .grid {
  width: 50%;
  margin-bottom: 30px; }
  @media (max-width: 580px) {
    .blog-with-sidebar .news-grids .grid {
      width: 100%; } }

.blog-with-sidebar .news-grids > .grid:last-child {
  margin-top: 0; }

.blog-with-sidebar .pagination-wrapper {
  margin-top: 50px; }
  @media (max-width: 991px) {
    .blog-with-sidebar .pagination-wrapper {
      margin-top: 30px; } }
  @media (max-width: 767px) {
    .blog-with-sidebar .pagination-wrapper {
      margin-top: 20px; } }

/*--------------------------------------------------------------
#6.0	contact-pg-section
--------------------------------------------------------------*/
.contact-pg-section {
  /*** contact form ***/ }
  .contact-pg-section .contact-info ul li {
    padding-left: 35px;
    position: relative;
    list-style-type: none; }
  .contact-pg-section .contact-info ul > li + li {
    margin-top: 50px; }
    @media (max-width: 991px) {
      .contact-pg-section .contact-info ul > li + li {
        margin-top: 25px; } }
  .contact-pg-section .contact-info .icon {
    background-color: #5487c1;
    width: 35px;
    height: 35px;
    line-height: 38px;
    font-size: 18px;
    font-size: 1.2rem;
    color: #fff;
    text-align: center;
    position: relative;
    left: 0;
    top: 0;
    border-radius: 50%; }
  .contact-pg-section .contact-info p {
    font-size: 17px;
    font-size: 1.13333rem;
    font-weight: bold;
    line-height: 1.4em;
    color: #283138;
    margin: 0 0 0 15px; }
    @media (max-width: 1199px) {
      .contact-pg-section .contact-info p {
        font-size: 15px;
        font-size: 1rem; } }
  .contact-pg-section .contact-info p span {
    display: block;
    color: #969fab; }
  .contact-pg-section .location-map {
    background-color: red;
    height: 357px;
    border-radius: 4px; }
    @media (max-width: 991px) {
      .contact-pg-section .location-map {
        margin-top: 40px; } }
  .contact-pg-section .contact-form {
    margin-top: 70px; }
    .contact-pg-section .contact-form > div {
      margin-bottom: 40px; }
      @media (max-width: 991px) {
        .contact-pg-section .contact-form > div {
          margin-bottom: 20px; } }
    .contact-pg-section .contact-form label {
      font-size: 22px;
      font-size: 1.2rem;
      color: #283138;
      font-weight: 600; }
      @media (max-width: 1199px) {
        .contact-pg-section .contact-form label {
          font-size: 15px;
          font-size: 1rem; } }
    .contact-pg-section .contact-form input,
    .contact-pg-section .contact-form textarea {
      height: 45px;
      border: 1px solid #d1d7e0;
      border-radius: 4px;
      box-shadow: none; }
      @media (max-width: 991px) {
        .contact-pg-section .contact-form input,
        .contact-pg-section .contact-form textarea {
          height: 35px;
          border: 1px solid #d1d7e0;
          border-radius: 4px; } }
    .contact-pg-section .contact-form textarea {
      height: 230px; }
      @media (max-width: 991px) {
        .contact-pg-section .contact-form textarea {
          height: 150px; } }
    .contact-pg-section .contact-form .submit-btn {
      margin-bottom: 0; }
    .contact-pg-section .contact-form .submit-btn button {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 18px;
      font-size: 1.2rem;
      padding: 0;
      font-weight: 600;
      border-radius: 4px; }
      @media (max-width: 1199px) {
        .contact-pg-section .contact-form .submit-btn button {
          height: 45px;
          line-height: 45px;
          font-size: 15px;
          font-size: 1rem; } }
      @media (max-width: 991px) {
        .contact-pg-section .contact-form .submit-btn button {
          height: 40px;
          line-height: 40px; } }
  .contact-pg-section #contact-form-s2 #loader i {
    color: #283138; }

.wpcf7-form-control-wrap .wpcf7-not-valid-tip {
  color: #e2574c; }

/*--------------------------------------------------------------
#4.0	faq-pg-section
--------------------------------------------------------------*/
.faq-pg-section .section-title-s4 p span {
  display: block;
  font-weight: 600; }

@media screen and (min-width: 992px) {
  .faq-pg-section .faq-section {
    border: 1px solid #dee0e1;
    padding: 70px;
    border-radius: 5px; } }

/*--------------------------------------------------------------
#0.6	services
--------------------------------------------------------------*/
.all-service-link,
.all-news-link {
  text-align: right; }
  @media (max-width: 991px) {
    .all-service-link,
    .all-news-link {
      text-align: left;
      margin-top: 25px; } }

.service-slider .owl-controls {
  margin-top: 50px; }
  @media (max-width: 991px) {
    .service-slider .owl-controls {
      margin-top: 35px; } }

@media (max-width: 991px) {
  .service-slider {
    margin-top: 50px; } }

.services-grid-section .all-services {
  text-align: center;
  padding-top: 30px; }

.services-grid-view {
  overflow: hidden;
  margin: 0 -15px; }
  .services-grid-view .grid {
    width: 33.33%;
    float: left;
    padding: 0 15px 30px; }
    @media (max-width: 991px) {
      .services-grid-view .grid {
        width: 50%; } }
    @media (max-width: 650px) {
      .services-grid-view .grid {
        width: 100%;
        float: none; } }

/*--------------------------------------------------------------
#0.7	testimonials
--------------------------------------------------------------*/
.testimonials {
  position: relative; }
  .testimonials:before {
    content: "";
    background-color: rgba(40, 49, 56, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }

.testimonials-slider {
  position: relative; }
  .testimonials-slider .inner {
    padding: 0 155px; }
    @media (max-width: 1199px) {
      .testimonials-slider .inner {
        padding: 0 120px; } }
    @media (max-width: 767px) {
      .testimonials-slider .inner {
        padding: 0; } }
  .testimonials-slider .slide-item:before {
    font-family: "Flaticon";
    content: "\f102";
    font-size: 60px;
    color: #5487c1;
    position: absolute;
    left: 30px;
    top: -14px; }
    @media (max-width: 1199px) {
      .testimonials-slider .slide-item:before {
        font-size: 50px;
        font-size: 3.33333rem; } }
    @media (max-width: 991px) {
      .testimonials-slider .slide-item:before {
        font-size: 40px;
        font-size: 2.66667rem; } }
    @media (max-width: 767px) {
      .testimonials-slider .slide-item:before {
        font-size: 35px;
        font-size: 2.33333rem; } }
    @media (max-width: 767px) {
      .testimonials-slider .slide-item:before {
        display: none; } }
  .testimonials-slider .slide-item img {
    width: auto; }
  .testimonials-slider .slide-item p {
    font-size: 22px;
    font-size: 1.46667rem;
    color: #fff; }
    @media (max-width: 1199px) {
      .testimonials-slider .slide-item p {
        font-size: 18px;
        font-size: 1.2rem; } }
    @media (max-width: 991px) {
      .testimonials-slider .slide-item p {
        font-size: 16px;
        font-size: 1.06667rem; } }
  .testimonials-slider .client-quote {
    margin-bottom: 40px; }
  .testimonials-slider .client-details {
    position: relative;
    padding: 0 0 10px 85px; }
  .testimonials-slider .client-pic {
    position: absolute;
    left: 0;
    min-height: 58px;
    border-bottom: 2px solid #5487c1; }
  .testimonials-slider .client-info {
    padding-top: 5px; }
  .testimonials-slider .client-info h4 {
    font-size: 20px;
    font-size: 1.33333rem;
    color: #5487c1;
    margin: 0 0 0.3em; }
    @media (max-width: 1199px) {
      .testimonials-slider .client-info h4 {
        font-size: 16px;
        font-size: 1.06667rem; } }
  .testimonials-slider .client-info > span {
    font-size: 14px;
    font-size: 0.93333rem;
    color: #dddddd; }
  .testimonials-slider .owl-controls {
    position: absolute;
    right: 0;
    bottom: -45px; }
    @media (max-width: 767px) {
      .testimonials-slider .owl-controls {
        position: static;
        text-align: left;
        margin: 15px 0 0 -5px; } }
    .testimonials-slider .owl-controls .owl-prev,
    .testimonials-slider .owl-controls .owl-next {
      text-align: center; }

/*--------------------------------------------------------------
#0.8    offer
--------------------------------------------------------------*/
@media screen and (min-width: 1200px) {
  .offer-text {
    padding-right: 30px; } }

.offer-text > p:nth-child(2) {
  margin-bottom: 55px; }
  @media (max-width: 991px) {
    .offer-text > p:nth-child(2) {
      margin-bottom: 40px; } }

.offer-grids {
  overflow: hidden;
  margin: 0 -15px; }
  @media (max-width: 991px) {
    .offer-grids {
      margin-top: 80px; } }
  @media (max-width: 767px) {
    .offer-grids {
      margin-top: 60px; } }
  .offer-grids .grid {
    width: 50%;
    float: left;
    padding: 0 15px; }
    @media (max-width: 600px) {
      .offer-grids .grid {
        width: 100%;
        float: left;
        margin-bottom: 30px; } }
  .offer-grids > .grid:nth-child(1),
  .offer-grids > .grid:nth-child(2) {
    margin-bottom: 40px; }
  .offer-grids > .grid:last-child {
    margin-bottom: 0; }
  .offer-grids .details {
    padding-left: 75px;
    position: relative; }
    @media (max-width: 767px) {
      .offer-grids .details {
        padding-left: 55px; } }
  .offer-grids .details > h3 {
    font-size: 20px;
    font-size: 1.33333rem;
    margin: 0 0 1em; }
    @media (max-width: 1199px) {
      .offer-grids .details > h3 {
        font-size: 18px;
        font-size: 1.2rem; } }
    @media (max-width: 767px) {
      .offer-grids .details > h3 {
        font-size: 16px;
        font-size: 1.06667rem; } }
  .offer-grids .details .icon {
    position: absolute;
    left: 0;
    top: -10px; }
    @media (max-width: 1199px) {
      .offer-grids .details .icon {
        left: 8px; } }
    @media (max-width: 991px) {
      .offer-grids .details .icon {
        left: 0; } }
  .offer-grids .details .icon i:before {
    font-size: 45px; }
    @media (max-width: 1199px) {
      .offer-grids .details .icon i:before {
        font-size: 40px; } }
    @media (max-width: 767px) {
      .offer-grids .details .icon i:before {
        font-size: 30px; } }
  .offer-grids .details .offer-details {
    font-weight: 600;
    color: #5487c1;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
    .offer-grids .details .offer-details:hover {
      color: #437ab9; }

.offer-section {
  padding-bottom: 145px; }
  @media (max-width: 991px) {
    .offer-section {
      padding-bottom: 80px; } }

.offer-section_dark {
  background-color: #283138; }
  .offer-section_dark, .offer-section_dark *:not([class]) {
    color: #fff; }

.offer-pic {
  padding: 40px 0 0 45px;
  position: relative; }
  .offer-pic img {
    position: relative;
    z-index: 2;
    border: 2px solid #5487c1; }
    @media (min-width: 768px) {
      .offer-pic img {
        padding: 20px; } }
    @media (min-width: 1200px) {
      .offer-pic img {
        padding: 20px; } }

/*--------------------------------------------------------------
#0.9    recent-projects
--------------------------------------------------------------*/
.recent-projects {
  background-color: #283138; }
  .recent-projects p {
    font-size: 18px; }

@media (max-width: 767px) {
  .recent-projects-grids,
  .projects-grid-view {
    overflow: hidden; } }

.recent-projects-grids .grid,
.projects-grid-view .grid {
  position: relative;
  overflow: hidden;
  display: inline-block;
  float: left; }
  @media (max-width: 767px) {
    .recent-projects-grids .grid,
    .projects-grid-view .grid {
      width: 50%;
      float: left; } }
  @media (max-width: 550px) {
    .recent-projects-grids .grid,
    .projects-grid-view .grid {
      width: 100%;
      float: none; } }

@media (max-width: 550px) {
  .recent-projects-grids .project-img img,
  .projects-grid-view .project-img img {
    width: 100%; } }

.recent-projects-grids .project-info,
.projects-grid-view .project-info {
  background-color: rgba(40, 49, 56, 0.9);
  width: 95%;
  height: 92%;
  border: 2px solid #5487c1;
  position: absolute;
  left: -10%;
  top: 4%;
  opacity: 0;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }

.recent-projects-grids .grid:hover .project-info,
.projects-grid-view .grid:hover .project-info {
  left: 2.5%;
  opacity: 1; }

.recent-projects-grids .inner-info,
.projects-grid-view .inner-info {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 10; }

.recent-projects-grids .inner-info > a,
.projects-grid-view .inner-info > a {
  color: #fff; }

.recent-projects-grids .inner-info h3,
.projects-grid-view .inner-info h3 {
  font-family: "Hind", sans-serif;
  font-size: 22px;
  font-size: 1.46667rem;
  color: #fff;
  margin: 0 0 0.2em;
  position: relative;
  top: -10px;
  opacity: 0;
  -webkit-transition: all 0.3s 0.5s;
  transition: all 0.3s 0.5s; }
  @media (max-width: 991px) {
    .recent-projects-grids .inner-info h3,
    .projects-grid-view .inner-info h3 {
      font-size: 20px;
      font-size: 1.33333rem; } }
  @media (max-width: 767px) {
    .recent-projects-grids .inner-info h3,
    .projects-grid-view .inner-info h3 {
      font-size: 18px;
      font-size: 1.2rem; } }

.recent-projects-grids .inner-info .tags,
.projects-grid-view .inner-info .tags {
  color: #5487c1;
  position: relative;
  top: 10px;
  opacity: 0;
  -webkit-transition: all 0.3s 0.5s;
  transition: all 0.3s 0.5s; }
  @media (max-width: 767px) {
    .recent-projects-grids .inner-info .tags,
    .projects-grid-view .inner-info .tags {
      font-size: 14px;
      font-size: 0.93333rem; } }

.recent-projects-grids .grid:hover .inner-info h3,
.recent-projects-grids .grid:hover .inner-info .tags,
.projects-grid-view .grid:hover .inner-info h3,
.projects-grid-view .grid:hover .inner-info .tags {
  top: 0;
  opacity: 1; }

.recent-projects-grids .mCustomScrollBox,
.projects-grid-view .mCustomScrollBox {
  height: auto; }
  @media (max-width: 550px) {
    .recent-projects-grids .mCustomScrollBox,
    .projects-grid-view .mCustomScrollBox {
      position: static; } }

.recent-projects-grids .mCSB_horizontal.mCSB_inside > .mCSB_container,
.projects-grid-view .mCSB_horizontal.mCSB_inside > .mCSB_container {
  margin-bottom: 80px; }

.recent-projects-grids .mCSB_scrollTools.mCSB_scrollTools_horizontal,
.projects-grid-view .mCSB_scrollTools.mCSB_scrollTools_horizontal {
  max-width: 850px;
  margin: 0 auto; }
  @media (max-width: 991px) {
    .recent-projects-grids .mCSB_scrollTools.mCSB_scrollTools_horizontal,
    .projects-grid-view .mCSB_scrollTools.mCSB_scrollTools_horizontal {
      max-width: 700px; } }

.recent-projects-grids .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.recent-projects-grids .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar,
.projects-grid-view .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.projects-grid-view .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
  height: 10px; }

.recent-projects-grids .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail,
.projects-grid-view .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
  background-color: #3a444c; }

.recent-projects-grids .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar,
.projects-grid-view .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
  background-color: #5487c1; }

@media (max-width: 550px) {
  .recent-projects-grids .mCSB_inside > .mCSB_container,
  .projects-grid-view .mCSB_inside > .mCSB_container {
    margin-right: 0; } }

.projects-grid-view-section .more-projects {
  margin-top: 30px;
  text-align: center; }

.projects-grid-view {
  margin: 0 -15px; }
  .projects-grid-view .grid {
    width: calc(33.33% - 30px);
    margin: 0 15px 30px;
    float: left; }
    @media (max-width: 991px) {
      .projects-grid-view .grid {
        width: calc(50% - 30px); } }
    @media (max-width: 550px) {
      .projects-grid-view .grid {
        width: calc(100% - 30px);
        float: none; } }

/*--------------------------------------------------------------
#1.0    about-us-faq
--------------------------------------------------------------*/
.about-us-faq {
  position: relative; }
  .about-us-faq .backhoe-loader {
    position: absolute;
    right: 0;
    top: 160px; }
    @media (max-width: 1800px) {
      .about-us-faq .backhoe-loader {
        display: none; } }

.about-us-section .btns {
  margin: 50px 0 45px; }
  @media (max-width: 767px) {
    .about-us-section .btns {
      margin: 35px 0; } }

.about-us-section .social > p {
  text-transform: uppercase; }

.about-us-section .social-links a {
  font-size: 32px;
  font-size: 2.13333rem;
  color: #333; }
  @media (max-width: 991px) {
    .about-us-section .social-links a {
      font-size: 26px;
      font-size: 1.73333rem; } }
  @media (max-width: 767px) {
    .about-us-section .social-links a {
      font-size: 22px;
      font-size: 1.46667rem; } }
  .about-us-section .social-links a:hover {
    color: #5487c1; }

.about-us-section .social-links > li + li {
  margin-left: 15px; }
  @media (max-width: 991px) {
    .about-us-section .social-links > li + li {
      margin-left: 10px; } }

.faq-section {
  position: relative;
  z-index: 1; }
  @media (max-width: 1199px) {
    .faq-section {
      margin-top: 50px; } }
  @media (max-width: 767px) {
    .faq-section {
      margin-top: 40px; } }
  .faq-section .faq-accordion {
    padding-top: 7px; }

/*--------------------------------------------------------------
#1.1    partners
--------------------------------------------------------------*/
.partners {
  padding-top: 0; }

.partners-bg {
  background-color: #f6f6f6;
  padding: 45px 0; }

.partners-slider .grid {
  border: 2px solid #e9e9e9;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s; }

.partners-slider .grid:hover {
  border-color: #5487c1; }

/*--------------------------------------------------------------
#1.2    partners
--------------------------------------------------------------*/
.contact-section {
  position: relative; }
  .contact-section:before {
    content: "";
    background-color: rgba(40, 49, 56, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  @media screen and (min-width: 992px) {
    .contact-section .section-title, .contact-section .section-title-white, .contact-section .section-title-s2, .contact-section .section-title-s5, .contact-section .section-title-s6, .contact-section .section-title-s7 {
      margin-bottom: 30px; } }
  .contact-section p {
    color: #aaa;
    line-height: 1.56em; }

.contact-section-contact-box {
  padding: 38px 45px;
  border: 2px solid #5487c1; }
  @media (max-width: 991px) {
    .contact-section-contact-box {
      margin-bottom: 60px; } }
  @media (max-width: 767px) {
    .contact-section-contact-box {
      margin-bottom: 45px; } }
  @media (max-width: 450px) {
    .contact-section-contact-box {
      padding: 38px 25px; } }
  .contact-section-contact-box .details ul {
    list-style-type: none;
    color: #fff; }
  .contact-section-contact-box .details ul li {
    position: relative;
    padding-left: 25px; }
  .contact-section-contact-box .details ul i {
    color: #5487c1;
    position: absolute;
    left: 0;
    top: 3px; }
  .contact-section-contact-box .details ul > li + li {
    margin-top: 20px; }

.contact-form-s1 label {
  font-size: 12px;
  font-size: 0.8rem;
  font-weight: normal;
  display: block;
  color: #5487c1;
  text-transform: uppercase; }
  @media (max-width: 767px) {
    .contact-form-s1 label {
      font-size: 11px;
      font-size: 0.73333rem; } }

.contact-form-s1 input,
.contact-form-s1 select {
  height: 38px;
  border: 0; }
  @media (max-width: 767px) {
    .contact-form-s1 input,
    .contact-form-s1 select {
      height: 34px; } }

.contact-form-s1 form {
  overflow: hidden;
  margin: 0 -12px; }

.contact-form-s1 form > div {
  width: 50%;
  float: left;
  padding: 0 12px;
  margin-top: 12px; }
  @media (max-width: 450px) {
    .contact-form-s1 form > div {
      width: 100%;
      float: none; } }

.contact-form-s1 form .submit-btn-wrap {
  width: 100%;
  float: none;
  clear: both;
  padding-top: 35px; }

.contact-form-s1 .wpcf7-submit {
  background-color: #5487c1;
  color: #fff;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  transition: all 0.3s; }
  .contact-form-s1 .wpcf7-submit:hover {
    background-color: #3f73ad; }

.contact-women {
  position: absolute;
  left: 0;
  bottom: 0; }
  @media (max-width: 1550px) {
    .contact-women {
      display: none; } }

/*--------------------------------------------------------------
#1.3    news-section
--------------------------------------------------------------*/
.news-grids {
  margin: 45px -15px 0;
  padding-top: 20px;
  overflow: hidden; }
  @media (max-width: 991px) {
    .news-grids {
      padding-top: 50px; } }
  .news-grids .grid {
    width: 33.33%;
    float: left;
    padding: 0 15px; }
    @media (max-width: 991px) {
      .news-grids .grid {
        width: 50%; } }
    @media (max-width: 580px) {
      .news-grids .grid {
        width: 100%;
        float: none;
        margin-bottom: 40px; } }
  @media (max-width: 991px) {
    .news-grids > .grid:last-child {
      margin-top: 40px; } }
  @media (max-width: 580px) {
    .news-grids > .grid:last-child {
      margin-bottom: 0; } }
  .news-grids .entry-details {
    border: 1px solid #e8e8e8; }
  .news-grids .entry-meta {
    padding: 20px 30px 0; }
  .news-grids .entry-body {
    padding: 0 30px 33px; }
    .news-grids .entry-body p:last-child {
      margin-bottom: 0; }
  .news-grids .entry-meta ul {
    list-style: none;
    overflow: hidden; }
  .news-grids .entry-meta ul li {
    font-size: 14px;
    font-size: 0.93333rem;
    color: #9a9a9a;
    float: left; }
    @media (max-width: 1199px) {
      .news-grids .entry-meta ul li {
        font-size: 13px;
        font-size: 0.86667rem; } }
    @media (max-width: 767px) {
      .news-grids .entry-meta ul li {
        font-size: 12px;
        font-size: 0.8rem; } }
  .news-grids .entry-meta ul li a {
    color: #9a9a9a; }
  .news-grids .entry-meta ul li i {
    display: inline-block;
    color: #5487c1;
    padding-right: 4px; }
  .news-grids .entry-meta ul > li + li {
    margin-left: 15px; }
  .news-grids .entry-media img {
    border-radius: 5px; }
  .news-grids .entry-body h3 {
    font-family: "Hind", sans-serif;
    font-size: 18px;
    font-size: 1.2rem;
    line-height: 1.3em;
    font-weight: 600;
    margin: 0.72em 0 0.83em; }
    @media (max-width: 1199px) {
      .news-grids .entry-body h3 {
        font-size: 16px;
        font-size: 1.06667rem; } }
    @media (max-width: 767px) {
      .news-grids .entry-body h3 {
        font-size: 14px;
        font-size: 0.93333rem; } }
  .news-grids .entry-body h3 a {
    color: #283138; }
    .news-grids .entry-body h3 a:hover {
      color: #5487c1; }
  .news-grids .entry-body p {
    font-size: 14px;
    font-size: 0.93333rem;
    line-height: 1.57em; }

/*--------------------------------------------------------------
#2.0    cta
--------------------------------------------------------------*/
.cta {
  position: relative;
  text-align: center; }
  .cta:before {
    content: "";
    background-color: rgba(40, 49, 56, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .cta .container {
    position: relative;
    z-index: 1; }
  .cta h2 {
    font-size: 30px;
    font-size: 2rem;
    line-height: 1.3em;
    color: #fff;
    margin-bottom: 1.33em; }
    @media (max-width: 1199px) {
      .cta h2 {
        font-size: 25px;
        font-size: 1.66667rem; } }
    @media (max-width: 991px) {
      .cta h2 {
        font-size: 22px;
        font-size: 1.46667rem; } }
    @media (max-width: 767px) {
      .cta h2 {
        font-size: 18px;
        font-size: 1.2rem; } }
    .cta h2 span {
      color: #5487c1; }

/*--------------------------------------------------------------
#2.1    services s2
--------------------------------------------------------------*/
.service-s2-top {
  margin-top: 10px; }
  @media (max-width: 991px) {
    .service-s2-top {
      margin-top: 50px; } }

.service-s2-tab {
  overflow: hidden;
  margin-bottom: 60px; }
  .service-s2-tab .tablinks {
    width: 17%;
    float: right;
    list-style: none; }
    @media (max-width: 991px) {
      .service-s2-tab .tablinks {
        width: 30%; } }
    @media (max-width: 767px) {
      .service-s2-tab .tablinks {
        width: 270px;
        float: none;
        margin-bottom: 25px; } }
    .service-s2-tab .tablinks li {
      border: 1px solid transparent;
      margin-bottom: 2px; }
    .service-s2-tab .tablinks li.active {
      border: 1px solid #5487c1; }
    .service-s2-tab .tablinks li a {
      font-size: 18px;
      font-size: 1.2rem;
      font-weight: 600;
      color: #283138;
      display: block;
      padding: 5px 20px;
      text-align: right;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      transition: all 0.3s; }
      @media (max-width: 1199px) {
        .service-s2-tab .tablinks li a {
          font-size: 16px;
          font-size: 1.06667rem; } }
    .service-s2-tab .tablinks li.active a span {
      visibility: visible;
      opacity: 1;
      display: inline-block; }
    .service-s2-tab .tablinks li a span {
      padding-left: 8px;
      visibility: hidden;
      display: none;
      opacity: 0;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      transition: all 0.3s; }
  .service-s2-tab .tab-content {
    width: 80%;
    float: left; }
    @media (max-width: 991px) {
      .service-s2-tab .tab-content {
        width: 65%; } }
    @media (max-width: 767px) {
      .service-s2-tab .tab-content {
        width: 100%;
        float: none; } }
  .service-s2-tab .tab-pane {
    overflow: hidden; }
    .service-s2-tab .tab-pane .img-holder {
      width: 45%;
      float: left;
      padding-bottom: 15px; }
      @media (max-width: 991px) {
        .service-s2-tab .tab-pane .img-holder {
          width: 100%;
          float: none; } }
    .service-s2-tab .tab-pane .img-holder img {
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); }
    .service-s2-tab .tab-pane .details {
      width: 55%;
      float: right;
      padding: 45px 100px 45px 45px; }
      @media (max-width: 1199px) {
        .service-s2-tab .tab-pane .details {
          padding: 25px 41px 25px 35px; } }
      @media (max-width: 991px) {
        .service-s2-tab .tab-pane .details {
          width: 100%;
          float: none;
          padding: 25px 0 0; } }
      @media (max-width: 767px) {
        .service-s2-tab .tab-pane .details {
          padding: 15px 0 0; } }
  .service-s2-tab .details h3 {
    font-size: 24px;
    font-size: 1.6rem;
    font-weight: 600;
    margin: 0 0 0.71em; }
    @media (max-width: 1199px) {
      .service-s2-tab .details h3 {
        font-size: 20px;
        font-size: 1.33333rem; } }
    @media (max-width: 767px) {
      .service-s2-tab .details h3 {
        font-size: 18px;
        font-size: 1.2rem; } }
  .service-s2-tab .details .more {
    font-weight: 600;
    color: #5487c1;
    margin-top: 5px; }
    .service-s2-tab .details .more:hover {
      color: #4075b1; }

.service-s2-box-grids {
  margin: 0 -15px; }
  .service-s2-box-grids .grid {
    width: 33.33%;
    float: left;
    padding: 0 15px; }
    @media (max-width: 700px) {
      .service-s2-box-grids .grid {
        width: 100%;
        float: none;
        margin-bottom: 15px; } }
  .service-s2-box-grids .grid .inner {
    border: 2px solid #e6e6e6;
    padding: 65px 42px 60px  100px;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    -ms-transition: all 0.2s;
    transition: all 0.2s; }
    @media (max-width: 1199px) {
      .service-s2-box-grids .grid .inner {
        padding: 95px 42px 40px; } }
    @media (max-width: 991px) {
      .service-s2-box-grids .grid .inner {
        padding: 95px 35px 30px; } }
    @media (max-width: 767px) {
      .service-s2-box-grids .grid .inner {
        padding: 85px 35px 30px; } }
  .service-s2-box-grids .grid:hover .inner {
    border-color: #5487c1; }
  .service-s2-box-grids .inner h3 {
    font-size: 20px;
    font-size: 1.33333rem;
    font-weight: 600;
    margin: 0 0 1em;
    position: relative; }
    @media (max-width: 991px) {
      .service-s2-box-grids .inner h3 {
        font-size: 18px;
        font-size: 1.2rem; } }
    @media (max-width: 767px) {
      .service-s2-box-grids .inner h3 {
        font-size: 16px;
        font-size: 1.06667rem; } }
    .service-s2-box-grids .inner h3 span {
      font-size: 48px;
      font-size: 3.2rem;
      font-weight: bold;
      position: absolute;
      left: -80px;
      top: -45px; }
      @media (max-width: 1199px) {
        .service-s2-box-grids .inner h3 span {
          font-size: 38px;
          font-size: 2.53333rem;
          left: 0;
          top: -60px; } }
      @media (max-width: 991px) {
        .service-s2-box-grids .inner h3 span {
          font-size: 34px;
          font-size: 2.26667rem; } }
      @media (max-width: 767px) {
        .service-s2-box-grids .inner h3 span {
          font-size: 30px;
          font-size: 2rem; } }
    .service-s2-box-grids .inner h3 span:before {
      content: "";
      background-color: #5487c1;
      width: 2px;
      height: 140%;
      position: absolute;
      left: 60%;
      top: -10px;
      -webkit-transform: rotate(40deg);
      transform: rotate(40deg); }
  .service-s2-box-grids .inner > p:last-child {
    margin-bottom: 0; }

/*--------------------------------------------------------------
#2.2    our-team
--------------------------------------------------------------*/
.our-team-bg {
  background-color: #f6f6f6;
  padding-bottom: 170px;
  margin-bottom: 45px; }
  @media (max-width: 1199px) {
    .our-team-bg {
      padding-bottom: 80px; } }
  @media (max-width: 991px) {
    .our-team-bg {
      padding-bottom: 60px; } }

.our-team .team-slider {
  position: relative; }

.our-team .owl-stage-outer {
  z-index: 10; }

.our-team .team-slider:after {
  content: "";
  width: 120%;
  height: 300px;
  border: 2px solid #5487c1;
  position: absolute;
  left: -10%;
  bottom: 0; }
  @media (max-width: 1199px) {
    .our-team .team-slider:after {
      display: none; } }

/*--------------------------------------------------------------
#2.3    fun-fact
--------------------------------------------------------------*/
.fun-fact {
  text-align: center;
  padding: 55px 0 100px; }
  @media (max-width: 1199px) {
    .fun-fact {
      padding: 0 0 100px; } }
  @media (max-width: 991px) {
    .fun-fact {
      padding: 0 0 60px; } }
  @media (max-width: 767px) {
    .fun-fact {
      padding: 0 0 45px; } }
  .fun-fact .start-count > .col:last-child .grid:after {
    display: none; }
  .fun-fact .start-count > .col:last-child .grid {
    border-bottom: 0; }
  @media (max-width: 767px) {
    .fun-fact .start-count > .col + .col .grid {
      margin-top: 20px; } }
  .fun-fact .grid {
    padding: 0 40px;
    position: relative; }
    @media (max-width: 1199px) {
      .fun-fact .grid {
        padding: 0 25px; } }
    @media (max-width: 991px) {
      .fun-fact .grid {
        padding: 0 15px; } }
    @media (max-width: 767px) {
      .fun-fact .grid {
        border-bottom: 1px solid #d9d9d9; } }
    .fun-fact .grid:after {
      content: "";
      background: #d9d9d9;
      background: -webkit-linear-gradient(top, white 0%, #d9d9d9 15%, #d9d9d9 80%, white 98%);
      background: linear-gradient(top, white 0%, #d9d9d9 15%, #d9d9d9 80%, white 98%);
      width: 2px;
      height: 105px;
      position: absolute;
      right: -15px;
      bottom: -2%; }
      @media (max-width: 767px) {
        .fun-fact .grid:after {
          display: none; } }
  .fun-fact .grid h3 {
    font-size: 60px;
    font-size: 4rem;
    margin: 0 0 3px; }
    @media (max-width: 1199px) {
      .fun-fact .grid h3 {
        font-size: 50px;
        font-size: 3.33333rem; } }
    @media (max-width: 991px) {
      .fun-fact .grid h3 {
        font-size: 40px;
        font-size: 2.66667rem; } }
    @media (max-width: 767px) {
      .fun-fact .grid h3 {
        font-size: 30px;
        font-size: 2rem; } }
    .fun-fact .grid h3 > span:last-child {
      color: #5487c1; }
  .fun-fact .grid .fact-title {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: bold;
    color: #9a9a9a;
    margin: 0 0 1.38em;
    display: block; }

/*--------------------------------------------------------------
#2.4    cta-newsletter
--------------------------------------------------------------*/
.cta-newsletter {
  padding: 100px 0;
  position: relative; }
  @media (max-width: 991px) {
    .cta-newsletter {
      padding: 80px 0;
      text-align: center; } }
  @media (max-width: 767px) {
    .cta-newsletter {
      padding: 60px 0; } }
  .cta-newsletter:before {
    content: "";
    background-color: rgba(40, 49, 56, 0.9);
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0; }
  .cta-newsletter .cta-newsletter-inner h3 {
    font-size: 30px;
    font-size: 2rem;
    color: #fff;
    margin: 0 0 1.23em; }
    @media (max-width: 1199px) {
      .cta-newsletter .cta-newsletter-inner h3 {
        font-size: 25px;
        font-size: 1.66667rem; } }
    @media (max-width: 991px) {
      .cta-newsletter .cta-newsletter-inner h3 {
        font-size: 20px;
        font-size: 1.33333rem; } }
    .cta-newsletter .cta-newsletter-inner h3 span {
      color: #5487c1; }
  .cta-newsletter .subscrib-form {
    max-width: 725px;
    position: relative; }
    @media (max-width: 1199px) {
      .cta-newsletter .subscrib-form {
        max-width: 625px; } }
    @media (max-width: 991px) {
      .cta-newsletter .subscrib-form {
        max-width: 500px;
        margin: 0 auto; } }
    @media (max-width: 767px) {
      .cta-newsletter .subscrib-form {
        max-width: 95%;
        margin: 0 auto; } }
    .cta-newsletter .subscrib-form input {
      height: 45px;
      border: 0;
      padding-left: 25px;
      padding-right: 260px; }
      @media (max-width: 1199px) {
        .cta-newsletter .subscrib-form input {
          height: 40px;
          padding-right: 200px; } }
      @media (max-width: 991px) {
        .cta-newsletter .subscrib-form input {
          height: 38px;
          padding-left: 15px;
          padding-right: 140px; } }
      @media (max-width: 767px) {
        .cta-newsletter .subscrib-form input {
          padding-right: 120px; } }
    .cta-newsletter .subscrib-form button {
      background-color: #5487c1;
      width: 240px;
      height: 45px;
      line-height: 45px;
      padding: 0;
      border: 0;
      outline: 0;
      font-size: 16px;
      font-size: 1.06667rem;
      font-weight: 600;
      color: #283138;
      position: absolute;
      right: 0;
      top: 0;
      border-radius: 5px;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      -o-transition: all 0.3s;
      -ms-transition: all 0.3s;
      transition: all 0.3s; }
      @media (max-width: 1199px) {
        .cta-newsletter .subscrib-form button {
          width: 180px;
          height: 40px;
          line-height: 40px; } }
      @media (max-width: 1199px) {
        .cta-newsletter .subscrib-form button {
          font-size: 14px;
          font-size: 0.93333rem;
          width: 120px;
          height: 38px;
          line-height: 38px; } }
      @media (max-width: 767px) {
        .cta-newsletter .subscrib-form button {
          width: 100px; } }
    .cta-newsletter .subscrib-form button:hover {
      background-color: #437ab9; }
  .cta-newsletter .newsletter-pic {
    position: absolute;
    right: 0;
    bottom: -100px; }
    @media (max-width: 991px) {
      .cta-newsletter .newsletter-pic {
        display: none; } }

/*--------------------------------------------------------------
#2.5    testimoials-s2-slider
--------------------------------------------------------------*/
.testimoials-s2-slider .testimoials-s2-grid,
.testimoials-s2-grid-view .testimoials-s2-grid {
  border: 2px solid #5487c1;
  padding: 65px 35px 35px;
  border-radius: 5px;
  position: relative; }
  @media (max-width: 767px) {
    .testimoials-s2-slider .testimoials-s2-grid,
    .testimoials-s2-grid-view .testimoials-s2-grid {
      padding: 45px 20px 20px; } }

.testimoials-s2-slider .testimoials-s2-grid:after,
.testimoials-s2-grid-view .testimoials-s2-grid:after {
  background: #5487c1 url("../images/testimonials/quote.png") no-repeat center center;
  content: "";
  width: 58px;
  height: 52px;
  position: absolute;
  right: 0;
  top: -2px;
  border-radius: 5px; }
  @media (max-width: 991px) {
    .testimoials-s2-slider .testimoials-s2-grid:after,
    .testimoials-s2-grid-view .testimoials-s2-grid:after {
      width: 50px;
      height: 48px;
      background-size: 20px; } }

.testimoials-s2-slider .testimoials-s2-grid img,
.testimoials-s2-grid-view .testimoials-s2-grid img {
  width: auto;
  border-radius: 50%; }

.testimoials-s2-slider .client-info,
.testimoials-s2-grid-view .client-info {
  margin-top: 10px;
  position: relative;
  overflow: hidden; }
  .testimoials-s2-slider .client-info .details,
  .testimoials-s2-grid-view .client-info .details {
    height: 75px;
    display: inline-block;
    float: left;
    position: relative;
    padding-left: 90px; }
    @media (max-width: 991px) {
      .testimoials-s2-slider .client-info .details,
      .testimoials-s2-grid-view .client-info .details {
        float: none;
        display: block; } }
  .testimoials-s2-slider .client-info .details .pic,
  .testimoials-s2-grid-view .client-info .details .pic {
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%; }
  .testimoials-s2-slider .client-info .info,
  .testimoials-s2-grid-view .client-info .info {
    margin-top: 15px; }
  .testimoials-s2-slider .client-info h4,
  .testimoials-s2-grid-view .client-info h4 {
    font-size: 18px;
    font-size: 1.2rem;
    margin: 0; }
    @media (max-width: 991px) {
      .testimoials-s2-slider .client-info h4,
      .testimoials-s2-grid-view .client-info h4 {
        font-size: 16px;
        font-size: 1.06667rem; } }
    @media (max-width: 767px) {
      .testimoials-s2-slider .client-info h4,
      .testimoials-s2-grid-view .client-info h4 {
        font-size: 14px;
        font-size: 0.93333rem; } }
  .testimoials-s2-slider .client-info p,
  .testimoials-s2-grid-view .client-info p {
    font-size: 14px;
    font-size: 0.93333rem;
    text-transform: uppercase; }
    @media (max-width: 767px) {
      .testimoials-s2-slider .client-info p,
      .testimoials-s2-grid-view .client-info p {
        font-size: 12px;
        font-size: 0.8rem; } }
  .testimoials-s2-slider .client-info .rating,
  .testimoials-s2-grid-view .client-info .rating {
    display: inline-block;
    float: right;
    margin-top: 15px; }
    @media (max-width: 991px) {
      .testimoials-s2-slider .client-info .rating,
      .testimoials-s2-grid-view .client-info .rating {
        float: none;
        display: block;
        margin: -20px 0 0 88px; } }
    .testimoials-s2-slider .client-info .rating i,
    .testimoials-s2-grid-view .client-info .rating i {
      color: #5487c1; }

@media screen and (min-width: 992px) {
  .testimoials-s2-slider .owl-controls .owl-nav,
  .testimoials-s2-grid-view .owl-controls .owl-nav {
    width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); } }

.testimoials-s2-slider .owl-controls .owl-nav .owl-prev,
.testimoials-s2-slider .owl-controls .owl-nav .owl-next,
.testimoials-s2-grid-view .owl-controls .owl-nav .owl-prev,
.testimoials-s2-grid-view .owl-controls .owl-nav .owl-next {
  background: transparent;
  font-size: 20px;
  font-size: 1.33333rem;
  color: #283138; }
  @media screen and (min-width: 992px) {
    .testimoials-s2-slider .owl-controls .owl-nav .owl-prev,
    .testimoials-s2-slider .owl-controls .owl-nav .owl-next,
    .testimoials-s2-grid-view .owl-controls .owl-nav .owl-prev,
    .testimoials-s2-grid-view .owl-controls .owl-nav .owl-next {
      font-size: 24px;
      font-size: 1.6rem;
      position: absolute;
      padding: 0;
      margin: 0; } }

@media screen and (min-width: 992px) {
  .testimoials-s2-slider .owl-controls .owl-nav .owl-prev,
  .testimoials-s2-grid-view .owl-controls .owl-nav .owl-prev {
    left: -70px; } }

@media screen and (min-width: 992px) {
  .testimoials-s2-slider .owl-controls .owl-nav .owl-next,
  .testimoials-s2-grid-view .owl-controls .owl-nav .owl-next {
    right: -70px; } }

.testimoials-s2-slider .owl-controls .owl-nav .owl-prev:hover,
.testimoials-s2-slider .owl-controls .owl-nav .owl-next:hover,
.testimoials-s2-grid-view .owl-controls .owl-nav .owl-prev:hover,
.testimoials-s2-grid-view .owl-controls .owl-nav .owl-next:hover {
  background: transparent;
  color: #5487c1; }

.testimonials-s2-grid-view-section {
  padding-bottom: 70px; }
  @media (max-width: 991px) {
    .testimonials-s2-grid-view-section {
      padding-bottom: 50px; } }
  @media (max-width: 767px) {
    .testimonials-s2-grid-view-section {
      padding-bottom: 40px; } }

.testimoials-s2-grid-view {
  overflow: hidden;
  margin: 0 -15px; }
  .testimoials-s2-grid-view .testimoials-s2-grid {
    width: calc(50% - 30px);
    float: left;
    margin: 0 15px 30px; }
    @media (max-width: 650px) {
      .testimoials-s2-grid-view .testimoials-s2-grid {
        width: calc(100% - 30px);
        float: none; } }

/*--------------------------------------------------------------
#3.0    features
--------------------------------------------------------------*/
.features-title {
  border: 2px solid #5487c1;
  padding: 45px 30px;
  border-radius: 5px; }
  @media (max-width: 991px) {
    .features-title {
      margin-bottom: 40px;
      padding: 35px 30px; } }
  @media (max-width: 991px) {
    .features-title {
      padding: 25px 20px; } }
  .features-title h2 {
    font-size: 30px;
    font-size: 2rem;
    margin: 0 0 0.9em; }
    @media (max-width: 1199px) {
      .features-title h2 {
        font-size: 25px;
        font-size: 1.66667rem; } }
    @media (max-width: 767px) {
      .features-title h2 {
        font-size: 20px;
        font-size: 1.33333rem; } }
  .features-title p {
    margin: 0; }

.feature-grid {
  text-align: center; }
  .feature-grid .icon {
    background-color: #5487c1;
    width: 90px;
    height: 90px;
    line-height: 90px;
    text-align: center;
    margin: 0 auto 35px;
    border-radius: 50%; }
    @media (max-width: 1199px) {
      .feature-grid .icon {
        width: 75px;
        height: 75px;
        line-height: 75px; } }
    @media (max-width: 767px) {
      .feature-grid .icon {
        width: 60px;
        height: 60px;
        line-height: 60px;
        margin: 0 auto 25px; } }
  .feature-grid .icon .fi:before {
    font-size: 50px;
    color: #000; }
    @media (max-width: 1199px) {
      .feature-grid .icon .fi:before {
        font-size: 40px; } }
    @media (max-width: 767px) {
      .feature-grid .icon .fi:before {
        font-size: 30px; } }
  .feature-grid h3 {
    font-size: 20px;
    font-size: 1.33333rem;
    margin: 0 0 0.5em; }
    @media (max-width: 1199px) {
      .feature-grid h3 {
        font-size: 17px;
        font-size: 1.13333rem; } }
  .feature-grid p {
    margin-bottom: 1.7em; }
    @media (max-width: 767px) {
      .feature-grid p {
        margin-bottom: 1.4em; } }
  .feature-grid .more {
    color: #5487c1;
    font-weight: 600; }

.features .row > .col:last-child .feature-grid {
  border: 0;
  padding: 0;
  margin: 0; }

.features-grid-s5 {
  display: flex;
  width: 100%;
  align-items: stretch; }
  .features-grid-s5 > * {
    padding: 15px; }
    @media (min-width: 1200px) {
      .features-grid-s5 > * {
        flex: 0 0 20%; } }
  @media (max-width: 991px) {
    .features-grid-s5 {
      flex-wrap: wrap;
      justify-content: center; }
      .features-grid-s5 > * {
        flex: 1 1 240px; } }

/*--------------------------------------------------------------
#3.1    services-s3
--------------------------------------------------------------*/
.services-s3 {
  background-color: #e8f0f7;
  padding-bottom: 70px; }
  @media (max-width: 991px) {
    .services-s3 {
      padding-bottom: 50px; } }
  @media (max-width: 767px) {
    .services-s3 {
      padding-bottom: 40px; } }

.services-s3-grids {
  overflow: hidden;
  margin: 0 -15px; }
  .services-s3-grids .grid {
    width: 33.33%;
    float: left;
    padding: 0 15px 30px;
    position: relative; }
    @media (max-width: 991px) {
      .services-s3-grids .grid {
        width: 50%; } }
    @media (max-width: 600px) {
      .services-s3-grids .grid {
        width: 100%;
        float: none; } }
  .services-s3-grids .grid:hover .details {
    background-color: #437ab9; }
  .services-s3-grids .grid:hover h3 a {
    color: #fff; }
  .services-s3-grids .grid .inner {
    position: relative;
    overflow: hidden; }
  .services-s3-grids .grid .details {
    background-color: #5487c1;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    border-radius: 5px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s; }
  .services-s3-grids .grid img {
    border-radius: 5px; }
  .services-s3-grids .grid h3 {
    font-size: 18px;
    font-size: 1.2rem;
    font-weight: normal;
    margin: 0; }
    @media (max-width: 1199px) {
      .services-s3-grids .grid h3 {
        font-size: 15px;
        font-size: 1rem; } }
  .services-s3-grids .grid h3 a {
    display: block;
    color: #283138;
    padding: 12px 18px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -o-transition: all 0.3s;
    -ms-transition: all 0.3s;
    transition: all 0.3s; }

/*--------------------------------------------------------------
#3.2    pricing
--------------------------------------------------------------*/
.pricing {
  text-align: center; }
  .pricing .pricing-tab {
    list-style-type: none;
    overflow: hidden;
    display: inline-block;
    margin-bottom: 50px;
    border-radius: 5px; }
  .pricing .pricing-tab li {
    float: left; }
  .pricing .pricing-tab li a {
    display: block;
    font-size: 16px;
    font-size: 1.06667rem;
    font-weight: bold;
    color: #5487c1;
    border: 2px solid #5487c1;
    padding: 15px 80px; }
    @media (max-width: 1199px) {
      .pricing .pricing-tab li a {
        font-size: 14px;
        font-size: 0.93333rem;
        border: 2px solid #5487c1;
        padding: 10px 50px; } }
    @media (max-width: 767px) {
      .pricing .pricing-tab li a {
        padding: 5px 30px; } }
  .pricing .pricing-tab li.active a {
    background-color: #5487c1;
    color: #283138; }
  .pricing .pricing-grids {
    overflow: hidden;
    margin: 0 -15px; }
    .pricing .pricing-grids .pricing-grid {
      width: 33.33%;
      float: left;
      text-align: center;
      padding: 0 15px 15px; }
      @media (max-width: 991px) {
        .pricing .pricing-grids .pricing-grid {
          width: 50%; } }
      @media (max-width: 767px) {
        .pricing .pricing-grids .pricing-grid {
          width: 100%;
          float: none; } }
    .pricing .pricing-grids .pricing-details {
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.2);
      border-radius: 0 0 5px 5px; }
    .pricing .pricing-grids .pricing-header {
      background-color: #283138;
      padding: 40px 0;
      border-radius: 5px 5px 0 0;
      position: relative;
      overflow: hidden; }
      @media (max-width: 1199px) {
        .pricing .pricing-grids .pricing-header {
          padding: 30px 0; } }
      .pricing .pricing-grids .pricing-header h3 {
        font-size: 24px;
        font-size: 1.6rem;
        color: #5487c1;
        margin: 0; }
        @media (max-width: 1199px) {
          .pricing .pricing-grids .pricing-header h3 {
            font-size: 20px;
            font-size: 1.33333rem; } }
        @media (max-width: 991px) {
          .pricing .pricing-grids .pricing-header h3 {
            font-size: 17px;
            font-size: 1.13333rem; } }
      .pricing .pricing-grids .pricing-header .price {
        font-family: "Montserrat", sans-serif;
        font-size: 48px;
        font-size: 3.2rem;
        font-weight: bold;
        color: #fff;
        position: relative; }
        @media (max-width: 1199px) {
          .pricing .pricing-grids .pricing-header .price {
            font-size: 40px;
            font-size: 2.66667rem; } }
        @media (max-width: 991px) {
          .pricing .pricing-grids .pricing-header .price {
            font-size: 35px;
            font-size: 2.33333rem; } }
      .pricing .pricing-grids .pricing-header .price span {
        font-size: 28px;
        font-size: 1.86667rem;
        position: relative;
        top: -13px; }
        @media (max-width: 991px) {
          .pricing .pricing-grids .pricing-header .price span {
            font-size: 18px;
            font-size: 1.2rem; } }
      .pricing .pricing-grids .pricing-header .sing-up {
        background-color: #5487c1;
        width: 165px;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
        font-size: 1.06667rem;
        font-weight: 600;
        color: #283138;
        display: inline-block;
        border-radius: 5px; }
        @media (max-width: 1199px) {
          .pricing .pricing-grids .pricing-header .sing-up {
            width: 125px;
            height: 38px;
            line-height: 38px;
            font-size: 14px;
            font-size: 0.93333rem; } }
        @media (max-width: 991px) {
          .pricing .pricing-grids .pricing-header .sing-up {
            width: 110px;
            height: 35px;
            line-height: 35px; } }
      .pricing .pricing-grids .pricing-header .off {
        background-color: #fd3e03;
        color: #fff;
        display: block;
        padding: 0 25px;
        position: absolute;
        right: -32px;
        top: -24px;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        -webkit-transform-origin: left bottom;
        transform-origin: left bottom; }
    .pricing .pricing-grids .pricing-body {
      padding: 40px 15px; }
      .pricing .pricing-grids .pricing-body ul {
        list-style-type: none; }
      .pricing .pricing-grids .pricing-body ul li {
        color: #6a6a6a; }
      .pricing .pricing-grids .pricing-body ul li i {
        color: #5487c1; }
      .pricing .pricing-grids .pricing-body ul > li + li {
        margin-top: 17px; }
        @media (max-width: 991px) {
          .pricing .pricing-grids .pricing-body ul > li + li {
            margin-top: 14px; } }
    .pricing .pricing-grids .pricing-footer {
      border-top: 1px solid #d9d9d9;
      padding: 19px 0; }
      @media (max-width: 1199px) {
        .pricing .pricing-grids .pricing-footer {
          padding: 14px 0; } }
      .pricing .pricing-grids .pricing-footer a {
        font-size: 15px;
        font-size: 1rem;
        color: #283138; }
        .pricing .pricing-grids .pricing-footer a span {
          font-size: 18px;
          font-size: 1.2rem;
          font-weight: 600; }
      .pricing .pricing-grids .pricing-footer a:hover {
        color: #5487c1; }

/**** products grids ***/
.products-grids .grid {
  background-color: #fff;
  text-align: center; }

.products-grids .img-holder img {
  display: inline-block; }

.products-grids .img-holder-info-list {
  position: relative; }

.products-grids .info-list {
  width: 152px;
  position: absolute;
  left: 50%;
  top: 60%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s; }
  @media (max-width: 767px) {
    .products-grids .info-list {
      width: 122px; } }
  .products-grids .info-list > div {
    width: 50px;
    float: left;
    margin-right: 1px; }
    @media (max-width: 767px) {
      .products-grids .info-list > div {
        width: 40px; } }
  .products-grids .info-list > div:last-child {
    margin-right: 0; }
  .products-grids .info-list > div a {
    background-color: #283138;
    height: 45px;
    display: block;
    text-align: center;
    padding: 10px 0; }
    @media (max-width: 767px) {
      .products-grids .info-list > div a {
        height: 40px;
        line-height: 40px;
        padding: 0; } }
  .products-grids .info-list > div a:hover {
    background-color: #1d2429; }
  .products-grids .info-list > div img {
    display: inline-block; }
    @media (max-width: 767px) {
      .products-grids .info-list > div img {
        max-width: 17px; } }

/*--------------------------------------------------------------
#6.0	service-single-section
--------------------------------------------------------------*/
.project-single-section .project-single-details {
  border: 2px solid #5487c1;
  padding: 60px 50px;
  border-radius: 5px; }
  @media (max-width: 1199px) {
    .project-single-section .project-single-details {
      padding: 40px 30px; } }
  @media (max-width: 991px) {
    .project-single-section .project-single-details {
      margin-top: 50px; } }
  @media (max-width: 767px) {
    .project-single-section .project-single-details {
      padding: 30px 25px; } }

.project-single-section .project-single-details h3 {
  font-size: 25px;
  font-size: 1.66667rem;
  margin: 0 0 0.75em; }
  @media (max-width: 991px) {
    .project-single-section .project-single-details h3 {
      font-size: 20px;
      font-size: 1.33333rem; } }

.project-single-section .project-info {
  list-style-type: none;
  padding-top: 40px; }
  @media (max-width: 991px) {
    .project-single-section .project-info {
      padding-top: 20px; } }
  .project-single-section .project-info li {
    font-size: 15px;
    font-size: 1rem; }
    @media (max-width: 767px) {
      .project-single-section .project-info li {
        font-size: 14px;
        font-size: 0.93333rem; } }
    .project-single-section .project-info li span {
      color: #283138;
      font-weight: 600; }
  .project-single-section .project-info > li + li {
    margin-top: 13px; }
  .project-single-section .project-info i {
    color: #5487c1;
    display: inline-block;
    padding-right: 5px; }

/*--------------------------------------------------------------
#5.0	service-single-section
--------------------------------------------------------------*/
.service-single-content .title {
  position: relative;
  margin-top: 52px; }
  @media screen and (min-width: 1200px) {
    .service-single-content .title {
      padding-right: 340px; } }
  @media (max-width: 767px) {
    .service-single-content .title {
      margin-top: 40px; } }

.service-single-content .title h3 {
  font-size: 25px;
  font-size: 1.66667rem;
  margin: 0 0 2em; }
  @media (max-width: 991px) {
    .service-single-content .title h3 {
      font-size: 20px;
      font-size: 1.33333rem; } }
  @media (max-width: 767px) {
    .service-single-content .title h3 {
      font-size: 17px;
      font-size: 1.13333rem; } }

.service-single-content .download {
  position: absolute;
  right: 0;
  top: 0; }
  @media (max-width: 1199px) {
    .service-single-content .download {
      position: static;
      margin-bottom: 40px; } }

.service-single-content .download a {
  padding: 10px 20px 10px 40px;
  border: 2px solid #5487c1;
  color: #6a6a6a;
  font-weight: 600;
  position: relative; }
  @media (max-width: 1199px) {
    .service-single-content .download a {
      padding: 7px 15px 7px 30px; } }
  @media (max-width: 991px) {
    .service-single-content .download a {
      font-size: 14px;
      font-size: 0.93333rem;
      padding: 5px 15px 5px 30px; } }

.service-single-content .download a i {
  position: absolute;
  left: 20px; }
  @media (max-width: 1199px) {
    .service-single-content .download a i {
      left: 10px; } }

.service-single-content .details ul {
  list-style-type: none;
  padding: 2em 0 3em; }

.service-single-content .details ul li {
  position: relative;
  padding-left: 75px;
  font-weight: 600; }
  @media (max-width: 1199px) {
    .service-single-content .details ul li {
      padding-left: 45px; } }

.service-single-content .details ul > li + li {
  margin-top: 45px; }
  @media (max-width: 1199px) {
    .service-single-content .details ul > li + li {
      margin-top: 20px; } }

.service-single-content .details ul li i {
  background-color: #5487c1;
  width: 45px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); }
  @media (max-width: 1199px) {
    .service-single-content .details ul li i {
      width: 30px;
      height: 30px;
      line-height: 30px;
      font-size: 12px; } }

.service-single-content .details p {
  margin-bottom: 2em; }

@media (max-width: 991px) {
  .service-single-content .details h4 {
    font-size: 16px;
    font-size: 1.06667rem; } }

/*--------------------------------------------------------------
	Shop details page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#12.0	products-section
--------------------------------------------------------------*/
.shop-details-main-content {
  /*** product slider ***/
  /*** product info ***/
  /*** upsell product **/ }
  .shop-details-main-content .shop-single-slider-wrapper .slider-for {
    text-align: center; }
    .shop-details-main-content .shop-single-slider-wrapper .slider-for img {
      display: inline-block; }
  .shop-details-main-content .shop-single-slider-wrapper .slider-nav {
    padding: 0 25px;
    margin-top: 35px; }
  .shop-details-main-content .shop-single-slider-wrapper .slider-nav > i {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 100; }
  .shop-details-main-content .shop-single-slider-wrapper .slider-nav > i:hover {
    cursor: pointer; }
  .shop-details-main-content .shop-single-slider-wrapper .slider-nav .nav-btn-rt {
    left: auto;
    right: 0; }
  .shop-details-main-content .shop-single-slider-wrapper .slider-nav .slick-slide {
    text-align: center; }
    .shop-details-main-content .shop-single-slider-wrapper .slider-nav .slick-slide img {
      display: inline-block; }
  .shop-details-main-content .product-details {
    padding: 30px 30px 93px;
    /*** product option ***/ }
    @media (max-width: 1199px) {
      .shop-details-main-content .product-details {
        padding: 40px 30px 85px; } }
    @media (max-width: 991px) {
      .shop-details-main-content .product-details {
        margin-top: 45px;
        padding: 40px 30px; } }
    @media (max-width: 767px) {
      .shop-details-main-content .product-details {
        padding: 0; } }
    .shop-details-main-content .product-details h2 {
      font-size: 27px;
      font-size: 1.8rem;
      line-height: 1.3em;
      margin: 0 0 0.33em;
      text-transform: capitalize; }
      @media (max-width: 767px) {
        .shop-details-main-content .product-details h2 {
          font-size: 22px;
          font-size: 1.46667rem; } }
    .shop-details-main-content .product-details .rating i {
      font-size: 16px;
      font-size: 1.06667rem;
      color: #5487c1; }
    .shop-details-main-content .product-details .price {
      font-size: 36px;
      font-size: 2.4rem;
      color: #283138;
      margin: 7px 0 14px; }
      @media (max-width: 991px) {
        .shop-details-main-content .product-details .price {
          font-size: 30px;
          font-size: 2rem; } }
      @media (max-width: 767px) {
        .shop-details-main-content .product-details .price {
          font-size: 25px;
          font-size: 1.66667rem; } }
      .shop-details-main-content .product-details .price .old {
        font-size: 24px;
        font-size: 1.6rem;
        font-weight: normal;
        color: #9d9d9d;
        text-decoration: line-through;
        display: inline-block;
        margin-left: 5px; }
        @media (max-width: 991px) {
          .shop-details-main-content .product-details .price .old {
            font-size: 20px;
            font-size: 1.33333rem; } }
        @media (max-width: 767px) {
          .shop-details-main-content .product-details .price .old {
            font-size: 18px;
            font-size: 1.2rem; } }
    .shop-details-main-content .product-details p {
      margin: 0; }
    .shop-details-main-content .product-details .product-option {
      margin-top: 45px; }
    .shop-details-main-content .product-details .product-option .p-row {
      overflow: hidden; }
    .shop-details-main-content .product-details .product-option .p-row > div {
      height: 35px;
      display: inline-block;
      float: left;
      margin-right: 15px; }
    .shop-details-main-content .product-details .product-option .p-row > div:first-child {
      width: 85px; }
    .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn:hover, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn_light:hover, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn-s2:hover, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn-s3:hover, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn-s4:hover, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn-s5:hover {
      background: #283138;
      border-color: #283138;
      color: #fff; }
    .shop-details-main-content .product-details .product-option .theme-btn, .shop-details-main-content .product-details .product-option .theme-btn_light, .shop-details-main-content .product-details .product-option .theme-btn-s2, .shop-details-main-content .product-details .product-option .theme-btn-s3, .shop-details-main-content .product-details .product-option .theme-btn-s4, .shop-details-main-content .product-details .product-option .theme-btn-s5 {
      font-size: 16px;
      font-size: 1.06667rem;
      padding: 0 10px;
      height: 35px;
      line-height: 35px; }
      @media (max-width: 767px) {
        .shop-details-main-content .product-details .product-option .theme-btn, .shop-details-main-content .product-details .product-option .theme-btn_light, .shop-details-main-content .product-details .product-option .theme-btn-s2, .shop-details-main-content .product-details .product-option .theme-btn-s3, .shop-details-main-content .product-details .product-option .theme-btn-s4, .shop-details-main-content .product-details .product-option .theme-btn-s5 {
          font-size: 14px;
          font-size: 0.93333rem; } }
      .shop-details-main-content .product-details .product-option .theme-btn:before, .shop-details-main-content .product-details .product-option .theme-btn_light:before, .shop-details-main-content .product-details .product-option .theme-btn-s2:before, .shop-details-main-content .product-details .product-option .theme-btn-s3:before, .shop-details-main-content .product-details .product-option .theme-btn-s4:before, .shop-details-main-content .product-details .product-option .theme-btn-s5:before {
        display: none; }
    .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn_light, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn-s2, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn-s3, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn-s4, .shop-details-main-content .product-details .product-option .p-row > div:last-child .theme-btn-s5 {
      background-color: #fff;
      color: #283138;
      border: 1px solid #e6e6e6; }
    .shop-details-main-content .product-details #count-product {
      border-radius: 0;
      border: 1px solid #e6e6e6; }
    .shop-details-main-content .product-details #count-product:focus {
      box-shadow: none;
      outline: none; }
    .shop-details-main-content .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up,
    .shop-details-main-content .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
      border-radius: 0;
      border-color: #e6e6e6; }
      .shop-details-main-content .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up:hover,
      .shop-details-main-content .product-details .bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down:hover {
        background-color: #283138;
        color: #fff; }
  .shop-details-main-content .product-info {
    margin-top: 75px;
    /*** tabs ***/
    /*** client review ***/
    /*** review form ***/ }
    .shop-details-main-content .product-info h4 {
      font-size: 15px;
      text-transform: uppercase;
      margin: 0;
      line-height: 1.7em; }
    .shop-details-main-content .product-info p {
      font-size: 14px; }
    .shop-details-main-content .product-info .tab-pane p:last-child {
      margin-bottom: 0; }
    .shop-details-main-content .product-info .nav-tabs {
      border: 0;
      margin-bottom: 30px; }
      @media (max-width: 767px) {
        .shop-details-main-content .product-info .nav-tabs {
          margin-bottom: 20px; } }
    .shop-details-main-content .product-info .nav-tabs li {
      margin-right: 45px;
      border: 0; }
      .shop-details-main-content .product-info .nav-tabs li:last-child {
        margin-right: 0; }
      @media (max-width: 767px) {
        .shop-details-main-content .product-info .nav-tabs li {
          margin-right: 15px; } }
    .shop-details-main-content .product-info .nav-tabs li.active a {
      border: 0;
      outline: 0; }
    .shop-details-main-content .product-info .nav-tabs a {
      font-size: 18px;
      color: #b3b3b3;
      border: 0;
      margin: 0;
      padding: 0;
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .shop-details-main-content .product-info .nav-tabs a {
          font-size: 16px;
          font-size: 1.06667rem; } }
    .shop-details-main-content .product-info .nav-tabs a:hover,
    .shop-details-main-content .product-info .nav-tabs .active a {
      background: transparent;
      color: #283138; }
    .shop-details-main-content .product-info .client-review {
      overflow: hidden;
      margin-bottom: 30px; }
      .shop-details-main-content .product-info .client-review:last-child {
        margin-bottom: 0; }
      .shop-details-main-content .product-info .client-review .client-pic {
        width: 14%;
        float: left; }
        @media (max-width: 767px) {
          .shop-details-main-content .product-info .client-review .client-pic {
            width: 100%;
            float: none;
            margin-bottom: 10px; } }
      .shop-details-main-content .product-info .client-review .details {
        width: 86%;
        float: right; }
        @media (max-width: 767px) {
          .shop-details-main-content .product-info .client-review .details {
            width: 100%;
            float: none; } }
      .shop-details-main-content .product-info .client-review .name-rating-time {
        border-bottom: 1px solid #e6e6e6;
        margin-top: -5px; }
        @media (max-width: 991px) {
          .shop-details-main-content .product-info .client-review .name-rating-time {
            margin-top: 0; } }
        @media (max-width: 767px) {
          .shop-details-main-content .product-info .client-review .name-rating-time {
            padding-bottom: 3px; } }
      .shop-details-main-content .product-info .client-review .name-rating-time > div,
      .shop-details-main-content .product-info .client-review .name-rating > div {
        display: inline-block;
        font-size: 14px; }
        @media (max-width: 767px) {
          .shop-details-main-content .product-info .client-review .name-rating-time > div,
          .shop-details-main-content .product-info .client-review .name-rating > div {
            font-size: 12px;
            display: block; }
            .shop-details-main-content .product-info .client-review .name-rating-time > div h4,
            .shop-details-main-content .product-info .client-review .name-rating > div h4 {
              font-size: 12px; } }
      .shop-details-main-content .product-info .client-review .rating {
        font-size: 14px;
        padding-left: 10px; }
        @media (max-width: 767px) {
          .shop-details-main-content .product-info .client-review .rating {
            padding-left: 0;
            margin: 2px 0 7px; } }
      .shop-details-main-content .product-info .client-review .name-rating-time .time {
        float: right;
        color: #b3b3b3;
        text-transform: uppercase; }
        @media (max-width: 767px) {
          .shop-details-main-content .product-info .client-review .name-rating-time .time {
            float: none; } }
      .shop-details-main-content .product-info .client-review .review-body {
        padding-top: 8px; }
        .shop-details-main-content .product-info .client-review .review-body p {
          font-size: 14px; }
    @media screen and (min-width: 1200px) {
      .shop-details-main-content .product-info .review-form-wrapper {
        padding-left: 45px; } }
    @media (max-width: 991px) {
      .shop-details-main-content .product-info .review-form {
        margin-top: 45px; } }
    .shop-details-main-content .product-info .review-form h4 {
      margin-bottom: 1.73em; }
    .shop-details-main-content .product-info .review-form form input,
    .shop-details-main-content .product-info .review-form form textarea {
      border-radius: 0; }
    .shop-details-main-content .product-info .review-form form input:focus,
    .shop-details-main-content .product-info .review-form form textarea:focus {
      box-shadow: none;
      outline: none; }
    .shop-details-main-content .product-info .review-form form textarea {
      height: 130px; }
    .shop-details-main-content .product-info .review-form form > div {
      margin-bottom: 27px; }
    .shop-details-main-content .product-info .review-form form > div:last-child {
      margin-bottom: 0; }
    .shop-details-main-content .product-info .review-form form .rating-post > div {
      display: inline-block; }
      @media (max-width: 767px) {
        .shop-details-main-content .product-info .review-form form .rating-post > div {
          display: block;
          float: none !important; } }
    .shop-details-main-content .product-info .review-form form .rating-post > div:last-child {
      float: right; }
      @media (max-width: 767px) {
        .shop-details-main-content .product-info .review-form form .rating-post > div:last-child {
          margin-top: 20px; } }
    .shop-details-main-content .product-info .review-form form .rating a {
      font-size: 14px;
      color: #cccccc;
      display: inline-block;
      margin-right: 10px; }
      @media (max-width: 767px) {
        .shop-details-main-content .product-info .review-form form .rating a {
          font-size: 12px;
          margin-right: 5px; } }
    .shop-details-main-content .product-info .review-form form .rating a:last-child {
      margin: 0; }
    .shop-details-main-content .product-info .review-form form .rating a:hover {
      color: #283138; }
    .shop-details-main-content .product-info .review-form form .theme-btn, .shop-details-main-content .product-info .review-form form .theme-btn_light, .shop-details-main-content .product-info .review-form form .theme-btn-s2, .shop-details-main-content .product-info .review-form form .theme-btn-s3, .shop-details-main-content .product-info .review-form form .theme-btn-s4, .shop-details-main-content .product-info .review-form form .theme-btn-s5 {
      border-radius: 0;
      box-shadow: none; }
      @media screen and (min-width: 767px) {
        .shop-details-main-content .product-info .review-form form .theme-btn, .shop-details-main-content .product-info .review-form form .theme-btn_light, .shop-details-main-content .product-info .review-form form .theme-btn-s2, .shop-details-main-content .product-info .review-form form .theme-btn-s3, .shop-details-main-content .product-info .review-form form .theme-btn-s4, .shop-details-main-content .product-info .review-form form .theme-btn-s5 {
          font-size: 15px;
          font-size: 1rem;
          padding: 0 25px; } }
  .shop-details-main-content .upsell-product {
    padding-top: 140px; }
    @media (max-width: 991px) {
      .shop-details-main-content .upsell-product {
        padding-top: 90px; } }
    @media (max-width: 767px) {
      .shop-details-main-content .upsell-product {
        padding-top: 80px; } }
    .shop-details-main-content .upsell-product .title {
      margin-bottom: 50px; }
      @media (max-width: 767px) {
        .shop-details-main-content .upsell-product .title {
          margin-bottom: 20px; } }
    .shop-details-main-content .upsell-product .title h2 {
      font-size: 36px;
      margin: 0;
      text-transform: capitalize;
      text-align: center; }
      @media (max-width: 767px) {
        .shop-details-main-content .upsell-product .title h2 {
          font-size: 25px; } }
      .shop-details-main-content .upsell-product .title h2 span {
        color: #283138; }
  .shop-details-main-content .upsell-product-slider .grid {
    width: auto !important; }
  .shop-details-main-content .upsell-product-slider .owl-controls {
    margin: 0; }
  .shop-details-main-content .upsell-product-slider .owl-dots span {
    background-color: #fff;
    width: 12px;
    height: 12px;
    border: 2px solid #000;
    margin: 0 5px 0 0; }
  .shop-details-main-content .upsell-product-slider .owl-dots .active span {
    background-color: #283138;
    width: 14px;
    height: 14px;
    border-color: #283138; }

/*--------------------------------------------------------------
	shop with sidebar page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#11.0	shop-main-content
--------------------------------------------------------------*/
.shop-with-sidebar .products-grids {
  overflow: hidden; }

.shop-with-sidebar .products-grids .grid-wrapper {
  width: 33.33%;
  float: left;
  padding: 0 7.5px;
  margin-bottom: 20px; }
  @media (max-width: 1199px) {
    .shop-with-sidebar .products-grids .grid-wrapper {
      width: 50%; } }
  @media (max-width: 500px) {
    .shop-with-sidebar .products-grids .grid-wrapper {
      width: 100%; } }

.shop-with-sidebar .products-grids .grid {
  box-shadow: none;
  margin: 3px 0; }

/*--------------------------------------------------------------
	shop grid page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#10.0	products-section
--------------------------------------------------------------*/
.shop .products-grids > .col {
  margin-bottom: 30px; }
  @media (max-width: 500px) {
    .shop .products-grids > .col {
      width: 100%; } }

.blogInner__img {
  width: 100% !important; }

.blogInner__gal {
  display: flex;
  flex-wrap: wrap; }

.blogInner__galImgWrap:not(:last-child) {
  margin-right: 50px;
  margin-bottom: 30px; }

.entry-media img.b-blog__img {
  border-radius: 5px 5px 0 0 !important; }

.b-blog__img-list {
  border-radius: none !important;
  width: 200px;
  margin-right: 40px; }
  @media (max-width: 767px) {
    .b-blog__img-list {
      width: 100%;
      margin-right: 0; } }

.b-blogList,
.b-blogList ul {
  list-style: none; }

.b-blogList__item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 70px;
  position: relative;
  /*
    &::after {
        content: "";
        display: block;

        width: 30%;
        height: 2px;

        position: absolute;
        bottom: -20px;
        left: 5px;

        border-radius: none !important;

        background: #5487c1;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }*/ }
  @media (max-width: 767px) {
    .b-blogList__item {
      flex-direction: column; } }

.b-blogList__imgWrap {
  flex: 0 0 auto; }
  @media (max-width: 767px) {
    .b-blogList__imgWrap {
      margin-bottom: 20px; } }

.b-blogList__heading {
  font-size: 2rem;
  color: #283128;
  transition: .3s; }
  .b-blogList__heading:hover {
    color: #3b463b; }

.b-blogList__link {
  color: inherit; }

.b-blogList__text {
  padding-left: 5px; }

.b-blogList__icon {
  padding-right: 5px;
  color: #5487c1; }

@media (miin-width: 992px) {
  .b-blog .blogLogo,
  .b-contact .blogLogo {
    margin: -10px auto auto; } }

.b-blog .topbar,
.b-contact .topbar {
  margin-top: -10px; }

@media (miin-width: 992px) {
  .b-blog .sticky-header a {
    margin-top: 10px !important; } }

.b-contact__link {
  color: #283138;
  text-decoration: none; }

.contact-pg-section .contact-info .icon i {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

.b-down__list {
  margin-top: 30px; }

.b-down__linkList {
  margin-right: 30px;
  display: flex;
  flex-direction: column; }

.b-down__item {
  display: flex;
  flex-direction: column;
  margin-bottom: 50px; }
  .b-down__item:last-child {
    margin-bottom: 0; }

@media (max-width: 767px) {
  .b-down__desc {
    flex-direction: column; } }

.b-down__links {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 115px;
  margin-top: 5px;
  margin-right: 30px; }
  @media (max-width: 767px) {
    .b-down__links {
      margin-bottom: 25px;
      margin-right: 0; } }

.b-down__icon {
  color: #5487c1;
  margin-left: 8px; }

.b-down__link {
  width: 60px;
  height: 60px;
  margin: 0 0 20px 0;
  font-size: 0;
  background-repeat: no-repeat;
  background-size: 100% auto;
  transition: .3s; }
  .b-down__link-pdf {
    background-image: url("../images/teee/down-icon-pdf.svg"); }
  .b-down__link-doc {
    background-image: url("../images/teee/down-icon-doc.svg"); }
  .b-down__link-xls {
    background-image: url("../images/teee/down-icon-xls.svg"); }
  .b-down__link:hover {
    opacity: .7; }

.b-down__textLink {
  text-decoration: none;
  color: #283138;
  font: inherit;
  font-size: 1.3rem;
  transition: .3s; }
  .b-down__textLink:hover {
    opacity: .7; }

.b-down__desc {
  display: flex; }

img,
a {
  min-height: auto; }

.b-heading {
  margin-bottom: 3rem;
  padding-bottom: 20px;
  position: relative;
  font-size: 2rem;
  color: #283128; }
  .b-heading::after {
    content: "";
    display: block;
    width: 33px;
    height: 5px;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #5487c1;
    border-radius: 3px; }
  .b-heading-form {
    text-transform: none; }
  .b-heading-main {
    margin-bottom: 40px;
    padding-bottom: 0;
    text-align: center; }
    .b-heading-main::after {
      display: none; }
  .b-heading-qual {
    text-transform: none; }
  .b-heading-slider {
    text-align: center;
    color: #fff;
    text-transform: none; }
    .b-heading-slider::after {
      left: 50%;
      transform: translateX(-50%); }
  .b-heading-team::after {
    display: none !important; }
  .b-heading-range::before {
    display: none; }
  @media (max-width: 767px) {
    .b-heading {
      font-size: 20px; } }
  @media (min-width: 1600px) {
    .b-heading {
      font-size: 30px; } }

.b-features {
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500; }
  @media (max-width: 767px) {
    .b-features {
      font-size: 14px; } }
  @media (min-width: 1600px) {
    .b-features {
      font-size: 24px; } }

.b-features__row {
  display: flex;
  justify-content: space-between; }
  .b-features__row:nth-last-child(2n-1) {
    background-color: #f6f6f6; }
  .b-features__row-reverse > *:nth-child(1) {
    order: 1; }
  .b-features__row-reverse > *:nth-child(2) {
    order: -1; }
  @media (max-width: 991px) {
    .b-features__row {
      flex-direction: column; } }

.b-features__col {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 50%; }
  .b-features__col-text {
    padding: 85px 50px 85px 75px; }
    @media (min-width: 1600px) {
      .b-features__col-text > p {
        max-width: 800px; } }
  .b-features__col-img {
    min-height: 400px;
    object-fit: cover;
    background-color: #5487c1; }
  .b-features__col-1 {
    background-image: url("../images/teee/z-index-1.jpg");
    background-position: center;
    background-size: cover; }
  .b-features__col-2 {
    background-image: url("../images/teee/z-index-2.jpg");
    background-position: center;
    background-size: cover; }
  .b-features__col-3 {
    background-image: url("../images/teee/z-index-3.jpg");
    background-position: center;
    background-size: cover; }
  .b-features__col-4 {
    background-image: url("../images/teee/z-index-4.jpg");
    background-position: center;
    background-size: cover; }
  .b-features__col-5 {
    background-image: url("../images/teee/z-index-5.jpg");
    background-position: center;
    background-size: cover; }
  @media (max-width: 991px) {
    .b-features__col {
      width: 100%; }
      .b-features__col-text {
        width: 750px;
        margin: 0 auto;
        padding: 90px 0 65px;
        order: -1; }
        .b-features__col-text + .b-features__col-img {
          order: 1; } }
  @media (max-width: 859px) {
    .b-features__col-text {
      width: 600px; } }
  @media (max-width: 767px) {
    .b-features__col-text {
      width: 100%;
      padding: 75px 20px 60px; }
    .b-features__col-img {
      min-height: 300px; } }

.b-features__heading {
  margin-bottom: 18px;
  padding-bottom: 20px;
  position: relative;
  font-size: 2rem;
  color: #283128; }
  .b-features__heading::after {
    content: "";
    display: block;
    width: 33px;
    height: 5px;
    position: absolute;
    bottom: 0px;
    left: 0;
    background-color: #5487c1;
    border-radius: 3px; }
  .b-features__heading-form {
    text-transform: none; }
  @media (max-width: 767px) {
    .b-features__heading {
      font-size: 20px; } }
  @media (min-width: 1600px) {
    .b-features__heading {
      font-size: 30px; } }

.b-features__col-text .learn-more-btn {
  font-size: 18px;
  font-weight: bold;
  color: #5487c1;
  margin-top: 15px;
  padding-left: 5px; }

.b-features__text {
  margin: 14px 0 0;
  padding-right: 30px;
  padding-left: 5px;
  font-size: 18px;
  color: #424242; }
  @media (max-width: 1199px) {
    .b-features__text {
      margin: 14px 0 0;
      padding: 0; } }

.b-features__link {
  max-height: 140px;
  overflow: hidden; }

.b-features__figureWrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 30px; }
  @media (min-width: 1374px) {
    .b-features__figureWrap {
      flex-wrap: nowrap; } }
  @media (max-width: 1199px) {
    .b-features__figureWrap {
      margin-left: 0; } }

.b-features__figure {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  width: 100%; }
  @media (min-width: 426px) {
    .b-features__figure {
      width: 48%;
      margin-right: 15px; } }
  @media (min-width: 1368px) {
    .b-features__figure {
      width: 23%; } }
  @media (min-width: 426px) and (max-width: 1368px) {
    .b-features__figure:nth-child(2) {
      padding-right: 0; } }
  .b-features__figure:last-child {
    margin-right: 0;
    padding-right: 0; }
  .b-features__figure img {
    max-height: 140px;
    object-fit: cover; }

.b-features__imgHead {
  margin-top: 16px;
  padding: 5px;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  color: #595959; }
  @media (max-width: 767px) {
    .b-features__imgHead {
      margin-top: 0;
      margin-bottom: 15px; } }

.b-features__img {
  width: 100%;
  height: auto; }

.b-features__item {
  margin: 7px 0;
  font-size: 1.5em;
  color: #5487c1;
  vertical-align: bottom; }
  @media (max-width: 767px) {
    .b-features__item {
      margin: 5px 0 !important; }
      .b-features__item:last-child {
        margin-bottom: 0 !important; } }

.b-features__itemText {
  font-size: .7em;
  color: #6a6a6a;
  vertical-align: middle; }

.b-features__listWrap {
  display: flex;
  width: 100%;
  margin-top: 25px; }
  .b-features__listWrap > * {
    width: 48%; }
  @media (max-width: 1199px) {
    .b-features__listWrap {
      flex-direction: column; }
      .b-features__listWrap > * {
        width: 100%;
        min-height: 115px; } }
  @media (max-width: 767px) {
    .b-features__listWrap {
      margin-top: 0; } }

.b-features__listIcon {
  background: url("../images/teee/list-icon.png") no-repeat;
  background-position: center;
  background-size: contain; }
  @media (max-width: 1199px) {
    .b-features__listIcon {
      display: none;
      background-position: left top; } }

.b-features__list {
  list-style: none;
  margin-top: 15px; }
  @media (max-width: 1199px) {
    .b-features__list {
      margin-left: 0; } }

.b-features__item {
  line-height: 1.8em;
  position: relative;
  padding-left: 30px;
  margin-bottom: 10px; }
  .b-features__item:before {
    position: absolute;
    content: "\2022";
    font-weight: bold;
    color: #5487c1;
    display: inline-block;
    font-size: 35px;
    left: 0;
    top: 2px; }
  .b-features__item:last-child {
    margin-bottom: 0; }

.navigation {
  z-index: 9999; }

.lb-disable-scrolling {
  position: absolute !important; }

.lb-data .lb-close {
  margin-bottom: 5px; }

.wpcf7-response-output {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 30px;
  margin: 0 auto; }

.wpcf7-mail-sent-ok {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  width: 80%;
  background-color: #398f14;
  color: white;
  margin: 0 auto; }

.wpcf7-validation-errors {
  display: flex;
  justify-content: center;
  border-radius: 3px;
  width: 80%;
  background-color: #f7e700;
  color: black;
  margin: 0px auto; }

.b-formWrap {
  padding-bottom: 50px !important;
  list-style: none; }
  .b-formWrap ul, .b-formWrap li a {
    font-size: 15px;
    color: #6a6a6a;
    margin-bottom: .9375rem; }
  .b-formWrap p {
    font-size: 18px; }
  .b-formWrap .careers-page h2 {
    margin-top: 40px;
    position: relative;
    padding-bottom: 1.25rem; }
    .b-formWrap .careers-page h2:before {
      content: "";
      display: block;
      width: 33px;
      height: 5px;
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #5487c1;
      border-radius: 3px; }
  .b-formWrap .careers-page h2:first-child {
    margin-top: 0; }
  .b-formWrap .careers-page ul, .b-formWrap .careers-page li {
    font-size: 15px;
    color: #6a6a6a;
    margin-bottom: .9375rem; }
  .b-formWrap .careers-page .wpcf7 {
    margin-top: 50px; }

.b-form {
  margin-top: 20px !important;
  font-size: 1rem; }
  .b-form .b-form__input,
  .b-form .b-form__area {
    border-radius: 10px;
    font-size: 1em;
    line-height: 1.1; }
  .b-form .b-form__area {
    resize: none;
    overflow: auto; }

.b-form__label {
  color: #5b6a75; }

.b-form__area + .b-form__label {
  top: 10px;
  transform: none; }

.b-form__inputWrap {
  margin-bottom: 20px !important; }

.b-formWrap__text {
  margin-bottom: 0;
  font-size: 18px; }

@media (min-width: 768px) {
  a.site-logo {
    top: -70px; } }

@media (max-width: 767px) {
  a.site-logo {
    margin: 2px 0 0 !important; }
    a.site-logo img {
      height: 60px !important; } }

@media (max-width: 991px) {
  a.site-logo {
    margin: 3px 0 0 !important; } }

.social-list {
  padding-right: 14px; }

.b-index h2,
.b-index h1,
.b-index h3 {
  text-transform: none; }

.certification {
  background-image: url("../images/teee/certif-section.jpg") !important;
  background-position: 50% -58.9333px;
  background-size: cover; }
  @media (max-width: 425px) {
    .certification {
      padding: 0; } }

.index__vidLink {
  padding-top: 5%; }

.index__imgWrap {
  padding: 0; }
  .index__imgWrap::after {
    top: -20px; }

.factorySlider__overLink {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 11;
  color: #fff;
  transition: .3s;
  display: flex;
  justify-content: center;
  align-items: center; }
  .factorySlider__overLink .factorySlider__heading {
    color: #fff;
    padding: 15px; }

.factorySlider__item:hover .factorySlider__overLink {
  opacity: 1; }

.factorySlider__imgWrap {
  height: 360px; }
  .factorySlider__imgWrap img {
    height: 100%; }

.index__text {
  padding-bottom: 30px;
  font-size: 18px; }
  .index__text-team {
    color: #fff;
    font-size: 18px; }

.b-heading-aboutUs {
  text-align: center; }
  .b-heading-aboutUs::after {
    left: 50%;
    transform: translateX(-50%); }

.b-index__aboutUsText {
  font-size: 1.3rem;
  text-align: center;
  color: #283128; }

.index__iconList {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px; }

.index__iconList h3 {
  font-size: 1.1rem;
  color: #5487c1; }

.index__iconItem {
  margin-bottom: 35px;
  padding-bottom: 35px;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 33%; }
  .index__iconItem .details {
    width: 170px;
    margin: 0 auto; }
  @media (min-width: 1200px) {
    .index__iconItem {
      max-width: 185px !important; } }
  @media (min-width: 230px) and (max-width: 429px) {
    .index__iconItem:last-child {
      border-bottom: none; } }
  @media (min-width: 1200px) {
    .index__iconItem:nth-child(-n+7) {
      border-bottom: none; } }

.b-index__lineText {
  font-weight: 700;
  text-decoration: underline; }

.index__iconItem .index__icon {
  margin-bottom: 20px; }

.feature-grid .icon {
  background-color: #fff; }

.index__icon {
  position: relative; }
  .index__icon::after {
    content: "";
    display: block;
    width: 80px;
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-size: 100%; }
  .index__icon-1::after {
    background-image: url("../images/teee/industry.svg"); }
  .index__icon-2::after {
    background-image: url("../images/teee/brain.svg"); }
  .index__icon-3::after {
    background-image: url("../images/teee/cargo-ship.svg"); }
  .index__icon-4::after {
    background-image: url("../images/teee/tower.svg"); }
  .index__icon-5::after {
    background-image: url("../images/teee/ladder.svg"); }
  .index__icon-6::after {
    background-image: url("../images/teee/support.svg"); }

.owl-carousel .owl-item img {
  object-fit: cover; }

@media (min-width: 574px) {
  .index-our-team .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; } }

@media (min-width: 574px) {
  .index-our-team .col-md-7, .index-our-team .col-md-5 {
    width: 49%; } }

.index-our-team .theme-btn_light.read-more {
  padding: 6px 20px;
  min-height: auto; }

.index-our-team .read-more {
  display: none; }
  @media (min-width: 574px) {
    .index-our-team .read-more {
      display: inline-block; } }

.index-our-team .offer-more-btn-pos {
  display: inline-block;
  padding-left: 15px;
  margin-top: 20px; }
  .index-our-team .offer-more-btn-pos a {
    display: inline-block; }
  @media (min-width: 574px) {
    .index-our-team .offer-more-btn-pos {
      display: none; } }

.test-hover {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  background-color: rgba(255, 96, 32, 0.9);
  transition: all .3s; }
  .test-hover .factorySlider__heading {
    color: #fff;
    padding: 15px;
    margin: 0;
    text-align: center; }

.no-touchevents .test-hover {
  opacity: 0;
  top: 0;
  background-color: transparent; }

.touchevents .square-hover-effect {
  display: none; }

.square-hover-effect-parent:hover .test-hover {
  opacity: 1; }

.square-hover-effect-parent:before {
  text-align: center;
  position: absolute;
  width: 100%;
  left: 0;
  bottom: 0;
  background-color: rgba(255, 96, 32, 0.9);
  z-index: 99;
  padding: 11px 15px;
  font-size: 19px; }

.square-hover-effect-parent.square-hover-effect-active:before {
  content: none; }

.close-navbar {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center; }

.contact-2-item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 35px; }
  @media (min-width: 768px) {
    .contact-2-item {
      margin-bottom: 0;
      justify-content: center; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .contact-2-item:nth-child(3) {
      margin-top: 15px; } }
  .contact-2-item .name-image {
    position: relative;
    background-position: center;
    background-size: cover;
    margin-right: 15px; }
    .contact-2-item .name-image img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      object-fit: cover; }
  .contact-2-item .name {
    font-size: 18px;
    font-weight: bold; }
  .contact-2-item a {
    color: #000;
    font-size: 17px; }
    .contact-2-item a:hover {
      text-decoration: underline; }
  .contact-2-item .country {
    font-size: 20px;
    width: 100%;
    margin-bottom: 13px;
    border-bottom: 1px solid #c4c4c4; }
  .contact-2-item .phone {
    width: 100%; }
  .contact-2-item .email {
    width: 100%; }
  .contact-2-item .address {
    width: 100%;
    color: #000;
    font-size: 1.0625rem;
    margin: 0;
    line-height: 20px;
    margin-bottom: 5px; }
  .contact-2-item .ctn-1 {
    display: flex;
    flex-wrap: wrap;
    text-align: left; }
  .contact-2-item .item-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between; }

.fc-sec {
  margin-top: 90px; }

input.input-upload-resume {
  display: none; }

.upload-file-1-inner {
  border: 2px dashed #d1d7e0;
  border-radius: 10px;
  text-align: center;
  padding: 30px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .upload-file-1-inner .upload-btn {
    margin: 0;
    display: inline-block;
    padding: 10px;
    cursor: pointer;
    border-radius: 5px; }
    .upload-file-1-inner .upload-btn:hover {
      background: #ddd; }

.upload-file-1 #drop-area.highlight {
  border-color: #5487c1; }

.b-index.home .hero-slider .theme-btn:hover, .b-index.home .hero-slider .theme-btn_light:hover, .b-index.home .hero-slider .theme-btn-s2:hover, .b-index.home .hero-slider .theme-btn-s3:hover, .b-index.home .hero-slider .theme-btn-s4:hover, .b-index.home .hero-slider .theme-btn-s5:hover {
  background-color: #fff;
  color: #5487c1;
  border-color: #5487c1; }

.b-index.home .offer-section.offer-section_dark.index-our-team {
  padding-bottom: 6.25rem !important; }

.b-index .blog-intro__text {
  font-size: 18px; }

.b-index.news .news-grids {
  margin-top: 45px; }

.b-blog .blog-single .post-body a {
  color: #5487c1; }

.b-blog .blog-single .post-body img {
  border: none !important;
  margin: 0 !important; }

.b-blog .post-body ul {
  list-style: none; }

.b-blog .post-body li {
  line-height: 1.8em;
  position: relative;
  padding-left: 50px; }
  .b-blog .post-body li:before {
    position: absolute;
    content: "\2022";
    font-weight: bold;
    color: #5487c1;
    display: inline-block;
    font-size: 35px;
    left: 15px;
    top: 2px; }

.b-index .get-quote-popup-block .b-blogList__text .b-blogList__heading, .b-blog .get-quote-popup-block .b-blogList__text .b-blogList__heading {
  font-size: 1.125rem; }

.b-index .get-quote-popup-block .b-down__textLink, .b-blog .get-quote-popup-block .b-down__textLink {
  font-size: 1rem;
  font-weight: 700; }

.b-index .modal-title, .b-blog .modal-title {
  width: 100%;
  margin-bottom: 1.975rem; }
  .b-index .modal-title h3, .b-blog .modal-title h3 {
    position: relative;
    text-align: center; }
    .b-index .modal-title h3:after, .b-blog .modal-title h3:after {
      content: "";
      display: block;
      width: 33px;
      height: 5px;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      background-color: #5487c1;
      border-radius: 3px;
      margin: 0 auto; }

.b-index.downloads .page-description {
  font-size: 18px; }

.b-index.downloads .b-blogList {
  margin-top: 3rem; }

.b-index.downloads .b-blogList__text .b-blogList__heading {
  font-size: 18px; }

.b-index.downloads .b-down__textLink {
  font-size: 1rem;
  font-weight: bold; }

.b-index.downloads .blog-grid-section {
  background-color: #dedcdc; }
  .b-index.downloads .blog-grid-section .container {
    display: flex;
    flex-wrap: wrap; }
    .b-index.downloads .blog-grid-section .container .b-heading {
      width: 100%; }
    .b-index.downloads .blog-grid-section .container .page-description {
      width: 100%; }
      @media (min-width: 992px) {
        .b-index.downloads .blog-grid-section .container .page-description {
          width: 50%;
          padding-right: 60px; } }
    .b-index.downloads .blog-grid-section .container .b-blogList {
      width: 100%;
      z-index: 10; }
      @media (min-width: 992px) {
        .b-index.downloads .blog-grid-section .container .b-blogList {
          background-color: rgba(255, 255, 255, 0.84);
          width: 50%;
          margin-top: -300px;
          padding: 33px; } }

.b-index .slide-caption p {
  padding-right: 6.875rem;
  font-size: 1.6rem;
  line-height: 1.5em;
  color: #fff;
  animation: fadeInLeftSlow 1.5s 1.4s forwards; }

.b-index .slide-caption span {
  color: #5487c1; }

.b-index.products .b-text .text {
  font-size: 18px; }
  .b-index.products .b-text .text .b-heading.text-center.pagenotfound__header {
    margin-top: 3rem; }

.b-index.products .b-text.section-padding_top .b-features__list {
  position: relative;
  padding: 24px 0;
  margin-top: 30px; }
  .b-index.products .b-text.section-padding_top .b-features__list:before {
    content: '';
    position: absolute;
    left: -100%;
    right: -100%;
    top: 0;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(40, 49, 56, 0.9), rgba(40, 49, 56, 0.4));
    z-index: -1; }
  .b-index.products .b-text.section-padding_top .b-features__list .b-features__item .b-features__itemText {
    color: #fff; }
    .b-index.products .b-text.section-padding_top .b-features__list .b-features__item .b-features__itemText strong {
      color: #5487c1; }
    .b-index.products .b-text.section-padding_top .b-features__list .b-features__item .b-features__itemText span {
      color: #000;
      font-weight: bold; }

.b-index.products .b-products.section-padding_top .row {
  display: flex;
  flex-wrap: wrap; }

.b-index.products .b-products.section-padding_top .product {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 1.9rem; }
  @media (min-width: 580px) {
    .b-index.products .b-products.section-padding_top .product {
      width: 49.9%; } }
  @media (min-width: 991px) {
    .b-index.products .b-products.section-padding_top .product {
      width: 33.3%; } }

.b-index.products .product-image {
  height: 155px; }

.b-index.contact .contact-intro__text {
  font-size: 18px; }
  .b-index.contact .contact-intro__text a {
    color: #5487c1; }

.b-index.contact .contact-pg-section .contact-info ul {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 -15px 30px;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .b-index.contact .contact-pg-section .contact-info ul {
      flex-wrap: nowrap; } }

.b-index.contact .contact-pg-section .contact-info li {
  margin-top: 0 !important;
  margin: 0 15px;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  padding-left: 0; }
  @media (min-width: 768px) {
    .b-index.contact .contact-pg-section .contact-info li {
      width: 33.33333333%;
      margin-bottom: 0; } }
  .b-index.contact .contact-pg-section .contact-info li:last-child {
    margin-bottom: 0; }

.b-index.careers .page-description p {
  font-size: 18px; }

.b-index.careers .page-description h2 {
  margin: 40px 0 30px; }

.b-index.careers .page-description li a {
  font-size: 18px;
  color: #5487c1; }
  .b-index.careers .page-description li a:hover {
    color: #5487c1; }

.b-index .wpcf7 {
  margin-top: 60px; }

.b-index.our.team .team-grids .team-grid, .b-index.our-team .team-grids .team-grid {
  max-width: 230px; }

.b-index.our.team .section-padding.offer-section, .b-index.our.team .offer-section.section-padding_top, .b-index.our-team .section-padding.offer-section, .b-index.our-team .offer-section.section-padding_top {
  padding-bottom: 6.25rem; }
  .b-index.our.team .section-padding.offer-section .offer-text, .b-index.our.team .offer-section.section-padding_top .offer-text, .b-index.our-team .section-padding.offer-section .offer-text, .b-index.our-team .offer-section.section-padding_top .offer-text {
    display: flex;
    flex-wrap: wrap;
    width: 100%; }
    .b-index.our.team .section-padding.offer-section .offer-text p, .b-index.our.team .offer-section.section-padding_top .offer-text p, .b-index.our-team .section-padding.offer-section .offer-text p, .b-index.our-team .offer-section.section-padding_top .offer-text p {
      margin-bottom: .9375rem !important; }
    @media (min-width: 992px) {
      .b-index.our.team .section-padding.offer-section .offer-text, .b-index.our.team .offer-section.section-padding_top .offer-text, .b-index.our-team .section-padding.offer-section .offer-text, .b-index.our-team .offer-section.section-padding_top .offer-text {
        width: auto;
        display: block; } }
    .b-index.our.team .section-padding.offer-section .offer-text .offer-pic.ourTeam__imgWrap, .b-index.our.team .offer-section.section-padding_top .offer-text .offer-pic.ourTeam__imgWrap, .b-index.our-team .section-padding.offer-section .offer-text .offer-pic.ourTeam__imgWrap, .b-index.our-team .offer-section.section-padding_top .offer-text .offer-pic.ourTeam__imgWrap {
      order: 2; }
      @media (min-width: 992px) {
        .b-index.our.team .section-padding.offer-section .offer-text .offer-pic.ourTeam__imgWrap, .b-index.our.team .offer-section.section-padding_top .offer-text .offer-pic.ourTeam__imgWrap, .b-index.our-team .section-padding.offer-section .offer-text .offer-pic.ourTeam__imgWrap, .b-index.our-team .offer-section.section-padding_top .offer-text .offer-pic.ourTeam__imgWrap {
          width: 597px;
          float: right;
          padding-left: 15px !important;
          padding-bottom: 5px !important;
          margin-top: -75px; } }

.b-index.contact .contact-pg-section.section-padding.b-formWrap .col.col-xs-12 .container, .b-index.contact .contact-pg-section.b-formWrap.section-padding_top .col.col-xs-12 .container {
  width: auto; }

.ch-st1 {
  display: inline-block;
  width: 100%; }
  .ch-st1 .ch-st1-wrap {
    display: inline-block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .ch-st1 .ch-st1-wrap input {
    position: absolute;
    cursor: pointer;
    margin: 0;
    margin-right: 15px; }
  .ch-st1 .first {
    display: flex; }
  .ch-st1 .wpcf7-list-item {
    position: relative;
    padding-left: 30px;
    display: flex;
    margin: 0; }
    .ch-st1 .wpcf7-list-item input[type='checkbox'] {
      display: none; }
    .ch-st1 .wpcf7-list-item .wpcf7-list-item-label {
      margin-left: 10px;
      font-size: 16px;
      margin-top: 3px; }
      .ch-st1 .wpcf7-list-item .wpcf7-list-item-label:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 11;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        background-color: #fff;
        border: 2px solid #5487c1; }
    .ch-st1 .wpcf7-list-item input[type='checkbox']:checked ~ .wpcf7-list-item-label:before {
      content: '';
      position: absolute;
      left: 9px;
      top: 5px;
      width: 7px;
      height: 11px;
      border: solid #333;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      z-index: 12; }
  .ch-st1 .wpcf7-list-item label {
    display: flex; }
  .ch-st1 .wpcf7-list-item a {
    color: #5487c1; }
  .ch-st1 .post-body a {
    color: #5487c1; }

.b-index .b-heading, .b-index .section-title-s2 h2, .b-index .section-title-s5 h2, .b-index .section-title-s6 h2, .b-index .section-title-s7 h2, .b-index .section-title-s5 h2, .b-index .section-title-s6 h2, .b-index .section-title-s7 h2, .b-index .section-title-s6 h2, .b-index .section-title-s7 h2, .b-index .section-title-s7 h2, .b-index .section-title-white h2, .b-index .section-title h2, .b-index .section-title-white h2, .b-index .section-title-s2 h2, .b-index .section-title-s5 h2, .b-index .section-title-s6 h2, .b-index .section-title-s7 h2 {
  font-family: 'GoodTimesRg-Regular';
  font-style: italic;
  font-size: 2rem; }
  @media (min-width: 425px) {
    .b-index .b-heading, .b-index .section-title-s2 h2, .b-index .section-title-s5 h2, .b-index .section-title-s6 h2, .b-index .section-title-s7 h2, .b-index .section-title-s5 h2, .b-index .section-title-s6 h2, .b-index .section-title-s7 h2, .b-index .section-title-s6 h2, .b-index .section-title-s7 h2, .b-index .section-title-s7 h2, .b-index .section-title-white h2, .b-index .section-title h2, .b-index .section-title-white h2, .b-index .section-title-s2 h2, .b-index .section-title-s5 h2, .b-index .section-title-s6 h2, .b-index .section-title-s7 h2 {
      font-size: 2.25rem; } }

.b-index.home section.features.section-padding, .b-index.home section.features.section-padding_top {
  padding-bottom: 0; }

@media (max-width: 991px) {
  .b-index.privacy-policy .blog-single-content {
    margin-bottom: 0 !important; } }

.b-index.home .features.section-padding.features-st1, .b-index.home .features.features-st1.section-padding_top {
  background-color: #283138;
  margin-top: 6.25rem;
  padding-bottom: 0; }

.b-index.home .index__iconItem .icon {
  background-color: transparent !important; }

@media (min-width: 992px) {
  .b-index.quality .hero .slide .row {
    display: inline-block;
    margin-left: 15px;
    margin-top: 180px; } }

.b-index.quality .b-quality .b-quality__row.b-quality__row-main:first-child {
  background-color: #fff; }
  @media (min-width: 992px) {
    .b-index.quality .b-quality .b-quality__row.b-quality__row-main:first-child {
      margin-top: -200px;
      background-color: #283138; } }
  .b-index.quality .b-quality .b-quality__row.b-quality__row-main:first-child .b-quality__col-text {
    background-color: white; }
    @media (min-width: 992px) {
      .b-index.quality .b-quality .b-quality__row.b-quality__row-main:first-child .b-quality__col-text {
        padding-left: 3.75rem;
        background-color: rgba(255, 255, 255, 0.84);
        transform: translateY(-83px); } }
  .b-index.quality .b-quality .b-quality__row.b-quality__row-main:first-child .b-quality__col.b-quality__col-image {
    width: 45%; }

@media (min-width: 992px) {
  .b-index.quality .b-quality .b-quality__row.b-quality__row-main:nth-child(2) {
    margin-top: -90px; } }

@media (min-width: 992px) {
  .b-index.quality .b-quality .b-quality__row.b-quality__row-main:nth-child(2) .b-quality__col-text {
    transform: translateY(-60px); } }

@media (min-width: 992px) {
  .b-index.quality .b-quality .b-quality__row.b-quality__row-main:nth-child(2) .b-quality__col-image {
    bottom: -40px;
    top: auto; } }

.b-index.quality .features.section-padding, .b-index.quality .features.section-padding_top {
  background-color: #283138; }

.b-index.quality .index__iconItem .icon {
  background-color: transparent !important; }

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
  margin: 0 auto !important; }

.owl-nav .owl-prev.disabled,
.owl-nav .owl-next.disabled {
  display: none !important; }

.b-index .post1__image-container, .b-blog .post1__image-container {
  width: 120px;
  height: 120px; }
  .b-index .post1__image-container.compat-object-fit, .b-blog .post1__image-container.compat-object-fit {
    background-size: cover;
    background-position: center center; }
    .b-index .post1__image-container.compat-object-fit .post__featured-image, .b-blog .post1__image-container.compat-object-fit .post__featured-image {
      opacity: 0; }

.b-index .post1__featured-image, .b-blog .post1__featured-image {
  width: 120px;
  height: 120px;
  object-fit: cover; }

@media screen and (-webkit-min-device-pixel-ratio: 0) and (max-width: 375px) {
  .b-index select,
  .b-index textarea,
  .b-index input,
  .b-index select:focus,
  .b-index textarea:focus,
  .b-index input:focus, .b-blog select,
  .b-blog textarea,
  .b-blog input,
  .b-blog select:focus,
  .b-blog textarea:focus,
  .b-blog input:focus {
    font-size: 16px !important; } }

.b-index input, .b-index textarea, .b-index select, .b-blog input, .b-blog textarea, .b-blog select {
  -webkit-appearance: none; }

.b-index .wpcf7-list-item-label, .b-index .b-form__label.required, .b-blog .wpcf7-list-item-label, .b-blog .b-form__label.required {
  display: inline-block; }

.b-index span.required-smb, .b-blog span.required-smb {
  position: relative; }
  .b-index span.required-smb::after, .b-blog span.required-smb::after {
    content: "\f069";
    margin-left: .4375rem;
    font-family: FontAwesome;
    font-size: .6rem;
    color: #e2574c;
    right: 0px;
    top: -6px;
    position: relative; }

.b-index .b-form__label.required:after, .b-blog .b-form__label.required:after {
  display: none; }

.b-index .ch-st1-wrap.required:after, .b-blog .ch-st1-wrap.required:after {
  display: none; }

.b-index .quote-btn-1, .b-blog .quote-btn-1 {
  position: fixed;
  top: 0;
  bottom: 0;
  margin: auto;
  right: 20px;
  width: 51px;
  height: 220px;
  background-size: cover;
  display: none;
  cursor: pointer;
  background-image: url(../images/quote-btn1.svg);
  z-index: 9999;
  opacity: .8;
  transition: opacity .4s; }
  .b-index .quote-btn-1:hover, .b-blog .quote-btn-1:hover {
    opacity: 1; }
  @media (min-width: 768px) {
    .b-index .quote-btn-1, .b-blog .quote-btn-1 {
      display: block; } }

.b-index .get-quote-popup-block, .b-blog .get-quote-popup-block {
  width: 80%;
  max-width: 670px;
  margin: auto;
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  background-color: #ffffff;
  padding: 30px;
  border-radius: 3px;
  padding-bottom: 0; }
  .b-index .get-quote-popup-block .b-blogList.b-down__list, .b-blog .get-quote-popup-block .b-blogList.b-down__list {
    margin-top: 0; }
    .b-index .get-quote-popup-block .b-blogList.b-down__list .b-down__item:last-child, .b-blog .get-quote-popup-block .b-blogList.b-down__list .b-down__item:last-child {
      margin-bottom: 0; }
    .b-index .get-quote-popup-block .b-blogList.b-down__list .b-down__item:nth-child(2), .b-blog .get-quote-popup-block .b-blogList.b-down__list .b-down__item:nth-child(2) {
      margin-bottom: 0; }
    .b-index .get-quote-popup-block .b-blogList.b-down__list .b-blogList__text p:last-child, .b-blog .get-quote-popup-block .b-blogList.b-down__list .b-blogList__text p:last-child {
      margin-bottom: 0; }

.b-index .popup-modal-dismiss, .b-blog .popup-modal-dismiss {
  position: absolute;
  top: 5px;
  right: 10px;
  cursor: pointer;
  color: #333; }

.b-index .mfp-wrap.mfp-ready, .b-blog .mfp-wrap.mfp-ready {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  height: 150%;
  background-color: rgba(0, 0, 0, 0.2) !important; }

.b-index .mfp-hide, .b-blog .mfp-hide {
  display: none; }

.b-index .post-body img[width], .b-blog .post-body img[width] {
  width: auto; }

.b-index .section-padding-2, .b-blog .section-padding-2 {
  padding: 2.5em 0; }

.b-index .product-more, .b-blog .product-more {
  padding-bottom: 0;
  display: flex;
  align-items: center;
  margin-top: -35px;
  margin-left: 0;
  padding-left: 18px; }
  .b-index .product-more .read-more, .b-blog .product-more .read-more {
    display: flex;
    align-items: center;
    margin: 0 auto; }
    @media (min-width: 990px) {
      .b-index .product-more .read-more, .b-blog .product-more .read-more {
        margin: 0; } }
  @media (max-width: 990px) {
    .b-index .product-more, .b-blog .product-more {
      margin-top: 0;
      margin-left: 0;
      padding: 6.25rem 0; } }

.b-index .empty-prod, .b-blog .empty-prod {
  display: none; }
  @media (min-width: 990px) {
    .b-index .empty-prod, .b-blog .empty-prod {
      display: block; } }

.b-index .orange-box-2, .b-blog .orange-box-2 {
  background-color: #5487c1; }
  @media (min-width: 990px) {
    .b-index .orange-box-2, .b-blog .orange-box-2 {
      margin-top: 30px; } }
  .b-index .orange-box-2 .text p, .b-blog .orange-box-2 .text p {
    margin-bottom: 0;
    color: #fff; }

.b-index .mt-1-0, .b-blog .mt-1-0 {
  margin-top: 0 !important; }

.b-index .homecontent {
  background-color: #283138;
  padding: 70px 0; }
  .b-index .homecontent p {
    margin-top: 0;
    color: #fff; }
    .b-index .homecontent p:last-child {
      margin-bottom: 0; }

.b-index .our-name {
  padding: 80px 0; }
  .b-index .our-name .our-name__row {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
  .b-index .our-name .our-name__col:first-child {
    width: calc(40% - 60px); }
    @media (max-width: 991px) {
      .b-index .our-name .our-name__col:first-child {
        width: calc(40% - 40px); } }
    @media (max-width: 575px) {
      .b-index .our-name .our-name__col:first-child {
        width: 100%; } }
  .b-index .our-name .our-name__col:last-child {
    width: calc(60% - 60px); }
    @media (max-width: 991px) {
      .b-index .our-name .our-name__col:last-child {
        width: calc(60% - 40px); } }
    @media (max-width: 575px) {
      .b-index .our-name .our-name__col:last-child {
        width: 100%; } }
  .b-index .our-name .our-name__text p {
    margin-top: 0; }
    .b-index .our-name .our-name__text p:last-child {
      margin-bottom: 0; }
  @media (max-width: 575px) {
    .b-index .our-name .our-name__image img {
      margin-bottom: 60px; } }

.ourTeam__galSection {
  margin-bottom: 0;
  padding-bottom: 40px; }
  @media (max-width: 349px) {
    .ourTeam__galSection .container {
      padding-left: 0;
      padding-right: 0; } }

.ourTeam__galwrap .member-pic {
  width: 299px;
  height: 300px;
  background-size: cover;
  margin: 0 auto;
  max-width: 230px; }

.ourTeam__gal {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; }
  @media (min-width: 991px) {
    .ourTeam__gal {
      justify-content: space-between; } }

.ourTeam__item {
  margin-bottom: 30px;
  width: 100%;
  max-width: 100% !important; }
  @media (min-width: 540px) {
    .ourTeam__item {
      width: 50%;
      max-width: 50% !important; } }
  @media (min-width: 750px) {
    .ourTeam__item {
      width: 32.3%;
      margin-right: 15px; }
      .ourTeam__item:last-child {
        margin-right: 0; } }

.b-ourTeam__slider {
  height: 280px !important; }

.ourTeam__imgWrap {
  padding: 0 !important; }
  @media (min-width: 768px) {
    .ourTeam__imgWrap {
      padding: 11px 0 0 10%; } }

@media (max-width: 479px) {
  .ourTeam__text {
    padding: 0 10px; } }

.b-qualContacts {
  background-color: #f2f2f2; }

.b-qualitySlider__text {
  font-size: 39px !important;
  font-family: Montserrat,sans-serif;
  font-weight: 700; }

.b-quality__inner {
  width: 1140px;
  margin: 0 auto;
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative; }
  .b-quality__inner-head {
    display: block; }
  .b-quality__inner-slider {
    padding: 60px 0 80px;
    display: block; }
  .b-quality__inner-contacts {
    padding: 40px 0;
    align-items: stretch; }
  @media (max-width: 1199px) {
    .b-quality__inner {
      width: 940px; } }
  @media (max-width: 991px) {
    .b-quality__inner {
      width: 100%;
      padding: 40px 20px;
      flex-direction: column; } }

.b-quality__text {
  margin: 14px 0 0;
  color: #6a6a6a;
  font-size: 18px; }
  @media (max-width: 1199px) {
    .b-quality__text {
      padding: 0; } }

.b-quality__row {
  width: 100%; }
  .b-quality__row-main .b-quality__inner {
    flex-wrap: wrap; }
  .b-quality__row-main:nth-child(3n+1) {
    background-color: #fff; }
  .b-quality__row-main:nth-child(3n+2) {
    background-color: #283138; }
    .b-quality__row-main:nth-child(3n+2) .b-heading, .b-quality__row-main:nth-child(3n+2) .b-quality__text, .b-quality__row-main:nth-child(3n+2) .b-quality__text p {
      color: #ffffff; }
  .b-quality__row-main:nth-child(3n+3) {
    background-color: #5487c1; }
    .b-quality__row-main:nth-child(3n+3) .b-heading {
      color: #ffffff; }
      .b-quality__row-main:nth-child(3n+3) .b-heading:after {
        background-color: #fff; }
    .b-quality__row-main:nth-child(3n+3) .b-quality__imgWrap {
      transform: translateY(-320px); }
      @media (min-width: 991px) {
        .b-quality__row-main:nth-child(3n+3) .b-quality__imgWrap {
          transform: translateY(-210px); } }
      @media (min-width: 1199px) {
        .b-quality__row-main:nth-child(3n+3) .b-quality__imgWrap {
          transform: translateY(-140px); } }
    .b-quality__row-main:nth-child(3n+3) .b-quality__text, .b-quality__row-main:nth-child(3n+3) .b-quality__text p {
      color: #ffffff; }
  .b-quality__row-main:nth-child(2n+1) .b-quality__col-text {
    padding: 60px; }
    @media (max-width: 991px) {
      .b-quality__row-main:nth-child(2n+1) .b-quality__col-text {
        padding-right: 0;
        padding-left: 0; } }
  .b-quality__row-main:first-child {
    background-color: #283138; }
  .b-quality__row-main:nth-child(2n) .b-quality__col-text {
    padding: 3.75rem;
    padding-right: 0; }
    @media (max-width: 991px) {
      .b-quality__row-main:nth-child(2n) .b-quality__col-text {
        padding-left: 0; } }
  .b-quality__row-main:nth-child(2n) .b-quality__inner {
    flex-direction: row-reverse; }
  @media (min-width: 991px) {
    .b-quality__row-main:nth-child(3) .b-quality__imgWrap {
      max-height: none; } }
  .b-quality__row-main .b-quality__imgWrap {
    transform: none !important; }
  @media (min-width: 991px) {
    .b-quality__row-main:nth-child(2n+1) .b-quality__col-image {
      position: absolute;
      width: 45%;
      right: 0;
      top: 43px; } }
  @media (min-width: 991px) {
    .b-quality__row-main:nth-child(2n) .b-quality__col-image {
      position: absolute;
      left: 0;
      top: -40px; } }
  .b-quality__row-slider {
    background-color: #283138; }

.b-quality__row.b-quality__row-orange {
  background-color: #5487c1; }
  .b-quality__row.b-quality__row-orange .b-heading {
    color: #ffffff; }
  .b-quality__row.b-quality__row-orange .b-quality__text {
    color: #ffffff; }

.b-quality__row.b-quality__row_dark-grey {
  background-color: #949494; }
  .b-quality__row.b-quality__row_dark-grey .b-heading {
    color: #ffffff; }
  .b-quality__row.b-quality__row_dark-grey .b-quality__text {
    color: #ffffff; }

.b-quality__col {
  width: 50%;
  height: auto; }
  @media (max-width: 991px) {
    .b-quality__col {
      width: 100% !important;
      margin-bottom: 20px; }
      .b-quality__col + .b-quality__col-text {
        order: -1; } }

.b-quality__imgWrap {
  max-height: 450px;
  overflow: hidden;
  transform: translateY(-75px); }

.b-quality__img {
  width: 100%;
  height: auto; }
  @media (min-width: 425px) {
    .b-quality__img {
      height: 450px;
      object-fit: cover; } }
  @media (min-width: 991px) {
    .b-quality__img {
      width: 100%;
      height: auto; } }

.b-qualContacts__icon {
  width: 142px;
  height: 142px;
  margin-right: 30px;
  flex: 1 0 auto;
  background-image: url("../images/teee/contacts-icon.png");
  background-repeat: no-repeat;
  background-size: contain; }
  @media (max-width: 767px) {
    .b-qualContacts__icon {
      display: none; } }

.b-qualContacts__wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }

.b-qualContacts__text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 18px; }
  @media (max-width: 767px) {
    .b-qualContacts__text {
      flex-direction: column;
      align-items: center; } }

.b-qualContacts__item {
  font-size: 24px;
  font-weight: 700;
  color: #283138;
  text-decoration: none; }
  .b-qualContacts__item:hover {
    color: #5487c1; }
  @media (max-width: 767px) {
    .b-qualContacts__item {
      font-size: 20px; }
      .b-qualContacts__item:not(:last-child) {
        margin-bottom: 10px; } }

.qualSlider__slide {
  height: auto;
  padding-left: 120px;
  padding-right: 120px;
  padding-top: 20px;
  position: relative; }
  .qualSlider__slide::before {
    content: "";
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    top: 10px;
    left: 0;
    background-image: url("../images/slider/qual-slider-icon.png");
    background-size: 100% auto;
    background-repeat: no-repeat; }
    @media (max-width: 767px) {
      .qualSlider__slide::before {
        width: 45px;
        height: 45px; } }
    @media (max-width: 567px) {
      .qualSlider__slide::before {
        width: 25px;
        height: 25px; } }
  .qualSlider__slide .qualSlider__text::after {
    content: "";
    display: block;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 22px;
    bottom: 80px;
    background-image: url("../images/slider/qual-slider-icon-2.png");
    background-size: 100% auto;
    background-repeat: no-repeat; }
    @media (max-width: 767px) {
      .qualSlider__slide .qualSlider__text::after {
        width: 45px;
        height: 45px; } }
    @media (max-width: 567px) {
      .qualSlider__slide .qualSlider__text::after {
        width: 25px;
        height: 25px; } }
  @media (max-width: 767px) {
    .qualSlider__slide {
      padding-top: 0;
      padding-left: 80px;
      padding-right: 80px; } }
  @media (max-width: 567px) {
    .qualSlider__slide {
      padding-left: 40px;
      padding-right: 40px; } }

.qualSlider .slick-arrow {
  width: 35px;
  height: 35px;
  background-color: #5487c1;
  border-radius: 3px;
  transition: .3s; }
  .qualSlider .slick-arrow:hover {
    background-color: #3c5683; }
  .qualSlider .slick-arrow::before {
    display: none; }
  .qualSlider .slick-arrow::after {
    content: "";
    display: block;
    width: 18px;
    height: 12px;
    position: absolute;
    background-image: url("../images/qual-arrow.svg");
    background-repeat: no-repeat; }

.qualSlider .slick-next {
  top: auto;
  right: 20px;
  bottom: -10px;
  z-index: 100; }
  .qualSlider .slick-next::after {
    top: 50%;
    right: 50%;
    transform: translate(70%, -50%) rotate(90deg); }

.qualSlider .slick-prev {
  top: auto;
  right: 70px;
  bottom: -10px;
  left: auto;
  z-index: 100; }
  .qualSlider .slick-prev::after {
    top: 50%;
    right: 50%;
    transform: translate(35%, -40%) rotate(-90deg); }

@media (max-width: 767px) {
  .qualSlider {
    padding-bottom: 70px; } }

.qualSlider__text {
  color: #fff; }

.qualSlider__user {
  margin-top: 40px;
  display: flex;
  align-items: center; }
  @media (max-width: 767px) {
    .qualSlider__user {
      margin-top: 20px; } }

.qualSlider__avatar {
  margin-right: 20px; }

.qualSlider__userText {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  color: #fff; }

.qualSlider__name {
  font-size: 1.2em;
  color: #5487c1; }

.b-slide {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 80%; }

.b-slide__lead {
  display: block;
  font-size: .8em;
  color: #5487c1; }

.b-slide__text {
  font-style: oblique; }

.b-slide__btn {
  margin-top: auto;
  margin-left: 1.5em; }

.b-slide__link {
  padding: .5em .7em;
  font-size: 1.5em; }
