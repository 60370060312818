.blogInner__img {
    width: 100% !important;
}

.blogInner__gal {
    display: flex;
    flex-wrap: wrap;
}

.blogInner__galImgWrap {
    &:not(:last-child) {
        margin-right: 50px;
        margin-bottom: 30px;
    }
}

.entry-media img.b-blog__img {
    border-radius: 5px 5px 0 0 !important;
}

.b-blog__img-list {
    border-radius: none !important;

    width: 200px;
    margin-right: 40px;

    @media (max-width: 767px) {
        width: 100%;
        margin-right: 0;
    }
}

.b-blogList,
.b-blogList ul {
    list-style: none;
}

.b-blogList__item {
    display: flex;
    justify-content: flex-start;

    margin-bottom: 70px;

    position: relative;

    @media (max-width: 767px) {
        flex-direction: column;
    }
/*
    &::after {
        content: "";
        display: block;

        width: 30%;
        height: 2px;

        position: absolute;
        bottom: -20px;
        left: 5px;

        border-radius: none !important;

        background: #5487c1;
    }

    &:last-child {
        &::after {
            display: none;
        }
    }*/
}

.b-blogList__imgWrap {
    flex: 0 0 auto;

    @media (max-width: 767px) {
        margin-bottom: 20px;
    }
}

.b-blogList__heading {
    font-size: 2rem;
    color: #283128;

    transition: .3s;

    &:hover {
        color: #3b463b;       
    }
}

.b-blogList__link {
    color: inherit;
}

.b-blogList__text {
    padding-left: 5px;
}

.b-blogList__icon {
    padding-right: 5px;

    color: #5487c1;
}

.b-blog .blogLogo,
.b-contact .blogLogo {

    @media (miin-width: 992px) {
        margin: -10px auto auto;
    }
}

.b-blog .topbar,
.b-contact .topbar {
    margin-top: -10px;
}

.b-blog .sticky-header a {
    @media (miin-width: 992px) {
        margin-top: 10px !important;
    }
}
