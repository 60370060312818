.video-player {
	@extend .video-pic;

	display: block;

	&.index__vidLink {
		padding-top: 0;

	}

	@include media-query($medium) {
		//padding: 30px 0;
		padding: 0;
		margin: 30px 0;

		&::after { content: none; }

		&::before {
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			//top: 50%;
			//left: 50%;
			//transform: translate3d(-50%, -50%, 0);
		}

		img {
			width: 100%;
		}
	}

	@include media-query($phones) {
		&::before {
			width: 50px;
			height: 50px;
			padding-left: 5px;
			border-width: 3px;
			font-size: 30px;
		}
	}
}
