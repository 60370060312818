.b-down__list {
    margin-top: 30px;
}

.b-down__linkList {
    margin-right: 30px;

    display: flex;
    flex-direction: column;
}

.b-down__item {
    display: flex;
    flex-direction: column;

    margin-bottom: 50px;

    &:last-child {
        margin-bottom: 0;
    }

}

.b-down__desc {
    @media (max-width: 767px) {
        flex-direction: column;
    }
}

.b-down__links {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 115px;

    margin-top: 5px;
    margin-right: 30px;

    @media (max-width: 767px) {
        margin-bottom: 25px;
        margin-right: 0;
    }
}

.b-down__icon {
    color: #5487c1;
    margin-left: 8px;
}

.b-down__link {
    width: 60px;
    height: 60px;
    margin: 0 0 20px 0;

    font-size: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;

    transition: .3s;
    
    &-pdf {
        background-image: url("../images/teee/down-icon-pdf.svg");
    }

    &-doc {
        background-image: url("../images/teee/down-icon-doc.svg");
    }

    &-xls {
        background-image: url("../images/teee/down-icon-xls.svg");
    }

    &:hover {
        opacity: .7;
    }
}

.b-down__textLink {
    text-decoration: none;
    color: #283138;

    font: inherit;
    font-size: 1.3rem;

    transition: .3s;

    &:hover {
        opacity: .7;
    }
}

.b-down__desc {
    display: flex;
}
