.social-list {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-right: 0 !important;

	& > * {
		flex: 0 0 50px;
		font-size: 1.5rem;
	}
}

.social-list_footer {
	width: 100%;
	margin-top: 30px;

	a .fa {
		transition: all .3s ease-in;
		color: $theme-secondary-color;

		&:hover {
			color: $white;
		}
	}
}

.social-list_header {
	margin-top: 20px;
	justify-content: flex-end;
	text-align: right;

	@include media-query($tablets) {
		justify-content: center;
		text-align: center;
	}

	a .fa {
		transition: all .3s ease-in;
		color: $theme-primary-color;
		font-size: 1.5rem;

		&:hover {
			color: $theme-secondary-color;
		}
	}
}