.img-miniature {
	position: relative;
	display: inline-block;
	max-width: 200px;
	border: 2px solid $theme-secondary-color;
	

	&, &::before, &::after, * {
		transition: all .3s ease-in;
	}

	cursor: pointer;

	&::before {
		content: "\f00e";
		position: absolute;
		display: flex;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		align-items: center;
		justify-content: center;
		font-family: $fa;
		font-size: 30px;
		color: $theme-secondary-color;
		background-color: rgba($white, .5);
		opacity: 0;
		z-index: 2;
	}

	&:hover {
		img { filter: grayscale(1); }

		&::before { opacity: 1; }
	}

	img {
		display: block;
		width: 100%;
		max-width: 100%;
		filter: grayscale(0);
	}
}