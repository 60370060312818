/*--------------------------------------------------------------
#7.0	blog-grid section
--------------------------------------------------------------*/
.blog-grid-section {
	.news-grids {
		overflow: hidden;
		padding-top: 0;

		column-count: 3;
		column-gap: 0;



		@include media-query(991px) {
			column-count: 2;
		}

		@include media-query(580px) {
			column-count: 1;
		}

	}

	.news-grids .grid {
		margin-bottom: 30px;
		width: 100%;
		//padding: 0;
		display: inline-block;
		float: none;

		.b-blog__img {
			width: 100%;
			object-fit: cover;

			padding: 1.875rem 1.875rem 0;
			//max-height: 244px;


		}
	}

	.news-grids > .grid:last-child {
		margin-top: 0;
	}

	.pagination-wrapper {
		margin-top: 50px;

		@include media-query(991px) {
			margin-top: 30px;
		}

		@include media-query(767px) {
			margin-top: 20px;
		}
	}
}
