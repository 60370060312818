/*--------------------------------------------------------------
#0.6	services
--------------------------------------------------------------*/
.all-service-link,
.all-news-link {
    text-align: right;
    
    @include media-query(991px) {
		text-align: left;
        margin-top: 25px;
	}
}

.service-slider {
    .owl-controls {
        margin-top: 50px;
        
        @include media-query(991px) {
            margin-top: 35px;
        }
    }
    
    @include media-query(991px) {
		margin-top: 50px;
	}
}

.services-grid-section {
    .all-services {
        text-align: center;
        padding-top: 30px;
    }
}

.services-grid-view {
    overflow: hidden;
    margin: 0 -15px;

    .grid {
        width: 33.33%;
        float: left;
        padding: 0 15px 30px;

        @include media-query(991px) {
            width: 50%;
        }

        @include media-query(650px) {
            width: 100%;
            float: none;
        }
    }
}


/*--------------------------------------------------------------
#0.7	testimonials
--------------------------------------------------------------*/
.testimonials {
    position: relative;
    
    &:before {
        content: "";
        @include overlay(transparentize($theme-primary-color, 0.1));
    }
}

.testimonials-slider {
    position: relative;
    
    .inner {
        padding: 0 155px;

        @include media-query(1199px) {
            padding: 0 120px;
        }

        @include media-query(767px) {
            padding: 0;
        }
    }
    
    .slide-item:before {
        font-family: "Flaticon";
        content: "\f102";
        font-size: 60px;
        color: $theme-secondary-color;
        position: absolute;
        left: 30px;
        top: -14px;

        @include media-query(1199px) {
            font-size: 50px;
            font-size: calc-rem-value(50);
        }

        @include media-query(991px) {
            font-size: 40px;
            font-size: calc-rem-value(40);
        }

        @include media-query(767px) {
            font-size: 35px;
            font-size: calc-rem-value(35);
        }

        @include media-query(767px) {
            display: none;
        }
    }
    
    .slide-item img {
        width: auto;
    }
    
    .slide-item p {
        font-size: 22px;
        font-size: calc-rem-value(22);
        color: $white;

        @include media-query(1199px) {
            font-size: 18px;
            font-size: calc-rem-value(18);
        }

        @include media-query(991px) {
            font-size: 16px;
            font-size: calc-rem-value(16);
        }
    }

    .client-quote {
        margin-bottom: 40px;
    }

    .client-details {
        position: relative;
        padding: 0 0 10px 85px;
    }

    .client-pic {
        position: absolute;
        left: 0;
        min-height: 58px;
        border-bottom: 2px solid $theme-secondary-color;
    }

    .client-info {
        padding-top: 5px;
    }
    
    .client-info h4 {
        font-size: 20px;
        font-size: calc-rem-value(20);
        color: $theme-secondary-color;
        margin: 0 0 0.3em;

        @include media-query(1199px) {
            font-size: 16px;
            font-size: calc-rem-value(16);
        }
    }

    .client-info > span {
        font-size: 14px;
        font-size: calc-rem-value(14);
        color: #dddddd;
    }

    .owl-controls {
        position: absolute;
        right: 0;
        bottom: -45px;

        @include media-query(767px) {
           position: static;
           text-align: left;
           margin: 15px 0 0 -5px;
        }

        .owl-prev,
        .owl-next {
            text-align: center;
        }
    }
}


/*--------------------------------------------------------------
#0.8    offer
--------------------------------------------------------------*/
.offer-text {
    @include widther(1200px) {
        padding-right: 30px;
    }

    > p:nth-child(2) {
        margin-bottom: 55px;

        @include media-query(991px) {
            margin-bottom: 40px;
        }
    }
}

.offer-grids {
    overflow: hidden;
    margin: 0 -15px;

    @include media-query(991px) {
        margin-top: 80px;
    }

    @include media-query(767px) {
        margin-top: 60px;
    }

    .grid {
        width: 50%;
        float: left;
        padding: 0 15px;

        @include media-query(600px) {
            width: 100%;
            float: left;
            margin-bottom: 30px;
        }
    }

    > .grid:nth-child(1),
    > .grid:nth-child(2) {
        margin-bottom: 40px;
    }

    > .grid:last-child {
        margin-bottom: 0;
    }

    .details {
        padding-left: 75px;
        position: relative;

        @include media-query(767px) {
            padding-left: 55px;
        }
    }

    .details > h3 {
        font-size: 20px;
        font-size: calc-rem-value(20);
        margin: 0 0 1em;

        @include media-query(1199px) {
            font-size: 18px;
            font-size: calc-rem-value(18);
        }

        @include media-query(767px) {
            font-size: 16px;
            font-size: calc-rem-value(16);
        }
    }

    .details .icon {
        position: absolute;
        left: 0;
        top: -10px;

        @include media-query(1199px) {
            left: 8px;
        }

        @include media-query(991px) {
            left: 0;
        }
    }

    .details .icon i:before {
        font-size: 45px;

        @include media-query(1199px) {
            font-size: 40px;
        }

        @include media-query(767px) {
            font-size: 30px;
        }
    }

    .details .offer-details {
        font-weight: 600;
        color: $theme-secondary-color;
        @include transition-time(0.2s);

        &:hover {
            color: darken($theme-secondary-color, 5%);
        }
    }
}

.offer-section {
    padding-bottom: 145px;

    @include media-query(991px) {
        padding-bottom: 80px;
    }

}

.offer-section_dark {
	background-color: $theme-primary-color;
	

	&, *:not([class]) {
		color: $white;
	}
}

.offer-pic {
    padding: 40px 0 0 45px;
    position: relative;

    @include media-query(991px) {
        //display: none;
    }

    //&::after {
	//	content: "";
	//	position: absolute;
    //    left: 0px;
	//	top: -20px;
	//	right: -40px;
	//	bottom: -40px;
    //    border: 2px solid $theme-secondary-color;
    //}

    img {
        position: relative;
        z-index: 2;
		border: 2px solid #5487c1;
		//padding: 30px;

		@media (min-width: 768px) {
			padding: 20px;
		}

		@media (min-width: 1200px) {
			padding: 20px;
		}
    }
}


/*--------------------------------------------------------------
#0.9    recent-projects
--------------------------------------------------------------*/
.recent-projects {
    background-color: $theme-primary-color;


    p {
        font-size: 18px;
    }
}

.recent-projects-grids,
.projects-grid-view {
    
    @include media-query(767px) {
       overflow: hidden;
    }

    .grid {
        position:relative;
        overflow:hidden;
        display: inline-block;
        float: left;

        @include media-query(767px) {
            width: 50%;
            float: left;
        }

        @include media-query(550px) {
            width: 100%;
            float: none;
        }
    }

    @include media-query(550px) {
        .project-img img {
            width: 100%;
        }
    }

    .project-info {
        background-color: transparentize($theme-primary-color, 0.1);
        width: 95%;
        height: 92%;
        border: 2px solid $theme-secondary-color;
        position: absolute;
        left: -10%;
        top: 4%;
        opacity: 0;
        @include transition-time(0.5s);
    }

    .grid:hover .project-info {
        left: 2.5%;
        opacity: 1;
    }

    .inner-info {
        width: 100%;
        text-align: center;
        position: absolute;
        top: 50%;
        @include translatingY(-50%);
        z-index: 10;
    }

    .inner-info > a {
        color: $white;
    }

    .inner-info h3 {
        font-family: $base-font;
        font-size: 22px;
        font-size: calc-rem-value(22);
        color: $white;
        margin: 0 0 0.2em;
        position: relative;
        top: -10px;
        opacity: 0;
        -webkit-transition: all 0.3s 0.5s;
        transition: all 0.3s 0.5s;

        @include media-query(991px) {
            font-size: 20px;
            font-size: calc-rem-value(20);
        }

        @include media-query(767px) {
            font-size: 18px;
            font-size: calc-rem-value(18);
        }
    }

    .inner-info .tags {
        color: $theme-secondary-color;
        position: relative;
        top: 10px;
        opacity: 0;
        -webkit-transition: all 0.3s 0.5s;
        transition: all 0.3s 0.5s;

        @include media-query(767px) {
            font-size: 14px;
            font-size: calc-rem-value(14);
        }
    }

    .grid:hover .inner-info h3,
    .grid:hover .inner-info .tags {
        top: 0;
        opacity: 1;
    }

    .mCustomScrollBox {
		height: auto;
        @include media-query(550px) {
            position: static;
        }
    }

    .mCSB_horizontal.mCSB_inside>.mCSB_container {
        margin-bottom: 80px;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal {
        max-width: 850px;
        margin: 0 auto;

        @include media-query(991px) {
             max-width: 700px;
        }
    }
    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail,
    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_dragger .mCSB_dragger_bar {
        height: 10px;
    }

    .mCSB_scrollTools.mCSB_scrollTools_horizontal .mCSB_draggerRail {
        background-color: #3a444c;
    }

    .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        background-color: $theme-secondary-color;
    }

    .mCSB_inside>.mCSB_container {
        @include media-query(550px) {
            margin-right: 0;
        }
    }
}

.projects-grid-view-section {
    .more-projects {
        margin-top: 30px;
        text-align: center;
    }
}

.projects-grid-view {
    margin: 0 -15px;

    .grid {
        width: calc(33.33% - 30px);
        margin: 0 15px 30px;
        float: left;

        @include media-query(991px) {
            width: calc(50% - 30px);
        }

        @include media-query(550px) {
            width: calc(100% - 30px);
            float: none;
        }
    }
}


/*--------------------------------------------------------------
#1.0    about-us-faq
--------------------------------------------------------------*/
.about-us-faq {
    position: relative;

    .backhoe-loader {
        position: absolute;
        right: 0;
        top: 160px;

        @include media-query(1800px) {
            display: none;
        }
    }
}

.about-us-section {
    .btns {
        margin: 50px 0 45px;

        @include media-query(767px) {
             margin: 35px 0;
        }
    }

    .social > p {
        text-transform: uppercase;
    }

    .social-links a {
        font-size: 32px;
        font-size: calc-rem-value(32);
        color: #333;

        @include media-query(991px) {
            font-size: 26px;
            font-size: calc-rem-value(26);
        }

        @include media-query(767px) {
            font-size: 22px;
            font-size: calc-rem-value(22);
        }

        &:hover {
            color: $theme-secondary-color;
        }
    }

    .social-links > li + li {
        margin-left: 15px;

        @include media-query(991px) {
            margin-left: 10px;
        }
    }
}

.faq-section {
    position: relative;
    z-index: 1;

    @include media-query(1199px) {
        margin-top: 50px;
    }

    @include media-query(767px) {
        margin-top: 40px;
    }

    .faq-accordion {
        padding-top: 7px;
    }
}


/*--------------------------------------------------------------
#1.1    partners
--------------------------------------------------------------*/
.partners {
    padding-top: 0;
}

.partners-bg {
    background-color: #f6f6f6;
    padding: 45px 0;
}

.partners-slider {
    .grid {
        border: 2px solid #e9e9e9;
        @include transition-time(0.3s);
    }

    .grid:hover {
        border-color: $theme-secondary-color;
    }
}


/*--------------------------------------------------------------
#1.2    partners
--------------------------------------------------------------*/
.contact-section {
    position: relative;

    &:before {
        content: "";
        @include overlay(transparentize($theme-primary-color, 0.1));
    }
    
    .section-title {
        @include widther(992px) {
            margin-bottom: 30px;
        }
    }

    p {
        color: #aaa;
        line-height: 1.56em;
    }
}

.contact-section-contact-box {
    padding: 38px 45px;
    border: 2px solid $theme-secondary-color;

    @include media-query(991px) {
        margin-bottom: 60px;
    }

    @include media-query(767px) {
        margin-bottom: 45px;
    }

    @include media-query(450px) {
        padding: 38px 25px;
    }


    .details ul {
        list-style-type: none;
        color: $white;
    }

    .details ul li {
        position: relative;
        padding-left: 25px;
    }

    .details ul i {
        color: $theme-secondary-color;
        position: absolute;
        left: 0;
        top: 3px;
    }

    .details ul > li + li {
        margin-top: 20px;
    }
}

.contact-form-s1 {
    label {
        font-size: 12px;
        font-size: calc-rem-value(12);
        font-weight: normal;
        display: block;
        color: $theme-secondary-color;
        text-transform: uppercase;

        @include media-query(767px) {
            font-size: 11px;
            font-size: calc-rem-value(11);
        }
    }

    input,
    select {
        height: 38px;
        border: 0;

        @include media-query(767px) {
            height: 34px;
        }
    }

    form {
        overflow: hidden;
        margin: 0 -12px;
    }

    form > div {
        width: 50%;
        float: left;
        padding: 0 12px;
        margin-top: 12px;
        
        @include media-query(450px) {
            width: 100%;
            float: none;
        }
    }

    form .submit-btn-wrap {
        width: 100%;
        float: none;
        clear: both;
        padding-top: 35px;
    }

    .wpcf7-submit {
        background-color: $theme-secondary-color;
        color: $white;
        @include transition-time(0.3s);

        &:hover {
            background-color: darken($theme-secondary-color, 8%);
        }
    }
}

.contact-women {
    position: absolute;
    left: 0;
    bottom: 0;

    @include media-query(1550px) {
        display: none;
    }
}


/*--------------------------------------------------------------
#1.3    news-section
--------------------------------------------------------------*/
.news-grids {
    margin: 45px -15px 0;
    padding-top: 20px;
    overflow: hidden;

    @include media-query(991px) {
        padding-top: 50px;
    }

    .grid {
        width: 33.33%;
        float: left;
        padding: 0 15px;

        @include media-query(991px) {
            width: 50%;
        }

        @include media-query(580px) {
            width: 100%;
            float: none;
            margin-bottom: 40px;
        }
    }

    @include media-query(991px) {
        > .grid:last-child {
           margin-top: 40px;
        }
    }

    @include media-query(580px) {
        > .grid:last-child {
           margin-bottom: 0;
        }
    }

    .entry-details {
        //padding: 20px 30px;
        border: 1px solid #e8e8e8;

        @include media-query(1199px) {
            //padding: 20px 25px;
        }

        @include media-query(767px) {
            //padding: 20px;
        }
    }

	.entry-meta {
		padding: 20px 30px 0;
	}

	.entry-body {
		padding: 0 30px 33px;

		p:last-child {
			margin-bottom: 0;
		}
	}

    .entry-meta ul {
        list-style: none;
        overflow: hidden;
    }

    .entry-meta ul li {
        font-size: 14px;
        font-size: calc-rem-value(14);
        color: #9a9a9a;
        float: left;

        @include media-query(1199px) {
            font-size: 13px;
            font-size: calc-rem-value(13);
        }

        @include media-query(767px) {
            font-size: 12px;
            font-size: calc-rem-value(12);
        }
    }

    .entry-meta ul li a {
        color: #9a9a9a;
    }

    .entry-meta ul li i {
        display: inline-block;
        color: $theme-secondary-color;
        padding-right: 4px;
    }

    .entry-meta ul > li + li {
        margin-left: 15px;
    }

    .entry-media img {
        border-radius: 5px;
    }

    .entry-body h3 {
        font-family: $base-font;
        font-size: 18px;
        font-size: calc-rem-value(18);
        line-height: 1.3em;
        font-weight: 600;
        margin: 0.72em 0 0.83em;

        @include media-query(1199px) {
            font-size: 16px;
            font-size: calc-rem-value(16);
        }

        @include media-query(767px) {
            font-size: 14px;
            font-size: calc-rem-value(14);
        }
    }

    .entry-body h3 a {
        color: $heading-color;

        &:hover {
            color: $theme-secondary-color;
        }
    }

    .entry-body p {
        font-size: 14px;
        font-size: calc-rem-value(14);
        line-height: 1.57em;
    }
}













