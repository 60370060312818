.theme-accordion-s1 {
	margin-bottom: 0;

	.panel-default {
		background: transparent;
		border: 1px solid #dee0e1;
		border-radius: 0;
	}

	.panel-group .panel+.panel {
	    margin-top: 8px;
	}

	.panel-heading {
		background-color: transparent;
		padding: 0;
		border-radius: 0;
	}

	.panel-heading a {
		background-color: rgba(253, 212, 51, 0.95);		
		font-size: 18px;
		font-size: calc-rem-value(18);
		font-weight: 600;
		color: #222;
		display: block;
		padding: 15px 25px;
		position: relative;

		@include media-query(1800px) {
			background-color: $theme-secondary-color;
		}

		@include media-query(991px) {
			font-size: 16px;
			font-size: calc-rem-value(16);
			padding: 12px 20px;
        }

		@include media-query(767px) {
			padding: 12px 15px;
        }

		&:before {
			font-family: "FontAwesome";
			content: "\f107";
			font-size: 20px;
			font-size: calc-rem-value(20);
			position: absolute;
			right: 25px;
			-webkit-transform: rotate(180deg);
			transform: rotate(180deg);
			@include transition-time(0.3s);

			@include media-query(991px) {
				font-size: 18px;
				font-size: calc-rem-value(18);
				right: 20px;
	        }
		}
	}

	.panel-heading .collapsed {
		background-color: $white;
	}

	.panel-heading .collapsed:before {
		-webkit-transform: rotate(0);
		transform: rotate(0);
	}

	.panel-heading+.panel-collapse>.panel-body {
		background: transparentize($white, 0.1);
		border: 0;
		padding: 24px 33px 12px;

		@include media-query(1800px) {
			background-color: $white;
		}

		@include media-query(991px) {
			padding: 20px 20px 10px;
		}

		@include media-query(767px) {
			padding: 15px 15px 8px;
		}
	}
}