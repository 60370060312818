.form {
    input,
    textarea,
    select {
        border-color: lighten( $black, 75% );
        border-radius: 0;
        outline: 0;
        box-shadow: none;
        color: lighten($black, 35%);

        &:focus {
            border-color: $theme-primary-color;
            @include round-box-shadow( lighten($theme-primary-color, 10%), 5px, 0 );
        }
    }

    @include placeholder-style(lighten($black, 35%));

}

.wpcf7-form {
    input, select {
        height:34px;
    }

    input,
    textarea,
    select {
        display:block;
        width:100%;
        padding:6px 12px;
        font-size:14px;
        line-height:1.42857143;
        color:#555;
        background-color:#fff;
        background-image:none;
        border:1px solid #ccc;
        border-radius:4px;
        -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,.076);
        box-shadow:inset 0 1px 1px rgba(0,0,0,.075);
        -webkit-transition:border-color ease-in-out .15s,
        -webkit-box-shadow ease-in-out .15s;
        -o-transition:border-color ease-in-out .15s,
        box-shadow ease-in-out .15s;
        transition:border-color ease-in-out .15s,
        box-shadow ease-in-out .15s
    }

    textarea {
        padding: 12px;
        line-height: 1.3;

    }

    select {
        font-style: normal;
        background-image: url(../images/select-icon.png);
        background-repeat: no-repeat;
        background-position: calc(100% - 15px) center;
        display: inline-block;
        -webkit-appearance:none;
        -moz-appearance:none;
        -ms-appearance:none;
        appearance:none;
        cursor:pointer;
    }
    
    select::-ms-expand { /* for IE 11 */
        display: none;
    }
}


/* FORM ELEMENTS */
.required {
	display: inline-flex;
	
	&::after {
		content: "\f069";
		margin-left: 7px;
		font-family: $fa;
		//font-size: 14px;
        font-size: 0.6rem;
		color: $error;
	}
}