.b-contact__link {
    color: #283138;

    text-decoration: none;
}

.contact-pg-section .contact-info .icon {

    i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

