.team-slider_factory {

	// Arrows
	&.team-grids .owl-controls .owl-nav {
		top: 46%;
	}

	&.team-grids .owl-controls .owl-nav .owl-next,
	&.team-grids .owl-controls .owl-nav .owl-prev {
		color: $white;
	}

	// Dots
	&.team-grids .owl-controls .owl-dots {
		display: none!important;

		@include media-query($large) {
			display: block!important;
		}
	}
}

.team-slider_factory-name {
	position: absolute;
	display: flex;
	top: 10px;
	bottom: 10px;
	left: 10px;
	right: 10px;
	align-items: center;
	justify-content: center;
	text-align: center;
	opacity: 0;
	transition: all .3s ease-out;
	z-index: 10;

	cursor: pointer;

	&:hover {
		opacity: 1;
	}
}