.b-slide {
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    height: 80%;
}

.b-slide__lead {
    display: block;

    font-size: .8em;;
    color: #5487c1;
}

.b-slide__text {
    font-style: oblique;
}

.b-slide__btn {
    margin-top: auto;
    margin-left: 1.5em;
}

.b-slide__link {
    padding: .5em .7em;

    font-size: 1.5em;
}
