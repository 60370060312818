.pagenotfound{
    &__inner{
        text-align: center;
    }
    &__header{
        text-align: center;
        &:after{
            left: 50%!important;
            transform: translateX(-50%)!important;
        }
    }
    &__text{
        color: rgb(40, 49, 40);
        font-size: 1.3rem;
        color: rgb(106, 106, 106);
        line-height: 1.8em;
        margin-bottom: .9375rem;
    }
    .button{
        display: inline-block;
    }
}