.b-formWrap {
    padding-bottom: 50px !important;


	ul, li a {
		font-size: 15px;
		color: #6a6a6a;
		margin-bottom: .9375rem;
	}

	list-style: none;

	p {
		font-size: 18px;
	}

	.careers-page {
		h2 {
			margin-top: 40px;
			position: relative;
			padding-bottom: 1.25rem;

			&:before {
				content: "";
				display: block;
				width: 33px;
				height: 5px;
				position: absolute;
				bottom: 0;
				left: 0;
				background-color: #5487c1;
				border-radius: 3px;
			}
		}

		h2:first-child {
			margin-top: 0;
		}

		ul, li {
			font-size: 15px;
			color: #6a6a6a;
			margin-bottom: .9375rem;
		}

		.wpcf7 {
			margin-top: 50px;
		}
	}

}

.b-form {
    margin-top: 20px !important;

    font-size: 1rem;

    .b-form__input,
    .b-form__area {
        border-radius: 10px;

        font-size: 1em;
        line-height: 1.1;
    }

    .b-form__area {
        resize: none;

        overflow: auto;
    }
}

.b-form__label {
    color: #5b6a75;
}

.b-form__area {
    & + .b-form__label {
        top: 10px;
        transform: none;
    }
}

.b-form__inputWrap {
    margin-bottom: 20px !important;
}

.b-formWrap__text {
    margin-bottom: 0;
	font-size: 18px;
}
