.modal-win {
	display: none;

	position: fixed;
	width: 100vw;
	height: 100vh;
	padding-top: 70px;
	top: 0;
	left: 0;
	align-items: center;
	justify-content: center;
	background-color: rgba($theme-primary-color, .8);

	@include media-query($l-phones) {
		padding-top: 85px;
	}

	&.open {
		display: flex;
		z-index: 1000;
	}
}

.modal-win__wrapper {
	position: relative;
	display: block;
	width: 80%;
	height: 80%;

	@include media-query($tablets) {
		width: 95%;
	}
}

.modal-win__close {
	position: absolute;
	display: flex;
	width: 50px;
	height: 50px;
	top: -50px;
	right: 0;
	align-items: center;
	justify-content: flex-end;
	cursor: pointer;

	&::before {
		content: "\f00d";
		font-family: $fa;
		font-size: 20px;
		color: rgba($white, .5);
		transition: all .3s ease-in;
	}

	&:hover {
		&::before {
			color: $white;
		}
	}
}

.modal-win__frame {
	text-align: center;
	&,
	img,
	iframe,
	video {
		width: 100%;
		height: 100%;
	}

	img {
		object-fit: contain;
		width: auto;
	}
}