/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name: 	
Version:        1.0.0
Author:         themexriver
URL:            http://themeforest.net/user/themexriver
-------------------------------------------------------------------*/


/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
#0.1	Theme Reset Style
#0.2	Global Elements
#0.3	header
#0.4	hero-slider
#0.5	footer
#0.6	services
#0.7	testimonials
#0.8    offer
#0.9    recent-projects
#1.0    about-us-faq
#1.1    partners
#1.2    partners
#1.3    news-section


---------------------------------------------------------------
	home style 2
----------------------------------------------------------------
#2.0    cta
#2.1    services s2
#2.2    our-team
#2.3    fun-fact
#2.4    cta-newsletter
#2.5    testimoials-s2-slider


---------------------------------------------------------------
	home style 3
----------------------------------------------------------------
#3.0    features
#3.1    services-s3
#3.2    pricing


---------------------------------------------------------------
	faq page
----------------------------------------------------------------
#4.0	faq-pg-section


---------------------------------------------------------------
	service single page
----------------------------------------------------------------
#5.0	service-singel-section


---------------------------------------------------------------
	contact page
----------------------------------------------------------------
#6.0	contact-pg-section


---------------------------------------------------------------
	blog grid page
----------------------------------------------------------------
#7.0	blog-grid section


---------------------------------------------------------------
	blog with sidebar page
----------------------------------------------------------------
#8.0	blog-with-sidebar


---------------------------------------------------------------
	blog details page
----------------------------------------------------------------
#9.0	blog-single-content


---------------------------------------------------------------
	shop grid page
----------------------------------------------------------------
#10.0	products-section


---------------------------------------------------------------
	shop with sidebar page
----------------------------------------------------------------
#11.0	shop-main-content


---------------------------------------------------------------
	shop details page
----------------------------------------------------------------
#12.0	products-section



----------------------------------------------------------------*/

// helpers
@import "helpers/variables";
@import "helpers/functions";
@import "helpers/mixins";

// base
@import "base/base";

// layout
/* @import "layout/header";
@import "layout/hero-slider";
@import "layout/footer"; */

@import "layout/_footer.scss";
@import "layout/_header.scss";
@import "layout/_hero-slider.scss";


// components
/* @import "components/sticky-header";
@import "components/typography";
@import "components/section-title";
@import "components/buttons";
@import "components/form";
@import "components/social-links";
@import "components/page-title";
@import "components/preloader";
@import "components/service-grids";
@import "components/dots-arrow-style";
@import "components/accordion";
@import "components/team-grids";
@import "components/service-single-sidebar";
@import "components/pagination";
@import "components/blog-sidebar.scss";
@import "components/products-grids.scss";
@import "components/shop-sidebar.scss"; */

@import "components/_accordion.scss";
@import "components/_blog-sidebar.scss";
@import "components/_buttons.scss";
@import "components/_certification.scss";
@import "components/_dots-arrow-style.scss";
@import "components/_form.scss";
@import "components/_get-quote.scss";
@import "components/_img-miniature.scss";
@import "components/_modal.scss";
@import "components/_page-title.scss";
@import "components/_pagination.scss";
@import "components/_preloader.scss";
@import "components/_products-grids.scss";
@import "components/_section-title.scss";
@import "components/_service-grids.scss";
@import "components/_service-single-sidebar.scss";
@import "components/_shop-sidebar.scss";
@import "components/_simpletab.scss";
@import "components/_site-logo.scss";
@import "components/_social-links.scss";
@import "components/_social-list.scss";
@import "components/_sticky-header.scss";
@import "components/_team-grids.scss";
@import "components/_team-slider.scss";
@import "components/_typography.scss";
@import "components/_video-player.scss";

// page
/* @import "page/home-default";
@import "page/home-style2";
@import "page/home-style3";
@import "page/faq";
@import "page/service-single";
@import "page/project-single";
@import "page/contact";
@import "page/blog-grid";
@import "page/blog-with-sidebar";
@import "page/blog-details";
@import "page/shop";
@import "page/shop-with-sidebar";
@import "page/shop-details"; */


@import "page/_404.scss";
@import "page/_blog-details.scss";
@import "page/_blog-grid.scss";
@import "page/_blog-with-sidebar.scss";
@import "page/_contact.scss";
@import "page/_faq.scss";
@import "page/_home-default.scss";
@import "page/_home-style2.scss";
@import "page/_home-style3.scss";
@import "page/_products-grid.scss";
@import "page/_project-single.scss";
@import "page/_service-single.scss";
@import "page/_shop-details.scss";
@import "page/_shop-with-sidebar.scss";
@import "page/_shop.scss";
@import "page/z-TEEE-index/_b-blog.scss";
@import "page/z-TEEE-index/_b-contacts.scss";
@import "page/z-TEEE-index/_b-downloads.scss";
@import "page/z-TEEE-index/_b-features.scss";
@import "page/z-TEEE-index/_b-form.scss";
@import "page/z-TEEE-index/_b-header.scss";
@import "page/z-TEEE-index/_b-index.scss";
@import "page/z-TEEE-index/_b-ourteam.scss";
@import "page/z-TEEE-index/_b-quality.scss";
@import "page/z-TEEE-index/_b-slider.scss";

