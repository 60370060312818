.preloader {
	width: 100%;
	background-color: $white;
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 10001;

	div {
		position: absolute;
		left: 50%;
		top: 50%;
		@include center-by-translating();
	}
}