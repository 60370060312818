.b-qualContacts {
    background-color: rgb(242, 242, 242);
}

.b-qualitySlider__text {

    font-size: 39px !important;
    font-family: Montserrat,sans-serif;
    font-weight: 700;


    //@media (max-width: 767px) {
    //    font-size: 40px !important;
    //}
}

.b-quality__inner {
    width: 1140px;
    margin: 0 auto;
    padding: 40px 0;

    display: flex;
    justify-content: space-between;
    align-items: center;
  position: relative;

    &-head {
        display: block;
    }

    &-slider {
        padding: 60px 0 80px;

        display: block;
    }

    &-contacts {
        padding: 40px 0;

        align-items: stretch;
    }

    @media (max-width: 1199px) {
        width: 940px;
    }

    @media (max-width: 991px) {
        //width: 720px;
    }

    @media (max-width: 991px) {
        width: 100%;
        padding: 40px 20px;

        flex-direction: column;
    }
}

.b-quality__text {
    margin: 14px 0 0;

    color: #6a6a6a;

    font-size: 18px;


    @media (max-width: 1199px) {
        padding: 0;
    }
    
}

.b-quality__row {
    width: 100%;
    //min-height: 470px;
    
    &-main {

		.b-quality__inner {
			flex-wrap: wrap;
		}


		&:nth-child(3n+1) {
			background-color: #fff;
		}


		&:nth-child(3n+2) {
			background-color: #283138;

			.b-quality__inner {
				//flex-direction: row-reverse;
			}

            .b-heading, .b-quality__text, .b-quality__text p {
              color: #ffffff;
            }

		}



		&:nth-child(3n+3) {
			background-color: #5487c1;

			.b-heading {
				color: #ffffff;

				&:after {
					background-color: #fff;
				}
			}

          .b-quality__imgWrap {
            transform: translateY(-320px);

            @media (min-width: 991px) {
              transform: translateY(-210px);

            }

            @media (min-width: 1199px) {
              transform: translateY(-140px);
            }

          }

			.b-quality__text, .b-quality__text p {
				color: #ffffff;
			}

		}

		&:nth-child(2n+1) {
			.b-quality__col-text {
				padding: 60px;

				@media (max-width: 991px) {
					padding-right: 0;
                    padding-left: 0;
				}
			}
		}


      &:first-child {
        background-color: #283138;
      }

        .b-quality__col.b-quality__col-text {
          //transform: translateY(-200px);
        }


		&:nth-child(2n) {

			.b-quality__col-text {
				//padding-left: 60px;
                padding: 3.75rem;
              padding-right: 0;

				@media (max-width: 991px) {
					padding-left: 0;
				}

			}

			.b-quality__inner {
				flex-direction: row-reverse;
			}
		}


      &:nth-child(3) {
        .b-quality__imgWrap {
          @media (min-width: 991px) {
            max-height: none;
          }
        }
      }

      //&:nth-child(4) {
      //  .b-quality__imgWrap {
      //    @media (min-width: 991px) {
      //      transform: translateY(-120px);
      //
      //    }
      //  }
      //}


      //&:nth-child(1) {
      //  .b-quality__imgWrap {
      //
      //    transform: translateY(-190px);
      //
      //    @media (min-width: 991px) {
      //      transform: translateY(-140px);
      //
      //    }
      //
      //  }
      //}


      .b-quality__imgWrap {
        //@media (max-width: 991px) {
          transform: none !important;

        //}
      }

      &:nth-child(2n+1) {
        .b-quality__col-image {

          @media (min-width: 991px) {
            position: absolute;
            width: 45%;
            right: 0;
            top: 43px;
          }

        }
      }

      &:nth-child(2n) {
        .b-quality__col-image {
          @media (min-width: 991px) {
            position: absolute;
            left: 0;
            top: -40px;
          }
        }
      }
    }

    &-slider {
        background-color: rgb(40, 49, 56);
    }
}

.b-quality__row.b-quality__row-orange {
	background-color: #5487c1;

	.b-heading {
		color: #ffffff;
	}

	.b-quality__text {
		color: #ffffff;
	}

}


.b-quality__row.b-quality__row_dark-grey {
	background-color: #949494;

	.b-heading {
		color: #ffffff;
	}

	.b-quality__text {
		color: #ffffff;
	}

}

.b-quality__col {
    width: 50%;
    height: auto;

    @media (max-width: 991px) {
        width: 100% !important;
        margin-bottom: 20px;

        & + .b-quality__col-text {
            order: -1;
        }
    }
}

.b-quality__imgWrap {
    max-height: 450px;

    overflow: hidden;
  transform: translateY(-75px);
}

.b-quality__img {
    width: 100%;
    height: auto;

  @media (min-width: 425px) {
    height: 450px;
    object-fit: cover;
  }


  @media (min-width: 991px) {
    width: 100%;
    height: auto;
  }
}

.b-qualContacts__icon {
    width: 142px;
    height: 142px;
    margin-right: 30px;

    flex: 1 0 auto;

    background-image: url("../images/teee/contacts-icon.png");
    background-repeat: no-repeat;
    background-size: contain;

    @media (max-width: 767px) {
        display: none;
    }
}

.b-qualContacts__wrap {
    display: flex;
    justify-content: space-between;
    //flex-direction: column;
    //align-items: stretch;
    flex-wrap: wrap;

}

.b-qualContacts__text {
    display: flex;
    justify-content: space-between;
    width: 100%;

    font-size: 18px;

    @media (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
}

.b-qualContacts__item {
    font-size: 24px;
    font-weight: 700;
    color: rgb(40, 49, 56);
    text-decoration: none;

    &:hover {
      color: #5487c1;
    }

    @media (max-width: 767px) {
        font-size: 20px;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
    }
}

.qualSlider__slide {
    height: auto;
    padding-left: 120px;
	padding-right: 120px;
    padding-top: 20px;

    position: relative;

    &::before {
        content: "";
        display: block;

        width: 60px;
        height: 60px;

        position: absolute;
        top: 10px;
        left: 0;

        background-image: url("../images/slider/qual-slider-icon.png");
        background-size: 100% auto;
        background-repeat: no-repeat;

        @media (max-width: 767px) {
            //display: none;
			width: 45px;
			height: 45px;
        }

		@media (max-width: 567px) {
			//display: none;
			width: 25px;
			height: 25px;
		}
    }


	.qualSlider__text {


		&::after {
			content: "";
			display: block;

			width: 60px;
			height: 60px;

			position: absolute;
			//top: 10px;
			right: 22px;
			bottom: 80px;

			background-image: url("../images/slider/qual-slider-icon-2.png");
			background-size: 100% auto;
			background-repeat: no-repeat;

			@media (max-width: 767px) {
				//display: none;
				width: 45px;
				height: 45px;
			}

			@media (max-width: 567px) {
				//display: none;
				width: 25px;
				height: 25px;
			}
		}
	}



    @media (max-width: 767px) {
        padding-top: 0;
        //padding-left: 0;
		padding-left: 80px;
		padding-right: 80px;
    }

	@media (max-width: 567px) {
		padding-left: 40px;
		padding-right: 40px;
	}
}

.qualSlider {
    .slick-arrow {
        width: 35px;
        height: 35px;

        background-color: rgb(84, 135, 193);
    
        border-radius: 3px;
        transition: .3s;

        &:hover {
            background-color: rgb(60, 86, 131);
        }

        &::before {
            display: none;
        }

        &::after {
            content: "";
            display: block;

            width: 18px;
            height: 12px;

            position: absolute;

            background-image: url("../images/qual-arrow.svg");
            background-repeat: no-repeat;
        }
    }

    .slick-next {
        top: auto;
        right: 20px;
        bottom: -10px;
        z-index: 100;

        &::after {
            top: 50%;
            right: 50%;
            transform: translate(70%, -50%) rotate(90deg);
        }
    }

    .slick-prev {
        top: auto;
        right: 70px;
        bottom: -10px;
        left: auto;
        z-index: 100;

        &::after {
            top: 50%;
            right: 50%;
            transform: translate(35%, -40%) rotate(-90deg);
        }
    }

    @media (max-width: 767px) {
        padding-bottom: 70px;
    }
}

.qualSlider__text {
    color: #fff;
}

.qualSlider__user {
    margin-top: 40px;

    display: flex;
    align-items: center;

    @media (max-width: 767px) {
        margin-top: 20px;
    }
}

.qualSlider__avatar {
    margin-right: 20px;
}

.qualSlider__userText {
    display: flex;
    justify-content: space-around;
    flex-direction: column;

    color: #fff;
}

.qualSlider__name {
    font-size: 1.2em;
    color: rgb(84, 135, 193);
}

