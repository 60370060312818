a.site-logo {
    @media (min-width: 768px) {
        top: -70px;
    }

    @media (max-width: 767px) {
        margin: 2px 0 0 !important;

        img {
            height: 60px !important;
        }
    }

    @media (max-width: 991px) {
        margin: 3px 0 0 !important;
    }
}

.social-list {
    padding-right: 14px;
}