/*--------------------------------------------------------------
#3.0    features
--------------------------------------------------------------*/
.features-title {
	border: 2px solid $theme-secondary-color;
	padding: 45px 30px;
	border-radius: 5px;

	@include media-query(991px) {
		margin-bottom: 40px;
		padding: 35px 30px;
	}

	@include media-query(991px) {
		padding: 25px 20px;
	}

	h2 {
		font-size: 30px;
		font-size: calc-rem-value(30);
		margin: 0 0 0.9em;

		@include media-query(1199px) {
			font-size: 25px;
			font-size: calc-rem-value(25);
		}

		@include media-query(767px) {
			font-size: 20px;
			font-size: calc-rem-value(20);
		}
	}

	p {
		margin: 0;
	}
}

.feature-grid {
	text-align: center;

	@include media-query(767px) {
		//margin-bottom: 35px;
		//padding-bottom: 35px;
		//border-bottom: 1px solid #e6e3e3;
	}

	.icon {
		background-color: $theme-secondary-color;
		width: 90px;
		height: 90px;
		line-height: 90px;
		text-align: center;
		margin: 0 auto 35px;
		border-radius: 50%;

		@include media-query(1199px) {
			width: 75px;
			height: 75px;
			line-height: 75px;
		}

		@include media-query(767px) {
			width: 60px;
			height: 60px;
			line-height: 60px;
			margin: 0 auto 25px;
		}
	}

	.icon .fi:before {
		font-size: 50px;
		color: $black;

		@include media-query(1199px) {
			font-size: 40px;
		}

		@include media-query(767px) {
			font-size: 30px;
		}
	}

	h3 {
		font-size: 20px;
		font-size: calc-rem-value(20);
		margin: 0 0 0.5em;

		@include media-query(1199px) {
			font-size: 17px;
			font-size: calc-rem-value(17);
		}
	}

	p {
		margin-bottom:1.7em;

		@include media-query(767px) {
			margin-bottom: 1.4em;
		}
	}

	.more {
		color: $theme-secondary-color;
		font-weight: 600;
	}
}

.features .row > .col:last-child .feature-grid {
	border: 0;
	padding: 0;
	margin: 0;
}

.features-grid-s5 {
	display: flex;
	width: 100%;
	align-items: stretch;

	& > * {
		padding: 15px;

		@media (min-width: 1200px) {
			flex: 0 0 20%;
		}
	}

	@include media-query($medium) {
		flex-wrap: wrap;
		justify-content: center;

		& > * {
			flex: 1 1 240px;
		}
	}
}


/*--------------------------------------------------------------
#3.1    services-s3
--------------------------------------------------------------*/
.services-s3 {
	background-color: #e8f0f7;
	padding-bottom: 70px;

	@include media-query(991px) {
		padding-bottom: 50px;
	}

	@include media-query(767px) {
		padding-bottom: 40px;
	}
}

.services-s3-grids {
	overflow: hidden;
	margin: 0 -15px;

	.grid {
		width: 33.33%;
		float: left;
		padding: 0 15px 30px;
		position: relative;

		@include media-query(991px) {
			width: 50%;
		}

		@include media-query(600px) {
			width: 100%;
			float: none;
		}
	}

	.grid:hover .details {
		background-color: darken($theme-secondary-color, 5%);
	}

	.grid:hover h3 a {
		color: $white;
	}

	.grid .inner {
		position: relative;
		overflow: hidden;
	}

	.grid .details {
		background-color: $theme-secondary-color;
		width: 100%;
		position: absolute;
		left: 0;
		bottom: 0;
		border-radius: 5px;
		@include transition-time(0.3s);
	}

	.grid img {
		border-radius: 5px;
	}

	.grid h3 {
		font-size: 18px;
		font-size: calc-rem-value(18);
		font-weight: normal;
		margin: 0;

		@include media-query(1199px) {
			font-size: 15px;
			font-size: calc-rem-value(15);
		}
	}

	.grid h3 a {
		display: block;
		color: $theme-primary-color;
		padding: 12px 18px;
		@include transition-time(0.3s);
	}
}


/*--------------------------------------------------------------
#3.2    pricing
--------------------------------------------------------------*/
.pricing {
	text-align: center;

	.pricing-tab {
		list-style-type: none;
		overflow: hidden;
		display: inline-block;
		margin-bottom: 50px;
		border-radius: 5px;
	}

	.pricing-tab li {
		float: left;
	}

	.pricing-tab li a {
		display: block;
		font-size: 16px;
		font-size: calc-rem-value(16);
		font-weight: bold;
		color: $theme-secondary-color;
		border: 2px solid $theme-secondary-color;
		padding: 15px 80px;

		@include media-query(1199px) {
			font-size: 14px;
			font-size: calc-rem-value(14);
			border: 2px solid $theme-secondary-color;
			padding: 10px 50px;
		}

		@include media-query(767px) {
			padding: 5px 30px;
		}
	}

	.pricing-tab li.active a {
		background-color: $theme-secondary-color;
		color: $theme-primary-color;
	}

	.pricing-grids {
		overflow: hidden;
		margin: 0 -15px;

		.pricing-grid {
			width: 33.33%;
			float: left;
			text-align: center;
			padding: 0 15px 15px;

			@include media-query(991px) {
				width: 50%;
			}

			@include media-query(767px) {
				width: 100%;
				float: none;
			}
		}

		.pricing-details {
			box-shadow: 0 0 30px transparentize($black, 0.8);
			border-radius: 0 0 5px 5px;
		}

		.pricing-header {
			background-color: $theme-primary-color;
			padding: 40px 0;
			border-radius: 5px 5px 0 0;
			position: relative;
			overflow: hidden;

			@include media-query(1199px) {
				padding: 30px 0;
			}

			h3 {
				font-size: 24px;
				font-size: calc-rem-value(24);
				color: $theme-secondary-color;
				margin: 0;

				@include media-query(1199px) {
					font-size: 20px;
					font-size: calc-rem-value(20);
				}

				@include media-query(991px) {
					font-size: 17px;
					font-size: calc-rem-value(17);
				}
			}

			.price {
				font-family: $heading-font;
				font-size: 48px;
				font-size: calc-rem-value(48);
				font-weight: bold;
				color: $white;
				position: relative;

				@include media-query(1199px) {
					font-size: 40px;
					font-size: calc-rem-value(40);
				}

				@include media-query(991px) {
					font-size: 35px;
					font-size: calc-rem-value(35);
				}
			}

			.price span {
				font-size: 28px;
				font-size: calc-rem-value(28);
				position: relative;
				top: -13px;

				@include media-query(991px) {
					font-size: 18px;
					font-size: calc-rem-value(18);
				}
			}

			.sing-up {
				background-color: $theme-secondary-color;
				width: 165px;
				height: 40px;
				line-height: 40px;
				font-size: 16px;
				font-size: calc-rem-value(16);
				font-weight: 600;
				color: $theme-primary-color;
				display: inline-block;
				border-radius: 5px;

				@include media-query(1199px) {
					width: 125px;
					height: 38px;
					line-height: 38px;
					font-size: 14px;
					font-size: calc-rem-value(14);
				}

				@include media-query(991px) {
					width: 110px;
					height: 35px;
					line-height: 35px;
				}
			}

			.off {
				background-color: #fd3e03;
				color: $white;
				display: block;
				padding: 0 25px;
				position: absolute;
				right: -32px;
			    top: -24px;
				-webkit-transform: rotate(45deg);
				transform: rotate(45deg);
				-webkit-transform-origin: left bottom;
				transform-origin: left bottom;
			}
		}

		.pricing-body {
			padding: 40px 15px;

			ul {
				list-style-type: none;
			}

			ul li {
				color: $text-color;
			}

			ul li i {
				color: $theme-secondary-color;
			}

			ul > li + li {
				margin-top: 17px;

				@include media-query(991px) {
					margin-top: 14px;
				}
			}
		}

		.pricing-footer {
			border-top: 1px solid #d9d9d9;
			padding: 19px 0;

			@include media-query(1199px) {
				padding: 14px 0;
			}

			a {
				font-size: 15px;
				font-size: calc-rem-value(15);
				color: $theme-primary-color;

				span {
					font-size: 18px;
					font-size: calc-rem-value(18);
					font-weight: 600;
				}
			}

			a:hover {
				color: $theme-secondary-color;
			}
		}
	}
}

