/*--------------------------------------------------------------
# blog with sidebar page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#8.0	blog-with-sidebar
--------------------------------------------------------------*/
.blog-with-sidebar {
	.news-grids {
		padding-top: 0;
	}

	.news-grids .grid {
		width: 50%;
		margin-bottom: 30px;

		@include media-query(580px) {
			width: 100%;
		}
	}

	.news-grids > .grid:last-child {
		margin-top: 0;
	}

	.pagination-wrapper {
		margin-top: 50px;

		@include media-query(991px) {
			margin-top: 30px;
		}

		@include media-query(767px) {
			margin-top: 20px;
		}
	}

}