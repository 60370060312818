/*--------------------------------------------------------------
	shop grid page
--------------------------------------------------------------*/
/*--------------------------------------------------------------
#10.0	products-section
--------------------------------------------------------------*/
.shop {
	.products-grids > .col {
		margin-bottom: 30px;
		
		@include media-query(500px) {
			width: 100%;
		}
	}
}