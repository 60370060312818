img,
a {
    min-height: auto;
}

.b-heading {
    margin-bottom: 3rem;
    padding-bottom: 20px;

    position: relative;

    font-size: 2rem;
    color: #283128;
    // text-transform: uppercase;

    &::after {
        content: "";
        display: block;

        width: 33px;
        height: 5px;

        position: absolute;
        bottom: 0;
        left: 0;

        background-color: #5487c1;
        border-radius: 3px;
    }

    &-form {
        text-transform: none;
    }

    &-main {
        margin-bottom: 40px;
        padding-bottom: 0;

        text-align: center;

        &::after {
            display: none;
        }
    }

    &-qual {
        text-transform: none;
    }

    &-slider {
        text-align: center;
        color: #fff;
        text-transform: none;

        &::after {
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &-team {
        &::after {
            display: none !important;
        }
    }

    &-range {
        &::before {
            display: none;
        }
    }

    @media (max-width: 767px) {
        font-size: 20px;
    }

    @media (min-width: 1600px) {
        font-size: 30px;
    }
}

.b-features {
    font-size: 18px;
    line-height: 1.2;
    font-weight: 500;

    @media (max-width: 767px) {
        font-size: 14px;
    }

    @media (min-width: 1600px) {
        font-size: 24px;
    }
}

.b-features__row {
    display: flex;
    justify-content: space-between;

    &:nth-last-child(2n-1) {
        background-color: #f6f6f6;
    }

    &-reverse {
        & > *:nth-child(1) {
            order: 1;
		//	dsds
        }

        & > *:nth-child(2) {
            order: -1;
        }
    }

    @media (max-width: 991px) {
        flex-direction: column;
    }
}

.b-features__col {
    display: flex;
    flex-direction: column;
    align-items: stretch;

    width: 50%;

    &-text {
        padding: 85px 50px 85px 75px;

        @media (min-width: 1600px) {
            & > p {
                max-width: 800px;
            }            
        }
    }

    &-img {
        min-height: 400px;

        object-fit: cover;

        background-color: #5487c1;
    }
    
    &-1 {
        background-image: url("../images/teee/z-index-1.jpg");
        background-position: center;
        background-size: cover;
    }

    &-2 {
        background-image: url("../images/teee/z-index-2.jpg");
        background-position: center;
        background-size: cover;
    }

    &-3 {
        background-image: url("../images/teee/z-index-3.jpg");
        background-position: center;
        background-size: cover;
    }

    &-4 {
        background-image: url("../images/teee/z-index-4.jpg");
        background-position: center;
        background-size: cover;
    }

    &-5 {
        background-image: url("../images/teee/z-index-5.jpg");
        background-position: center;
        background-size: cover;
    }

    @media (max-width: 991px) {
        width: 100%;

        &-text {
            width: 750px;
            margin: 0 auto;
            padding: 90px 0 65px;

            order: -1;

            & + .b-features__col-img {
                order: 1;
            }
        }
    }

    @media (max-width: 859px) {
        &-text {
            width: 600px;    
        }
    }

    @media (max-width: 767px) {
        &-text {
            width: 100%;
            padding: 75px 20px 60px;
        }

        &-img {
            min-height: 300px;
        }
    }
}

.b-features__heading {
    margin-bottom: 18px;
    padding-bottom: 20px;

    position: relative;

    font-size: 2rem;
    color: #283128;
    // text-transform: uppercase;

    &::after {
        content: "";
        display: block;

        width: 33px;
        height: 5px;

        position: absolute;
        bottom: 0px;
        left: 0;

        background-color: #5487c1;
        border-radius: 3px;
    }

    &-form {
        text-transform: none;
    }

    @media (max-width: 767px) {
        font-size: 20px;
    }

    @media (min-width: 1600px) {
        font-size: 30px;
    }
}

.b-features__col-text {
    .learn-more-btn {
        font-size: 18px;
        font-weight: bold;
        color: #5487c1;
        margin-top: 15px;
        padding-left: 5px;
    }
}

.b-features__text {
    margin: 14px 0 0;
    padding-right: 30px;
    padding-left: 5px;

    font-size: 18px;

    color: #424242;

    @media (max-width: 1199px) {
        margin: 14px 0 0;
        padding: 0;
    }



    
}

.b-features__link {
    max-height: 140px;

    overflow: hidden;
}

.b-features__figureWrap {
    display: flex;
    flex-wrap: wrap;
	//justify-content: space-between;


	@media (min-width: 1374px) {
		flex-wrap: nowrap;
	}



	//@include media-query()

    width: 100%;
    margin-top: 30px;
    //margin-left: 20px;

    @media (max-width: 1199px) {
        margin-left: 0;
    }
}

.b-features__figure {
    //width: 170px;

    align-items: stretch;

    display: flex;
    //justify-content: space-between;
    flex-direction: column;

	width: 100%;

	@media (min-width: 426px) {
		width: 48%;
		margin-right: 15px;
		//padding-right: 20px;

	}

	@media (min-width: 1368px) {
		width: 23%;
	}

	@media (min-width: 426px) and (max-width: 1368px) {
		&:nth-child(2) {
			padding-right: 0;
		}
	}


    &:last-child {
        margin-right: 0;
		padding-right: 0;
	}

    //@media (max-width: 479px) {
    //    width: 150px;
    //}

	img {
		max-height: 140px;
		object-fit: cover;
	}
}

.b-features__imgHead {
    margin-top: 16px;
    padding: 5px;

    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    color: #595959;

    @media (max-width: 767px) {
        margin-top: 0;
        margin-bottom: 15px;
    }
}

.b-features__img {
    width: 100%;
    height: auto;
}

.b-features__item {
    margin: 7px 0;

    font-size: 1.5em;
    color: #5487c1;
    vertical-align: bottom;

    @media (max-width: 767px) {
        margin: 5px 0 !important;

        &:last-child {
            margin-bottom: 0 !important;
        }
    }
}

.b-features__itemText {
    font-size: .7em;
    color: #6a6a6a;

    vertical-align: middle;
}

.b-features__listWrap {
    display: flex;

    width: 100%;
    margin-top: 25px;

    & > * {
        width: 48%;
    }

    @media (max-width: 1199px) {
        flex-direction: column;

        & > * {
            width: 100%;
            min-height: 115px;
        }
    }

    @media (max-width: 767px) {
        margin-top: 0;
    }
}

.b-features__listIcon {
    background: url("../images/teee/list-icon.png") no-repeat;
    background-position: center;
    background-size: contain;

    @media (max-width: 1199px) {
        display: none;

        background-position: left top;
    }
}

//.b-features__list {
//    margin-top: 15px;
//    margin-left: 20px;
//
//    @media (max-width: 1199px) {
//        margin-left: 0;
//    }
//}
//
//.b-features__item {
//    margin-bottom: 10px;
//
//    &:last-child {
//        margin-bottom: 0;
//    }
//}



.b-features__list {
    //list-style: disc inside;
    list-style: none;
    margin-top: 15px;
    //margin-left: 20px;

    @media (max-width: 1199px) {
        margin-left: 0;
    }
}

.b-features__item {
    line-height: 1.8em;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;

    &:before {
        position: absolute;
        content: "\2022";
        font-weight: bold;
        color: #5487c1;
        display: inline-block;
        //margin-left: -1em;
        //width: 1em;
        font-size: 35px;

        left: 0;
        top: 2px;
    }

    &:last-child {
        margin-bottom: 0;
    }
}


.navigation {
    z-index: 9999;
}

#lightbox {
    //overflow: hidden;
}

#lightboxOverlay {
    //overflow: hidden;
}

.lb-disable-scrolling {
    position: absolute !important;
}

.lb-data .lb-close {
	//position: absolute;
	//margin: 0 auto;
	//left: 0;
	//top: -38px;
	//right: -50%;
	//transform: translateX(-100%);
	margin-bottom: 5px;
}



.wpcf7-response-output {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 30px;
	margin: 0 auto;
}

.wpcf7-mail-sent-ok {
	display: flex;
	justify-content: center;
	border-radius: 3px;
	width: 80%;
	background-color: #398f14;
	color: white;
	margin: 0 auto;
}

.wpcf7-validation-errors {
	display: flex;
	justify-content: center;
	border-radius: 3px;
	width: 80%;
	background-color: #f7e700;
	color: black;
	margin: 0px auto;
}
