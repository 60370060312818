/*--------------------------------------------------------------
#0.5	footer
--------------------------------------------------------------*/
.site-footer {
	.upper-footer {
		background-color: $theme-primary-color;
		padding: 100px 0;

		@include media-query(991px) {
			padding: 80px 0 35px;
		}

		@include media-query(767px) {
			padding: 70px 0 30px;
		}

		ul {
			list-style: none;
		}

		ul,
		li,
		p,
		a {
			color: $white;
		}

		a:hover {
			color: $theme-secondary-color;
		}
	}

	.upper-footer .row > .col {
		@include media-query(991px) {
			margin-bottom: 55px;
		}

		@include media-query(767px) {
			margin-bottom: 40px;
		}
	}

	.widget > h3,
	.widget .footer-logo {
		font-size: 22px;
		font-size: cacl-rem-value(24);
		color: $white;
		text-transform: uppercase;
		margin-top: 0;
		margin-bottom: 2em;
		padding-bottom: 0.5em;
		position: relative;

		@include media-query(991px) {
			font-size: 20px;
			font-size: cacl-rem-value(20);
		}

		@include media-query(767px) {
			font-size: 16px;
			font-size: cacl-rem-value(16);
		}

		&:before {
			content: "";
			background-color: $theme-secondary-color;
			width: 28px;
			height: 3px;
			position: absolute;
			left: 0;
			bottom: 0;
			border-radius: 5px;
		}
	}

	/*** about widget ***/
	.about-widget .contact-info {
		margin-top: -20px;
		@include widther(1200px) {
			// padding-right: 45px;
		}

		@include media-query(767px) {
			margin-top: -10px;
		}
	}

	.about-widget .footer-logo:before {
		display: none;
	}

	.about-widget ul li {
		position: relative;
		padding-left: 38px;
		line-height: 1.78em;
	}

	.about-widget ul li i {
		font-size: 24px;
		color: $theme-secondary-color;
		position: absolute;
		left: 0;
		top: 0;
	}

	.about-widget ul > li + li {
		margin-top: 28px;

		@include media-query(767px) {
			margin-top: 10px;
		}
	}

	@include media-query(767px) {
		.about-widget img {
			max-width: 160px;
		}
	}

	/*** service-links-widget ***/
	.service-links-widget {
		overflow: hidden;
	}

	.service-links-widget ul li {
		position: relative;
		padding-left: 20px;

		&:before {
			font-family: "FontAwesome";
			content: "\f105";
			color: $theme-secondary-color;
			position: absolute;
			left: 0;
			top: -3px;
		}
	}

	.service-links-widget ul li + li {
		margin-top: 25px;

		@include media-query(767px) {
			margin-top: 10px;
		}
	}


	/*** quick-links-widget ***/
	.quick-links-widget {
		overflow: hidden;
		margin-top: -16px;

		@include widther(992px) {
			margin-top: 48px;
		}

		@include widther(1200px) {
			//padding-right: 45px;
			margin-top: -16px;
		}
	}

	.quick-links-widget ul li + li {
		margin-top: 15px;

		@include media-query(767px) {
			margin-top: 10px;
		}
	}

	.quick-links-widget ul {
		width: 50%;
		float: left;
	}


	/*** twitter-feed-widget ***/
	.twitter-feed-widget ul li + li {
		padding-top: 14px;
		margin-top: 14px;
		border-top: 1px solid #494949;
	}

	.twitter-feed-widget p {
		color: #ccc;
	}

	.twitter-feed-widget i {
		color: #198cfa;
		display: inline-block;
		padding-right: 5px;
	}

	/*** copyright-info ***/
	.copyright-info {
		background-color: #000204;
		padding: 15px 0;
		text-align: center;

		p {
			color: #808790;
			margin: 0;

			@include media-query(767px) {
				font-size: 13px;
				font-size: cacl-rem-value(13);
			}
		}

		a {
			color: $theme-secondary-color;
		}
	}
}




/*--------------------------------------------------------------
#0.6	footer flex
--------------------------------------------------------------*/

.site-footer__wrap {
	position: relative;
	display: flex;
	width: 100%;
	justify-content: space-between;
	
	@media screen and (max-width: 991px) {
		flex-wrap: wrap;
	}
	
	@media screen and (max-width: 550px) {
		.widget > h3 {
			text-align: left;
			
			&::before {
				//width: auto;
				//left: 45%;
				//right: 45%;
			}
		}
	}
}

.site-footer__block {
	flex: 1 1;
}

.site-footer__logo {
	flex: 0 0 15%;


	
	@media screen and (max-width: 991px) {
		flex: 0 0 40%;
	}
	
	@media screen and (max-width: 550px) {
		flex: 1 1 100%;
		text-align: center;
	}
	
	a {
		display: inline-block;
		text-decoration: none;
		
		@media screen and (max-width: 550px) {
			max-width: 300px;
			margin: auto;
		}

		img {
			max-height: 136px;
		}
	}
}

.site-footer__nav {
	padding-left: 40px;
	padding-right: 40px;

	ul {
		flex: 0 0 33%;
	}
	
	@media screen and (max-width: 991px) {
		flex: 0 0 50%;
		padding-left: 0;
		padding-right: 0;
	}

	@media screen and (max-width: 550px) {
		flex: 1 1 100%;
		margin-top: 50px;

		ul {
			text-align: left;
		}

	}

	& > * {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
}

.site-footer__contact {
	flex: 0 0 50%;
	
	@media screen and (max-width: 991px) {
		flex: 1 1 100%;
		margin-top: 50px;
	}
}

.site-footer__cols {
	display: flex;
	width: 100%;
	justify-content: space-between;
	
	& > * {
		flex: 0 0 50%;
		
		&:first-child:not(:last-child) {
			li {
				padding-right: 15px;
			}
		}
	}
}

.site-footer__cols_sm {
	@media screen and (max-width: 550px) {
		& > *:first-child:not(:last-child) {
			//text-align: right;
			text-align: left;
		}
		
		&:last-child:not(:first-child) {
			li {
				padding-left: .9375rem;
			}
		}
	}
}

.site-footer__cols_st {
	@media screen and (max-width: 550px) {
		display: block;
		//max-width: 320px;
		margin: auto;
	}
}
.site-footer .about-widget.site-footer__cols_st .contact-info {
	@media screen and (max-width: 550px) {
		&:not(:first-of-type) {
			margin-top: .625rem;
		}
	}
}

.site-footer__wrap .social-list_footer {
	@media screen and (max-width: 550px) {
		justify-content: center;
		text-align: center;
	}
}

.site-footer__socials {
	margin: 0!important;
	padding: 0!important;
	
	@media screen and (max-width: 991px) {
		position: static!important;
		margin-bottom: 100px!important;
	}
	
	@media screen and (max-width: 550px) {
		margin-bottom: 50px!important;
	}

	.fa {
		position: relative!important;
	}

	.social-list.social-list_footer {
		@media screen and (max-width: 991px) {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 0;
			justify-content: center;
			text-align: center;
		}
	}
}
