.b-index h2,
.b-index h1,
.b-index h3 {
    text-transform: none;
}

.certification {
    background-image: url("../images/teee/certif-section.jpg") !important;
    background-position: 50% -58.9333px;
    background-size: cover;
	//padding: 0;

	@include media-query(425px) {
		padding: 0;
	}
}

.index__vidLink {
    padding-top: 5%;

    &::before {
        //top: calc(50% - 40px);
    }
}

.index__imgWrap {

    &::after {
        top: -20px;
    }

	padding: 0;

	@media (min-width: 769px) {
		//padding-top: 4.5%;
	}

}

.factorySlider__overLink {
    opacity: 0;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 11;

    color: #fff;

    transition: .3s;

    display: flex;
    justify-content: center;
    align-items: center;

    .factorySlider__heading {
        color: #fff;
		padding: 15px;
    }
}

.factorySlider__item:hover .factorySlider__overLink {
    opacity: 1;
}

.factorySlider__imgWrap {
    height: 360px;

    img {
        height: 100%;
    }
}

.index__text {
    padding-bottom: 30px;
	font-size: 18px;

    &-team {
        color: #fff;
		font-size: 18px;

	}
}

.b-heading-aboutUs {
    text-align: center;

    &::after {
        left: 50%;
        transform: translateX(-50%);
    }
}

.b-index__aboutUsText {
    font-size: 1.3rem;
    text-align: center;
    color: #283128;
}

.index__iconList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    //align-items: flex-start;

    margin-top: 20px;
}

.index__iconList h3 {
    font-size: 1.1rem;
    color: #5487c1;
}

.index__iconItem {


	margin-bottom: 35px;
	padding-bottom: 35px;

    padding: 0 !important;

    display: flex;
    flex-direction: column;

    min-width: 0;
	width: 33%;

	.details {
		width: 170px;
		margin: 0 auto;
	}


	@media (min-width: 1200px) {
		max-width: 185px !important;
	}

    @media (max-width: 991px) {
        //max-width: 200px !important;
    }

	@media (min-width: 230px) and (max-width: 429px) {
		//border-bottom: 1px solid #e6e3e3;
		//&:nth-child(-n+4) {
		//	border-bottom: 1px solid #e6e3e3;
		//}

		&:last-child {
			border-bottom: none;
		}
	}


	@media (min-width: 430px) and (max-width: 749px) {
		&:nth-child(-n+4) {
			//border-bottom: 1px solid #e6e3e3;
		}
	}


	@media (min-width: 630px) {
		&:nth-child(-n+3) {
			//border-bottom: 1px solid #e6e3e3;
		}
	}

	@media (min-width: 976px) {
		&:nth-child(-n+3) {
			//border-bottom: none;
		}
	}


	@media (max-width: 749px) {
		&:nth-child(-n+4) {
			//border-bottom: 1px solid #e6e3e3;
		}
	}

	@media (max-width: 509px) {
		&:nth-child(-n+5) {
			//border-bottom: 1px solid #e6e3e3;
		}
	}

	@media (min-width: 1200px) {
		&:nth-child(-n+7) {
			border-bottom: none;
		}
	}




}

.b-index__lineText {
    font-weight: 700;
    text-decoration: underline;
}

.index__iconItem .index__icon {
    margin-bottom: 20px;
}

.feature-grid .icon {
    background-color: #fff;
}

.index__icon {
    position: relative;

    &::after {
        content: "";
        display: block;

        width: 80px;
        height: 80px;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        background-repeat: no-repeat;
        background-size: 100%;

    }

    &-1 {
        &::after {
            background-image: url("../images/teee/industry.svg");
        }
    }

    &-2 {
        &::after {
            background-image: url("../images/teee/brain.svg");
        }
    }

    &-3 {
        &::after {
            background-image: url("../images/teee/cargo-ship.svg");
        }
    }

    &-4 {
        &::after {
            background-image: url("../images/teee/tower.svg");
        }
    }

    &-5 {
        &::after {
            background-image: url("../images/teee/ladder.svg");
        }
    }

    &-6 {
        &::after {

            background-image: url("../images/teee/support.svg");
        }
    }

}

.owl-carousel .owl-item img {
	object-fit: cover;
}

.index-our-team {
	.row {
		@media (min-width: 574px) {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
	}

	.col-md-7, .col-md-5 {
		@media (min-width: 574px) {
			width: 49%;
		}
	}

	.theme-btn_light.read-more {
		padding: 6px 20px;
		min-height: auto;
	}

	.read-more {
		display: none;

		@media (min-width: 574px) {
			display: inline-block;
		}

	}

	.offer-more-btn-pos {
		display: inline-block;

		padding-left: 15px;
		margin-top: 20px;


		a {
			display: inline-block;
		}

		@media (min-width: 574px) {
			display: none;
		}


	}

}

.test-hover {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	//top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	//opacity: 0;
	z-index: 99;
	background-color: rgba(255, 96, 32, .9);

	transition: all .3s;

	.factorySlider__heading {
		color: #fff;
		padding: 15px;
		margin: 0;
		text-align: center;


	}

	@media (min-width: 768px) {
		//opacity: 0;
		//top: 0;
		//background-color: transparent;
	}

}


.no-touchevents {
	.test-hover {
		opacity: 0;
		top: 0;
		background-color: transparent;
	}
}

.touchevents .square-hover-effect {
	display: none;
}

//.square-hover-effect {
//	//display: none;
//
//	@media (min-width: 768px) {
//		display: inline-block;
//	}
//}



.square-hover-effect-parent {



	&:hover .test-hover {
		opacity: 1;
	}


	&:before {
		//content: 'Details';
		text-align: center;
		position: absolute;
		//height: 50px;
		width: 100%;
		left: 0;
		bottom: 0;
		background-color: rgba(255,96,32,.9);
		z-index: 99;
		padding: 11px 15px;
		font-size: 19px;
	}


	&.square-hover-effect-active {
		&:before {
			content: none;
		}
	}

	&:hover:before {
		//display: none;
	}
}

.close-navbar {
	padding: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}


.contact-2-item {
	display: flex;
	flex-wrap: wrap;
	//text-align: center;
	margin-bottom: 35px;

	@media (min-width: 768px) {
		margin-bottom: 0;
		justify-content: center;

	}


	@media (min-width: 768px) and (max-width: 991px) {
		&:nth-child(3) {
			margin-top: 15px;
		}
	}

	.name-image {
		position: relative;
		//width: 100px;
		//height: 100px;
		//background-color: #f0ad4e;
		//background-image: url("http://teee.comradeserver.com/wp-content/uploads/2018/09/1.png");
		background-position: center;
		background-size: cover;
		//margin-bottom: 30px;
		//width: 100%;
		//width: 100px;
		margin-right: 15px;

		img {
			border-radius: 50%;
			width: 100px;
			height: 100px;
			object-fit: cover;
		}


	}

	.name {
		//position: absolute;
		//left: 0;
		font-size: 18px;
		font-weight: bold;
		//right: 0;
		//bottom: -27px;
	}

	a {
		color: #000;
		font-size: 17px;
		&:hover {
			text-decoration: underline;
		}
	}

	.country {
		font-size: 20px;
		width: 100%;
		margin-bottom: 13px;
		border-bottom: 1px solid #c4c4c4;
	}

	.phone {
		width: 100%;
	}

	.email {
		width: 100%;
	}

	.address {
		width: 100%;
		color: #000;
		font-size: 1.0625rem;
		margin: 0;
		line-height: 20px;
		margin-bottom: 5px;
	}


	.ctn-1 {
		display: flex;
		flex-wrap: wrap;
		text-align: left;
	}

	.item-inner {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
	}
}

.form-resume-wrap {
	.row {
		//margin: 0;
	}
}

.fc-sec {
	margin-top: 90px;
}

input.input-upload-resume {
	display: none;
}

.upload-file-1 {


	&-inner {
		border: 2px dashed #d1d7e0;
		border-radius: 10px;
		text-align: center;
		padding: 30px;
		height: 150px;

		display: flex;
		align-items: center;
		justify-content: center;

		.upload-btn {
			margin: 0;
			display: inline-block;
			padding: 10px;
			//background: #ccc;
			cursor: pointer;
			border-radius: 5px;
			//border: 1px solid #ccc;

			&:hover {
				background: #ddd;
			}
		}

	}

	#drop-area.highlight {
		border-color: #5487c1;
	}

}

.b-index.home .hero-slider {
	.theme-btn {
		&:hover {
			background-color: #fff;
			color: #5487c1;
			border-color: #5487c1;
		}
	}
}


.b-index.home {
	.offer-section.offer-section_dark.index-our-team {
		padding-bottom: 6.25rem !important;
	}
}

.b-index .blog-intro__text {
	font-size: 18px;
}

.b-index.news {
	.news-grids {
		margin-top: 45px;
	}
}

.b-blog {

	.blog-single {
		.post-body {
			a {
				color: #5487c1;
			}
			img {
				//padding-right: 15px;
                border: none !important;
                margin: 0 !important;
			}
		}
	}

	.post-body {
		ul {
			//list-style: disc inside;
			list-style: none;
		}

		li {
			line-height: 1.8em;
			position: relative;
			padding-left: 50px;

			&:before {
				position: absolute;
				content: "\2022";
				font-weight: bold;
				color: #5487c1;
				display: inline-block;
				//margin-left: -1em;
				//width: 1em;
				font-size: 35px;

				left: 15px;
				top: 2px;
			}


		}
	}
}

.b-index, .b-blog {
  .get-quote-popup-block {

    .b-blogList__text .b-blogList__heading {
      font-size: 1.125rem;
    }

    .b-down__textLink {
      font-size: 1rem;
      font-weight: 700;
    }

  }

  .modal-title {
    width: 100%;
    margin-bottom: 1.975rem;;

    h3 {
      position: relative;
      text-align: center;

      &:after {
        content: "";
        display: block;
        width: 33px;
        height: 5px;
        position: absolute;
        bottom: -15px;
        left: 0;
        right: 0;
        background-color: #5487c1;
        border-radius: 3px;
        margin: 0 auto;
      }
    }
  }

}


.b-index.downloads {
  .page-description {
    font-size: 18px;
  }

  .b-blogList {
    margin-top: 3rem;
  }

  .b-blogList__text {
    .b-blogList__heading {
      font-size: 18px;
    }
  }

  .b-down__textLink {
    font-size: 1rem;
    font-weight: bold;
  }


	.blog-grid-section {
		background-color: #dedcdc;

		.container {
			display: flex;
			flex-wrap: wrap;

			.b-heading {
				width: 100%;
			}

			.page-description {
				width: 100%;

				@media (min-width: 992px) {
					width: 50%;
					padding-right: 60px;
				}
			}


			.b-blogList {

				width: 100%;
				z-index: 10;

				@media (min-width: 992px) {
					background-color: rgba(255, 255, 255, 0.84);
					width: 50%;
					margin-top: -300px;
					padding: 33px;

				}

			}

		}
	}




}


.b-index {
	.slide-caption {
		p {
			padding-right: 6.875rem;
			font-size: 1.6rem;
			line-height: 1.5em;
			color: #fff;
			animation: fadeInLeftSlow 1.5s 1.4s forwards;
		}

		span {
			color: #5487c1;
		}
	}
}





.b-index.products {
  .b-text {
      .text {
          font-size: 18px;

		  .b-heading.text-center.pagenotfound__header {
			  margin-top: 3rem;
		  }
      }
  }

	.b-text.section-padding_top {
		.b-features__list {
			position: relative;
			padding: 24px 0;
			margin-top: 30px;

			&:before {
				content: '';
				position: absolute;
				left: -100%;
				right: -100%;
				top: 0;
				height: 100%;
				background-image: linear-gradient(90deg,rgba(40,49,56,.9),rgba(40,49,56,.4));
				z-index: -1;
			}

			.b-features__item {
				.b-features__itemText {
					color: #fff;

					strong {
						color: #5487c1;
					}

					span {
                      color: #000;
                      font-weight: bold;
					}
				}
			}
		}
	}

  .b-products.section-padding_top {
    .row {
      display: flex;
      flex-wrap: wrap;
      //justify-content: space-between;
		//justify-content: center;
    }

    .product {
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 1.9rem;

	  @media (min-width: 580px) {
		width: 49.9%;
	  }

	  @media (min-width: 991px) {
		  width: 33.3%;
	  }

    }

  }

	.product-image {
		height: 155px;
	}

}

.b-index.contact {
  .contact-intro__text {
    font-size: 18px;

    a {
      color: #5487c1;
    }
  }

  .contact-pg-section .contact-info {
    ul {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      //margin-bottom: 30px;
	  margin: 0 -15px 30px;
		flex-wrap: wrap;


		@media (min-width: 768px) {
			flex-wrap: nowrap;
		}


    }
    li {
       margin-top: 0 !important;
		margin: 0 15px;
	    width: 100%;
		display: flex;
		margin-bottom: 20px;
		padding-left: 0;

		@media (min-width: 768px) {
			width: 33.33333333%;
			margin-bottom: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

    }
  }

  //.site-footer__wrap {
  //  .contact-info {
  //
  //
  //
  //    //.about-widget ul li i {
  //    //  font-size: 24px;
  //    //  color: $theme-secondary-color;
  //    //  position: absolute;
  //    //  left: -30px;
  //    //  top: 3px;
  //    //}
  //
  //
  //    li {
  //      width: 100% !important;
	//	  display: inline-block;
  //
	//	  i {
	//		  left: -30px;d
	//	  }
  //    }
  //
	//	.social-list.social-list_footer {
	//		i {
	//			left: -16px;
	//		}
	//	}
  //  }
  //}

}

.b-index.careers {
	.page-description {
		p {
			font-size: 18px;
		}

		h2 {
			margin: 40px 0 30px;
		}

		ul {
			//margin-bottom: 50px;
		}

		li a {
			font-size: 18px;
			color: #5487c1;



			&:hover {
				color: #5487c1;
			}
		}
	}
}

.b-index {
	.wpcf7 {
		margin-top: 60px;
	}
}

.b-index.our.team, .b-index.our-team {
	.team-grids .team-grid {
		max-width: 230px;
	}

	.section-padding.offer-section {
		padding-bottom: 6.25rem;

		.offer-text {
			display: flex;
			flex-wrap: wrap;

			p {
				margin-bottom: .9375rem !important;
			}

			width: 100%;

			@media (min-width: 992px) {
				width: auto;
				display: block;
			}

			.offer-pic.ourTeam__imgWrap {
				order: 2;

				@media (min-width: 992px) {
					width: 597px;
					float: right;
					padding-left: 15px !important;
					padding-bottom: 5px !important;
					margin-top: -75px;
				}
			}
		}
	}



}


.b-index.contact {
	.contact-pg-section.section-padding.b-formWrap {
      .col.col-xs-12 {
        .container {
          width: auto;
        }
      }
	}
}

.ch-st1 {
	display: inline-block;
	width: 100%;


	.ch-st1-wrap {
		display: inline-block;
		position: relative;
		padding-left: 35px;
		margin-bottom: 12px;
		cursor: pointer;
		font-size: 22px;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.ch-st1-wrap input {
		position: absolute;
		//opacity: 0;
		cursor: pointer;
		//height: 0;
		//width: 0;
		margin: 0;
		margin-right: 15px;
	}

	.first {
		display: flex;
	}


	.wpcf7-list-item {
		position: relative;
		padding-left: 30px;
		display: flex;
		margin: 0;

		input[type='checkbox'] {
			display: none;
		}

		.wpcf7-list-item-label {

			margin-left: 10px;
			font-size: 16px;
			margin-top: 3px;

			&:after {
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: 0;
				top: 0;
				z-index: 11;
				width: 25px;
				height: 25px;
				border-radius: 4px;

				background-color: #fff;
				border: 2px solid #5487c1;

			}
		}
		input[type='checkbox']:checked ~ .wpcf7-list-item-label {


			&:before {
				content: '';
				position: absolute;
				left: 9px;
				top: 5px;
				width: 7px;
				height: 11px;
				border: solid #333;
				border-width: 0 3px 3px 0;
				-webkit-transform: rotate(45deg);
				-ms-transform: rotate(45deg);
				transform: rotate(45deg);
				z-index: 12;
			}

		}
	}

	.wpcf7-list-item {

		label {
			display: flex;
		}

		a {
			color: #5487c1;
		}
	}
	.post-body a {
		color: #5487c1;
	}
}





.b-index {
  .b-heading, .section-title-s2 h2, .section-title-s5 h2, .section-title-s6 h2, .section-title-s7 h2, .section-title-white h2, .section-title h2 {
    font-family: 'GoodTimesRg-Regular';
    font-style: italic;
    font-size: 2rem;

  @media (min-width: 425px) {
	  font-size: 2.25rem;
  }

  }
}

.b-index.home section.features.section-padding {
  padding-bottom: 0;
}


.b-index.privacy-policy {

	@media (max-width: 991px) {
		.blog-single-content {
			margin-bottom: 0 !important;
		}
	}
}

.b-index.home {
	.features.section-padding.features-st1 {
		background-color: #283138;
		margin-top: 6.25rem;
		padding-bottom: 0;
	}

	.index__iconItem {
		.icon {
			background-color: transparent !important;
		}
	}
}

.b-index.quality {
	.hero .slide .row {
		@media (min-width: 992px) {
			display: inline-block;
			margin-left: 15px;
			margin-top: 180px;
		}
	}

	.b-quality {

      //.b-quality__col-text .b-heading {
		//	font-family: 'GoodTimesRg-Regular';
      //      font-style: italic;
		//	font-size: 50px;d
      //}



		.b-quality__row.b-quality__row-main:first-child {

			background-color: #fff;

			@media (min-width: 992px) {
				margin-top: -200px;
				background-color: #283138;
			}

			.b-quality__col-text {
				background-color: rgba(255, 255, 255, 1);

				@media (min-width: 992px) {
					padding-left: 3.75rem;
					background-color: rgba(255, 255, 255, 0.84);
					transform: translateY(-83px);
				}
			}

			.b-quality__col.b-quality__col-image {
				width: 45%;
			}

		}


		.b-quality__row.b-quality__row-main:nth-child(2) {
			@media (min-width: 992px) {
				margin-top: -90px;
			}


			.b-quality__col-text {
				@media (min-width: 992px) {
					transform: translateY(-60px);
				}
			}

			.b-quality__col-image {
				@media (min-width: 992px) {
					bottom: -40px;
					top: auto;
				}
			}
		}
	}

	.features.section-padding {
		//background-color: #f2f2f2;
      background-color: #283138;
	}


	.index__iconItem {
		.icon {
			background-color: transparent !important;
		}
	}


}

div.wpcf7-validation-errors, div.wpcf7-acceptance-missing {
	margin: 0 auto !important;
}

.owl-nav .owl-prev.disabled,
.owl-nav .owl-next.disabled {
	display: none !important;
}

.b-index, .b-blog {
	.post1 {
		&__image-container {
			width: 120px; // the same width and height as for the <img>
			height: 120px;
			&.compat-object-fit {
				background-size: cover;
				background-position: center center;
				.post__featured-image { // hide image if object fit is not supported - opacity to 0 for the link area
					opacity: 0;
				}
			}
		}
		&__featured-image {
			width: 120px;
			height: 120px;
			object-fit: cover;
		}
	}




	@media screen and (-webkit-min-device-pixel-ratio:0) and (max-width: 375px){
		select,
		textarea,
		input,
		select:focus,
		textarea:focus,
		input:focus {
			font-size: 16px !important;
		}
	}


	input, textarea, select {
		-webkit-appearance: none;
	}


	.wpcf7-list-item-label, .b-form__label.required {
		display: inline-block;
	}

	span.required-smb {
		position: relative;
		//display: none;

		&::after {
			content: "\f069";
			margin-left: .4375rem;
			font-family: FontAwesome;
			font-size: .6rem;
			color: #e2574c;
			right: 0px;
			top: -6px;
			position: relative;
		}
	}


	.b-form__label.required {
		&:after {
			display: none;
		}
	}

	.ch-st1-wrap.required {
		&:after {
			display: none;
		}
	}

	.quote-btn-1 {
		position: fixed;
		//top: 175px;

		top: 0;
		bottom: 0;
		margin: auto;

		right: 20px;
		width: 51px;
		height: 220px;
		background-size: cover;
		display: none;

		cursor: pointer;
		background-image: url(../images/quote-btn1.svg);
		z-index: 9999;
		opacity: .8;

		transition: opacity .4s;

		&:hover {
			opacity: 1;
		}


		@media (min-width: 768px) {
			display: block;
		}
	}

	.get-quote-popup-block {
		width: 80%;
		max-width: 670px;
		margin: auto;
		position: fixed;
		top: 50%;
		transform: translateY(-50%);
		left: 0;
		right: 0;
		background-color: #ffffff;
		padding: 30px;
		border-radius: 3px;
		padding-bottom: 0;

		.b-blogList.b-down__list {
			margin-top: 0;


			.b-down__item:last-child {
				margin-bottom: 0;
			}

			.b-down__item:nth-child(2) {
				margin-bottom: 0;
			}

			.b-blogList__text {
				p:last-child {
					margin-bottom: 0;
				}
			}


		}

	}

	.popup-modal-dismiss {
		position: absolute;
		top: 5px;
		right: 10px;
		cursor: pointer;
		color: #333;
	}

	.mfp-wrap.mfp-ready {
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		position: fixed;
		height: 150%;
        background-color: rgba(0,0,0,.2) !important;
	}

	.mfp-hide {
		display: none;
	}


	.post-body {
		img[width] {
			width: auto;
		}
	}

	.section-padding-2 {
		padding: 2.5em 0;
	}

	.product-more {
		padding-bottom: 0;
		display: flex;
		align-items: center;
		//margin-left: 40px;
		margin-top: -35px;

		margin-left: 0;
		padding-left: 18px;

		.read-more {
			display: flex;
			align-items: center;

			margin: 0 auto;

			@media (min-width:990px) {
				margin: 0;
			}

		}

		@media (max-width:990px) {
			margin-top: 0;
			margin-left: 0;
			padding: 6.25rem 0;
		}
	}


	.empty-prod {
		display: none;

		@media (min-width:990px) {
			display: block;
		}
	}

	.orange-box-2 {
		background-color: #5487c1;

		@media (min-width:990px) {
			margin-top: 30px;
		}

		.text {
			p {
				margin-bottom: 0;
				color: #fff;
			}
		}
	}

	.mt-1-0 {
		margin-top: 0 !important;
	}

}

.b-index .homecontent {
	background-color: #283138;
	padding: 70px 0;

	p {
		margin-top: 0;
		color: #fff;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.b-index .our-name {
	padding: 80px 0;

	.our-name__row {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.our-name__col {
		&:first-child {
			width: calc(40% - 60px);

			@media (max-width: $medium) {
				width: calc(40% - 40px);
			}

			@media (max-width: $l-phones) {
				width: 100%;
			}
		}

		&:last-child {
			width: calc(60% - 60px);

			@media (max-width: $medium) {
				width: calc(60% - 40px);
			}

			@media (max-width: $l-phones) {
				width: 100%;
			}
		}
	}

	.our-name__text {
		p {
			margin-top: 0;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	.our-name__image {
		img {
			@media (max-width: $l-phones) {
				margin-bottom: 60px;
			}
		}
	}
}
