.certification {
	display: flex;
    align-items: flex-end;
	//min-height: 553px;

	@include media-query($phones) {
		//min-height: 100vh;
	}

	&::before {
		/// content: none!important;
		background-color: rgba($white, .3)!important;
	}

	.contact-women {
		left: auto;
		right: 0;
	}
}

.certification__content {
	display: flex;
	padding: 15px;
	align-items: center;
	background-image: linear-gradient(to right, rgba($theme-primary-color, .9), rgba($theme-primary-color, .4));
	z-index: 5;


	@include media-query(425px) {
		padding-bottom: 65px;
		padding-top: 65px;
	}



	@include media-query($l-phones) {
		display: block;
	}

	&, *, p {
		font-size: 1.3rem;
		color: $white!important;
	}

	@include media-query($phones) {
		padding-right: 15px;
	}

	@media (min-width: 769px) {

		padding-left: 30px;
		padding-right: 30px;
	}
}

.certification__txt {
	flex: 1 1 100%;
	padding-right: 30px;

	& > *:last-child { margin-bottom: 0; }

	@include media-query($l-phones) {
		padding-right: 0;
	}
}

.certification__img {
	flex: 0 0 200px;
	display: block!important;
	margin: 30px auto;
}
