.ourTeam__galSection {
    margin-bottom: 0;
    padding-bottom: 40px;

    @media (max-width: 349px) {
        .container {
            padding: {
                left: 0;
                right: 0;
            }
        }
    }
}

.ourTeam__galwrap {
	.member-pic {
		width: 299px;
		height: 300px;
		background-size: cover;
		margin: 0 auto;
        max-width: 230px;
		//background-image: url("http://teee.comradeserver.com/wp-content/uploads/2018/09/1.png");
	}
}

.ourTeam__gal {
    list-style: none;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media (min-width: 991px) {
      justify-content: space-between;

    }

  }



.ourTeam__item {
    //margin: 0 auto 30px;
    //flex: 0 0 auto;
    margin-bottom: 30px;


    width: 100%;
    max-width: 100% !important;


  @media (min-width: 540px) {
      width: 50%;
      max-width: 50% !important;
    }

	@media (min-width: 750px) {
		width: 32.3%;
        margin-right: 15px;

        &:last-child {
          margin-right: 0;
        }

	}



}

.b-ourTeam__slider {
    height: 280px !important;
}

.ourTeam__imgWrap {
	padding: 0 !important;
	//padding: 4% 0 0 10%;

  @media (min-width: 768px) {
    padding: 11px 0 0 10%;
  }

}

.ourTeam__text {
    @media (max-width: 479px) {
        padding: 0 10px;
    }
}
